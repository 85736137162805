import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ModalHeader from '../../components/ModalHeader.js';
import UnitConversionDisplay from '../inventory/UnitConversionDisplay';

const EditModal = ({ isOpen, onClose, orderDetails, submitEdit, handleRemoveOrder }) => {

  const [orderQuantity, setOrderQuantity] = useState(orderDetails?.order_quantity || 0);
  const [editComment, setEditComment] = useState(orderDetails?.client_comment || '');
  const [orderUrgency, setOrderUrgency] = useState(orderDetails?.order_urgency || '');
  const [showConfirmRemove, setShowConfirmRemove] = useState(false); // State to show remove confirmation
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    if (orderDetails) {
      setOrderQuantity(orderDetails.order_quantity || 0);
      setEditComment(orderDetails.client_comment || '');
      setOrderUrgency(orderDetails.order_urgency || '');
    }
  }, [isOpen, orderDetails]);


  const handleSave = () => {
    // Trigger submitEdit with updated values
    submitEdit({ ...orderDetails, order_quantity: orderQuantity, client_comment: editComment, order_urgency: orderUrgency });
    onClose();
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleRemove = () => {
    // Trigger removeOrder when confirmed
    handleRemoveOrder();
    setShowConfirmRemove(false);
    onClose();
  };

  return (
    <Modal
      overlayClassName="white-modal-overlay"
      className="white-modal"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      {/* Modal Header */}
      <ModalHeader 
        description = 'Edit order details for' 
        title={orderDetails?.product_name} 
      />

      {/* Modal Content */}
      <div style={{ marginLeft: '20px', marginRight: '20px' }}>
        <div style={{marginBottom: '10px', }}>
        <h3>Order Quantity</h3>
        <UnitConversionDisplay
                    quantity={orderQuantity}
                    setQuantity={setOrderQuantity}
                    quantityConversion={orderDetails?.quantity_conversion}
                    units={orderDetails?.product_unit}
        />
        </div>

        <div style={{marginBottom: '10px', }}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                        <h3 style={{margin: 0, marginTop: '10px'}}>Preferred Delivery Timeline</h3>
                        <button className="info-button" onClick={togglePopup}>&#x2139;</button>
                    </div>
                    <select className='select-box' style={{marginTop: '12px', marginBottom: '8px', width: '150px'}}
                        value={orderUrgency} onChange={(e) => setOrderUrgency(e.target.value)}>
                        <option value="1 business day">1 business day</option>
                        <option value="2-3 business days">2-3 business days</option>
                        <option value="3-5 business days">3-5 business days</option>
                        <option value="5+ business days">5+ business days</option>
                    </select>
                </div>

                {orderUrgency === '1 business day' && (
                    <p style={{marginTop: '-8px', color: 'black'}}>
                        Attention: Medvelle will process this order ASAP, additional delivery charges will apply.
                    </p>
                )}

                {orderUrgency === '2-3 business days' && (
                    <p style={{marginTop: '-8px', color: 'black'}}>
                        Attention: Additional delivery charges may apply depending on the supplier and your location.
                    </p>
                )}

                {(orderUrgency === '3-5 business days' || orderUrgency === '5+ business days') && (
                    <p style={{marginTop: '-8px', color: 'black'}}>
                        Thank you for being proactive with orders, you just saved on additional delivery charges!
                    </p>
                )}

                {isPopupVisible && (
                    <div className="popup">
                        <div className="popup-inner">
                            <p>Please indicate your preferred delivery timeline - Medvelle will make every effort to meet it.</p>
                            <p>The official order cutoff for next business day orders is 2PM ET. Please contact <a href="mailto:order@medvelle.com" style={{textDecoration: 'none', color: '#D46B45'}}><b>order@medvelle.com</b></a> for any urgent matters.</p>
                            <p>For maximum savings and minimized shipping costs, Medvelle encourages placing orders ahead of time.</p>
                            <button style={{backgroundColor: '#31bac1', color: 'black', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px'}} onClick={togglePopup}>Close</button>
                        </div>
                    </div>
                )}
        

        <h3>Order Comment</h3>
        <textarea
          value={editComment}
          onChange={(e) => setEditComment(e.target.value)}
          style={{ width: '80%', height: '80px', padding: '5px', border: '1px solid #87D3D7', borderRadius: '4px', marginBottom: '30px' }}
        />


        {/* Confirmation for Remove */}
        {showConfirmRemove && (
          <div className="popup">
            <div className="popup-inner">

            <p>Are you sure you want to remove this order?</p>
              <button 
                onClick={handleRemove} 
                style={{ 
                  marginRight: '10px', 
                  padding: '8px', 
                  backgroundColor: 'red', 
                  color: 'white', 
                  border: 'none', 
                  cursor: 'pointer', 
                  borderRadius: '4px' 
                }}
              >
                Confirm Remove
              </button>
              <button 
                onClick={() => setShowConfirmRemove(false)} 
                style={{ 
                  padding: '8px', 
                  backgroundColor: '#31bac1', 
                  color: 'white', 
                  border: 'none', 
                  cursor: 'pointer', 
                  borderRadius: '4px' 
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

      <div>
        {/* Save Changes Button */}
        <button 
          onClick={handleSave}
          className='action-button'
        >
          Save All Changes
        </button>
      </div>

      {/* Remove Button */}
      <h2>Remove Order</h2>
      <div>
      <button 
          onClick={() => setShowConfirmRemove(true)}
          className='decline-button'
          style={{
            fontSize:'small',
            marginBottom: '20px'
          }}
        >
          Remove Order
      </button>
      </div>

      </div>
    </Modal>
  );
};

export default EditModal;
