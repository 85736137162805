import React, {useState} from "react";
import Modal from 'react-modal';
import ModalHeader from "../../components/ModalHeader";
import {declineOrder} from "../../utils/orders/orderManagementUtils";

const DeclineRecommendationModal = ({isOpen, setIsOpen, orderIds, username, onSubmit}) => {
    const [declineReason, setDeclineReason] = useState('');
    const handleClose = () => {
        setIsOpen(false);
    };

    const submitDecline = async () => {
        let success = false
        for (const orderId of orderIds) {
            const result = await declineOrder(orderId, declineReason, username);
            if (result) success = true; // Track any failure
        }
        if (success) {
            onSubmit();
        }
        setDeclineReason('');
        handleClose();
    };

    return (
        <Modal overlayClassName="white-modal-overlay"
            className="white-modal"
            isOpen={isOpen}
            onRequestClose={handleClose}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
                content: {
                    width: '25%',
                }
            }}>
            <ModalHeader title={'Decline Order'} />
            <div style={{marginRight: '20px', marginLeft: '20px'}}>
                <h3 style={{margin: 0, marginTop: '10px'}}>Reason for Decline:</h3>
                <select className='select-box' style={{marginTop: '8px', marginBottom: '8px', maxWidth: '90%'}}
                    value={declineReason} onChange={(e) => setDeclineReason(e.target.value)}>
                    <option value="">Select a reason</option>
                    <option value="Sufficient Stock Available">Sufficient Stock Available</option>
                    <option value="Intention to Discontinue Product Use">Intention to Discontinue Product Use</option>
                    <option value="Issue with Price">Issue with Price</option>
                    <option value="Want a different Product/Manufacturer">Want a different Product/Manufacturer</option>
                    <option value="Already Ordered myself with Medvelle">Already Ordered myself with Medvelle</option>
                    <option value="Already Ordered myself externallye">Already Ordered myself externally</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div>
                <button className='action-button' style={{marginLeft: '20px', marginBottom: '20px'}} onClick={() => submitDecline()} disabled={!declineReason}>Submit</button>
            </div>
        </Modal>
    );
};

export default DeclineRecommendationModal;