export const fetchOrderRecs = async ({setOrderRecData, locationName, setIsLoading = (value) => { }, signal = undefined}) => {
    setIsLoading(true)
    try {
        const response = await fetch(
            `${ process.env.REACT_APP_BACKEND_URL }/order-recommendations${ locationName ? `?locationName=${ locationName }` : '' }`,
            {
                method: 'GET',  // or 'POST' or 'PUT' depending on your requirements
                headers: {
                    'Content-Type': 'application/json'
                },
                ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
            }
        );
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setOrderRecData(data.map(obj => ({
            ...obj,
            total: parseFloat(obj.unit_price * obj.order_quantity),
            isChecked: false  // Add new boolean attribute
        })));

        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching inventory data:', error);
        }
        return [];
    } finally {
        setIsLoading(false)
    }
};

export const fetchProcessedOrders = async ({locationName, setProcessedOrders, signal = undefined}) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-processed-orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({location_name: locationName}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve processed orders.');
        }

        const data = await response.json();
        setProcessedOrders(data.processed_orders);

        return data.processed_orders;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching processed orders:', error.message);
        }
        return null;
    }
};

export const fetchUnprocessedOrders = async ({locationName, setUnprocessedOrders, signal = undefined}) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-unprocessed-orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({location_name: locationName}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve unprocessed orders.');
        }

        const data = await response.json();
        setUnprocessedOrders(data.unprocessed_orders);

        return data.unprocessed_orders;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching unprocessed orders:', error.message);
        }
        return null;
    }
};

export const fetchBudget = async ({locationName, setBudget, signal = undefined}) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-budget`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({location_name: locationName}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve budget.');
        }

        const data = await response.json();
        setBudget(data.budget);

        return data.budget;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching budget:', error.message);
        }
        return null;
    }
};

export const fetchOrdersToCheck = async (locationName, productId, setOrdersToCheck, signal = undefined) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-recent-delivered-orders`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({location_name: locationName, product_id: productId}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve orders to check.');
        }

        const data = await response.json();
        setOrdersToCheck(data);

        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching orders to check:', error.message);
        }
        return null;
    }
};

export const getMoveProductsNumber = async (flag, locationName, setMoveHistoryNumber, signal = undefined) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get_number_moved_products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({product_id: flag.product_id, location_name: locationName, begin_date: flag.last_date_check}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Failed to fetch move history number');
        }

        const data = await response.json();
        setMoveHistoryNumber(data['number_move_products']);

        return true;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching move history number:', error);
        }
        return false;
    }
};

export const getFreeProductsNumber = async (flag, locationName, setFreeHistoryNumber, signal = undefined) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get_number_free_products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({product_id: flag.product_id, location_name: locationName, begin_date: flag.last_date_check}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Failed to fetch free history number');
        }

        const data = await response.json();
        setFreeHistoryNumber(data['number_free_products']);
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching free history number:', error);
        }
    }
};


export const getLiveRecommendations = async (locationName, productId, signal = undefined) => {
    const fetchWithTimeout = async (url, options, timeout = 4000) => {
        const fetchPromise = fetch(url, {...options, ...(signal ? {signal} : {})});

        const timeoutPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({data: {}});  // Default response in case of timeout
            }, timeout);
        });

        return Promise.race([fetchPromise, timeoutPromise]);
    };

    try {
        const response = await fetchWithTimeout(`${ process.env.REACT_APP_BACKEND_URL }/live-recommendations`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({location_name: locationName, product_id: productId}),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error(`API request error: ${ errorText }`);
            return {data: {}};
        }

        const result = await response.json();
        return {data: result.data};

    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error calling the API:', error);
        }
        return {data: {}};  // Return default response in case of error
    }
};

export const getLocationOrders = async ({locationName, setOrders, signal = undefined}) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/location-orders`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({location_name: locationName}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve orders.');
        }

        const data = await response.json();
        setOrders(data);
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching free location orders:', error);
        }
    }
};
