import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { handleManageSubmit } from '../../utils/procurement/procurementUtils';
import { ToastContainer } from 'react-toastify';
import { fetchTicketsInfo } from '../../utils/locations/locationsUtils';

const ManageModal = ({bundleID, isOpen, onClose, userList, setTickets, locationName}) => {

    const [assignedTo, setAssignedTo] = useState('');
    const [assignmentComments, setAssignmentComments] = useState('');

    return (
        <Modal
            overlayClassName="modal-overlay"
            className="modal-content"
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2,
                },
                content: {
                    height: 'auto',
                },
            }}
        >
            <h3>Manage Order Assignment</h3>
            <div>
                <div className="input-group">
                    <label>
                        Order Assigned To:
                        <select
                            value={assignedTo}
                            onChange={(e) => setAssignedTo(e.target.value)}
                        >
                            <option value="">Select User</option>
                            {userList.sort().map(user => (
                                <option key={user.username} value={user.username}>
                                    {user.name}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className="input-group">
                    <label>
                        Assignment Comments:
                        <textarea
                            value={assignmentComments}
                            onChange={(e) => setAssignmentComments(e.target.value)}
                        />
                    </label>
                </div>
                <div className="input-group">
                    <button 
                        type="submit"
                        style={{
                            border: '3px solid #31bac1',
                            backgroundColor: '#31bac1',
                            color: '#fff',
                            padding: '8px 12px',
                            cursor: 'pointer',
                            borderRadius: '8px'
                        }}
                        onClick={async () => {
                            await handleManageSubmit(bundleID, assignedTo, assignmentComments);
                            setAssignedTo('');
                            setAssignmentComments('');
                            onClose();
                            await fetchTicketsInfo(setTickets, locationName);
                        }}
                    >
                        Submit
                    </button>
                    <button 
                        type="button"
                        onClick={onClose}
                        style={{
                            border: '3px solid #31bac1',
                            backgroundColor: '#31bac1',
                            color: '#fff',
                            padding: '8px 12px',
                            cursor: 'pointer',
                            borderRadius: '8px',
                            marginLeft: '5px'
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <ToastContainer />
        </Modal>
    );
};

export default ManageModal;
