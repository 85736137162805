import Modal from 'react-modal';

const SuccessModal = ({isOpen, setIsOpen, onClose, successMessage }) => {

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    };

    return (
        <Modal
            className="white-modal"
            overlayClassName="white-modal-overlay"
            isOpen={isOpen}
            onRequestClose={handleClose}
        >
            <div style={{margin:'20px'}}>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <h2 style={{textAlign: 'center'}}>Reward Redeemed Successfully!</h2>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <p style={{textAlign: 'center'}}>{successMessage}</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <button className='action-button' onClick={handleClose} style={{marginTop: '20px', fontSize: 'medium'}}>
                        Close
                    </button>
                </div>
            </div>
            
        </Modal>
    );
};

export default SuccessModal;