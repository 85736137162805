import React, {useState, useEffect} from "react";
import Modal from 'react-modal';
import ModalHeader from "../../components/ModalHeader";
import {checkDeltaInterval, getDeltaStatus, getUpdatedDeltaQuantity} from "../../utils/flags/flagInfo";
import {resolveFlag} from "../../utils/flags/flagManagement";
import {fetchOrdersToCheck, getFreeProductsNumber, getMoveProductsNumber} from "../../utils/orders/orderInfoUtils";
import ProgressBar from "../../components/ProgressBar";
import {fetchRecommendationsPredictions, editInventoryQuantity, trackInventoryEdit, updateSpotcheckDate} from '../../utils/inventory/inventoryManagement';
import { formatDateText } from "../../utils/time_zones/TimeZones";
import tick from '../../assets/images/green-tick.png';

const ResolveRecommendationModal = ({isOpen, setIsOpen, onClose, flagToResolve, locationName, username, onSubmit}) => {
    const [moveHistoryNumber, setMoveHistoryNumber] = useState(0);
    const [freeHistoryNumber, setFreeHistoryNumber] = useState(0);
    const [countedQuantity, setCountedQuantity] = useState(0);
    const [countedQuantityMatch, setCountedQuantityMatch] = useState(null);
    const [finalDone, setFinalDone] = useState(false);
    const [isSubmittingFlagResolution, setIsSubmittingFlagResolution] = useState(false);
    const [countDone, setCountDone] = useState(false);
    const [freeProductDone, setFreeProductDone] = useState(false);
    const [orderDeliveredCheckDone, setOrderDeliveredCheckDone] = useState(false);
    const [gaveProductDone, setGaveProductDone] = useState(false);
    const [externalOrderDone, setExternalOrderDone] = useState(false);
    const [brokenExpiredDone, setBrokenExpiredDone] = useState(false);
    const [moveProductDone, setMoveProductDone] = useState(false);

    const [freeProductNumber, setFreeProductNumber] = useState(0);
    const [orderDeliveredCheckNumber, setOrderDeliveredCheckNumber] = useState(0);
    const [gaveProductNumber, setGaveProductNumber] = useState(0);
    const [externalOrderNumber, setExternalOrderNumber] = useState(0);
    const [brokenExpiredNumber, setBrokenExpiredNumber] = useState(0);
    const [moveProductNumber, setMoveProductNumber] = useState(0);

    const [countMessage, setCountMessage] = useState('');
    const [freeProductMessage, setFreeProductMessage] = useState('');
    const [orderDeliveredCheckMessage, setOrderDeliveredCheckMessage] = useState('');
    const [gaveProductMessage, setGaveProductMessage] = useState('');
    const [externalOrderMessage, setExternalOrderMessage] = useState('');
    const [brokenExpiredMessage, setBrokenExpiredMessage] = useState('');
    const [moveProductMessage, setMoveProductMessage] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [ordersToCheck, setOrdersToCheck] = useState([]);
    const [finalResolutionStatus, setFinalResolutionStatus] = useState('Resolved');

    const [deltaAfterCorrection, setDeltaAfterCorrection] = useState(0);
    const [deltaBeforeProcess, setDeltaBeforeProcess] = useState(0);

    const [selectedOrders, setSelectedOrders] = useState([]);

    useEffect(() => {
        if (isOpen) {
            setDeltaBeforeProcess(flagToResolve.total_delta);
            setCountedQuantity(flagToResolve.quantity_conversion !== 0 ? parseFloat((flagToResolve.inventory_quantity / flagToResolve.quantity_conversion).toFixed(1)) : flagToResolve.inventory_quantity);
            fetchOrdersToCheck(locationName, flagToResolve.product_id, setOrdersToCheck);
            getFreeProductsNumber(flagToResolve, locationName, setFreeHistoryNumber);
            getMoveProductsNumber(flagToResolve, locationName, setMoveHistoryNumber);
        }
    }, [flagToResolve])


    const {textDeltaStatus, colorDeltaStatus} = getDeltaStatus({flagToResolve, deltaBeforeProcess, orderDeliveredCheckNumber, moveProductNumber, freeProductNumber, brokenExpiredNumber, gaveProductNumber, externalOrderNumber});


    const issues = [
        {'problem': 'Orders not delivered', 'quantity': orderDeliveredCheckNumber},
        {'problem': 'Untracked moved products', 'quantity': moveProductNumber},
        {'problem': 'Untracked free products', 'quantity': freeProductNumber},
        {'problem': 'Untracked broken or expired products', 'quantity': brokenExpiredNumber},
        {'problem': 'Untracked external orders', 'quantity': externalOrderNumber}
        // { 'problem' : 'Untracked given away products', 'quantity' : gaveProductNumber }, 
    ];

    const steps = [
        {'number': 1, 'title': "Recount"},
        {'number': 2, 'title': "Undelivered Orders"},
        {'number': 3, 'title': "Moved Products"},
        {'number': 4, 'title': "Free Products"},
        {'number': 5, 'title': "Broken or Expired"},
        {'number': 6, 'title': "External Orders"},
        {'number': 7, 'title': "Submit"}
    ];


    const totalSteps = steps.length;

    const handleClose = () => {
        onClose()
        setIsOpen(false)
    }

    const allDone = () => {
        setFinalDone(true);
        setCountDone(true);
        setFreeProductDone(true);
        setOrderDeliveredCheckDone(true);
        setGaveProductDone(true);
        setExternalOrderDone(true);
        setBrokenExpiredDone(true);
        setMoveProductDone(true);
    };

    const handleNextStep = () => {
        checkAndResetVariables();
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePreviousStep = () => {
        checkAndResetVariables();
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const getUpdatedMessageReason = () => {
        return countMessage + orderDeliveredCheckMessage + moveProductMessage + freeProductMessage + brokenExpiredMessage + gaveProductMessage + externalOrderMessage + '|';
    };

    const handleOrderCheckboxChange = async (order) => {
        let ordersSelected;
        if (selectedOrders.includes(order)) {
            setSelectedOrders(selectedOrders.filter(o => o !== order));
            ordersSelected = selectedOrders.filter(o => o !== order);
        } else {
            setSelectedOrders([...selectedOrders, order]);
            ordersSelected = [...selectedOrders, order];
        }

        const orderQuantitySum = ordersSelected.reduce((acc, order_selected) => acc + order_selected.order_quantity, 0);
        setOrderDeliveredCheckNumber(orderQuantitySum);
    };   

    const handleCountedQuantityChange = (e) => {
        const counted_input = parseFloat(e.target.value);
        setCountedQuantity(counted_input);

        const counted = isNaN(counted_input) || counted_input === null ? 0 : parseFloat(counted_input);

        let delta = parseFloat(counted - flagToResolve.inventory_quantity / flagToResolve.quantity_conversion + flagToResolve.total_delta).toFixed(1);

        if (checkDeltaInterval(delta, flagToResolve)) {
            delta = 0;
            setCountedQuantityMatch(flagToResolve.inventory_quantity / flagToResolve.quantity_conversion - flagToResolve.total_delta);
        }

        setDeltaBeforeProcess(parseFloat(delta));
    };

    const checkAndResetVariables = () => {
        if (moveProductNumber === null || isNaN(moveProductNumber)) {
            setMoveProductNumber(0);
        }
        if (freeProductNumber === null || isNaN(freeProductNumber)) {
            setFreeProductNumber(0);
        }
        if (orderDeliveredCheckNumber === null || isNaN(orderDeliveredCheckNumber)) {
            setOrderDeliveredCheckNumber(0);
        }
        if (gaveProductNumber === null || isNaN(gaveProductNumber)) {
            setGaveProductNumber(0);
        }
        if (externalOrderNumber === null || isNaN(externalOrderNumber)) {
            setExternalOrderNumber(0);
        }
        if (brokenExpiredNumber === null || isNaN(brokenExpiredNumber)) {
            setBrokenExpiredNumber(0);
        }
        if (countedQuantity === null || isNaN(countedQuantity)) {
            setCountedQuantity(0);
            setCountedQuantityMatch(null);
        }
    };

    const handleSubmitResolvedQuantity = async () => {
        const originalQuantity = flagToResolve.inventory_quantity;
        const newQuantity = countedQuantityMatch === null ? Math.round(countedQuantity * flagToResolve.quantity_conversion) : Math.round(countedQuantityMatch * flagToResolve.quantity_conversion);
        const productId = flagToResolve.product_id;
        const origin = "Spotcheck";
        const success = await editInventoryQuantity(productId, newQuantity, locationName);

        if (success) {
            trackInventoryEdit(productId, newQuantity, originalQuantity, locationName, username, origin);
            await updateSpotcheckDate(productId, locationName);
            return success
        }
    };

    const submitFlagResolution = async (explanation, deltaAfter) => {
        if (!isSubmittingFlagResolution) {
            setIsSubmittingFlagResolution(true);
            const success = await resolveFlag({flagToResolve, username, locationName, deltaAfter, deltaBeforeProcess, explanation, moveProductNumber, freeProductNumber, brokenExpiredNumber, gaveProductNumber, externalOrderNumber, orderDeliveredCheckNumber});
            if (!success) console.error('Failed to submit flaggin resolution');
            await handleSubmitResolvedQuantity()
            onSubmit();

            setIsSubmittingFlagResolution(false);

            allDone();
        }
    };

    return (
        <Modal
            overlayClassName="white-modal-overlay"
            className="white-modal"
            isOpen={isOpen} // Change to your FlagsModalIsOpen state
            onRequestClose={handleClose}
            style={{
                content: {
                    width: '55%',
                    height: '75%',
                }
            }}
        >   
            <ModalHeader title={flagToResolve.product_name} description={'Flag resolution for'} />
            {/* <h2 style={{textAlign: 'center', marginBottom: '10px'}}>Resolve Flag for {flagToResolve.product_name}</h2> */}
            <br />
            <div style={{
                textAlign: 'center'
}}>
                {!finalDone &&
                    <div style={{justifyItems: 'center'}}>
                        <ProgressBar steps={steps} currentStep={currentStep} totalSteps={totalSteps} />
                        <h3 style={{textAlign: 'center', marginBottom: '10px'}}>Status</h3>
                        <div style={{width: '20%', backgroundColor: colorDeltaStatus, padding: '5px', borderRadius: '5px', color: 'white', textAlign: 'center'}}>
                            {textDeltaStatus}
                        </div>
                        <br />
                    </div>
                }

                {!countDone &&
                    <>
                        <div className="flag-resolution-centered">
                            <p>How many {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`} of {flagToResolve.product_name} do you have?</p>
                            <div style={{
                                border: '2px solid #87D3D7',
                                display: 'flex',
                                justifyContent: 'left',
                                flexDirection: 'column',
                                width: '100%',
                                maxHeight: '110px', // Set a fixed height
                                overflowY: 'auto', // Enable vertical scrolling
                                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                            }}>
                                <table style={{
                                    width: '100%',
                                    borderCollapse: 'separate',
                                    borderSpacing: 0,
                                    position: 'relative' // Ensure positioning context
                                }}>
                                    <thead style={{
                                        position: 'sticky', // Stick the header
                                        top: 0, // Stick to the top of the table
                                    }}>
                                        <tr style={{fontSize: '13px', backgroundColor: 'white'}}>
                                            <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left'}}>Current Quantity</th>
                                            <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left'}}>Expected Quantity</th>
                                            <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left'}}>Counted Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{fontSize: '13px', backgroundColor: '#ebf4f5'}}>
                                            <td style={{padding: '10px', textAlign: 'left'}}>{flagToResolve.quantity_conversion !== 0 ? parseFloat(flagToResolve.inventory_quantity / flagToResolve.quantity_conversion).toFixed(1) : flagToResolve.inventory_quantity} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}</td>
                                            <td style={{padding: '10px', textAlign: 'left'}}>{flagToResolve.quantity_conversion !== 0 ? parseFloat((flagToResolve.inventory_quantity / flagToResolve.quantity_conversion - flagToResolve.total_delta)).toFixed(1) : (flagToResolve.inventory_quantity - flagToResolve.total_delta)} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}</td>
                                            <td style={{padding: '10px', textAlign: 'left'}}>
                                                <input min="0" style={{width: '40%', marginRight: '5px'}} type="number" value={countedQuantity} onChange={handleCountedQuantityChange} />
                                                {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <button onClick={async () => {

                            if (deltaBeforeProcess != flagToResolve.total_delta) {
                                setCountMessage('|  Miscount  ');
                            }
                            if (deltaBeforeProcess == 0) {
                                setCurrentStep(steps.length);
                                await submitFlagResolution('|  Miscount  |', deltaBeforeProcess);
                                return;
                            }
                            setCountDone(true);
                            handleNextStep();

                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Next
                        </button>
                    </>
                }

                {!orderDeliveredCheckDone && countDone &&
                    <>
                        {ordersToCheck.length !== 0 ?
                            (
                                <>
                                    <div className="flag-resolution-centered">
                                        <p>Check the orders that haven't been delivered.</p>
                                        <div style={{
                                            border: '2px solid #87D3D7',
                                            display: 'flex',
                                            justifyContent: 'left',
                                            flexDirection: 'column',
                                            width: '100%',
                                            maxHeight: '160px', // Set a fixed height
                                            overflowY: 'auto', // Enable vertical scrolling
                                            boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                                        }}>

                                            <table style={{
                                                width: '100%',
                                                borderCollapse: 'separate',
                                                borderSpacing: 0,
                                                position: 'relative' // Ensure positioning context
                                            }}>
                                                <thead style={{
                                                    position: 'sticky', // Stick the header
                                                    top: 0, // Stick to the top of the table
                                                }}>
                                                    <tr style={{fontSize: '13px', backgroundColor: 'white'}}>
                                                        <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left'}}>Order Date</th>
                                                        <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left'}}>Product Name</th>
                                                        <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left'}}>Order Quantity</th>
                                                        <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left'}}>Not Delivered?</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {ordersToCheck.map((order, index) => (
                                                        <tr key={index} style={{fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff'}}>
                                                            <td style={{padding: '10px', textAlign: 'left'}}>{formatDateText(order.order_date)}</td>
                                                            <td style={{padding: '10px', textAlign: 'left'}}>{flagToResolve.product_name}</td>
                                                            <td style={{padding: '10px', textAlign: 'left'}}>{order.order_quantity}</td>
                                                            <td style={{padding: '10px', textAlign: 'left'}}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedOrders.includes(order)}
                                                                    onChange={async () => {
                                                                        await handleOrderCheckboxChange(order);
                                                                    }}
                                                                    style={{width: '20px', height: '20px', alignItems: 'center'}}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <button onClick={async () => {
                                        setCountDone(false);
                                        handlePreviousStep();
                                        setCountMessage('');
                                    }}
                                        style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            margin: '20px'
                                        }}
                                        className="action-button"
                                    >
                                        Back
                                    </button>
                                    <button onClick={async () => {

                                        if (orderDeliveredCheckNumber != 0 && !isNaN(orderDeliveredCheckNumber) && orderDeliveredCheckNumber !== null) {
                                            setOrderDeliveredCheckMessage('|  Order IDs not delivered: ' + selectedOrders.map(order => order.order_id).join(',') + '  ');
                                        }
                                        setOrderDeliveredCheckDone(true);
                                        handleNextStep();

                                    }}

                                        style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 0,
                                            margin: '20px'
                                        }}
                                        className="action-button"
                                    >
                                        Next
                                    </button>
                                </>
                            )
                            :
                            (
                                <>
                                    <div className="flag-resolution-centered">
                                        <p>No recent delivered orders found. Go to next step.</p>
                                    </div>
                                    <button onClick={async () => {
                                        setCountDone(false);
                                        handlePreviousStep();
                                        setCountMessage('');
                                    }}
                                        style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            margin: '20px'
                                        }}
                                        className="action-button"
                                    >
                                        Back
                                    </button>
                                    <button onClick={() => {
                                        setOrderDeliveredCheckDone(true);
                                        handleNextStep();
                                    }}

                                        style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 0,
                                            margin: '20px'
                                        }}
                                        className="action-button"
                                    >
                                        Next
                                    </button>
                                </>
                            )
                        }
                    </>
                }

                {!moveProductDone && orderDeliveredCheckDone &&
                    <>
                        <div className="flag-resolution-centered">
                            <p>We tracked {moveHistoryNumber} moved {flagToResolve.product_name} since {formatDateText(flagToResolve.last_date_check)}. How many additional {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`} of {flagToResolve.product_name} did you move?</p>
                            <input min="0" style={{width: '20%', marginRight: '5px'}} type="number" value={moveProductNumber} onChange={(e) => setMoveProductNumber(parseFloat(e.target.value))} /> {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}
                        </div>
                        <button onClick={async () => {
                            setOrderDeliveredCheckDone(false);
                            handlePreviousStep();
                            setOrderDeliveredCheckMessage('');
                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Back
                        </button>
                        <button onClick={async () => {

                            if (moveProductNumber != 0 && !isNaN(moveProductNumber) && moveProductNumber !== null) {
                                setMoveProductMessage('|  Moved Product(s)  ');
                            }
                            setMoveProductDone(true);
                            handleNextStep();

                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Next
                        </button>
                    </>
                }

                {!freeProductDone && moveProductDone &&
                    <>
                        <div className="flag-resolution-centered">
                            <p>We tracked {freeHistoryNumber.toFixed(2)} free promotional {flagToResolve.product_name} since {formatDateText(flagToResolve.last_date_check)}. How many additional {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`} of free {flagToResolve.product_name} did you receive?</p>
                            <input min="0" style={{width: '20%', marginRight: '5px'}} type="number" value={freeProductNumber} onChange={(e) => setFreeProductNumber(parseFloat(e.target.value))} /> {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}
                        </div>
                        <button onClick={async () => {
                            setMoveProductDone(false);
                            handlePreviousStep();
                            setMoveProductMessage('');
                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Back
                        </button>
                        <button onClick={async () => {

                            if (freeProductNumber != 0 && !isNaN(freeProductNumber) && freeProductNumber !== null) {
                                setFreeProductMessage('|  Free Product(s)  ');
                            }
                            setFreeProductDone(true);
                            handleNextStep();

                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Next
                        </button>
                    </>
                }

                {!brokenExpiredDone && freeProductDone &&
                    <>
                        <div className="flag-resolution-centered">
                            <p>How many {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`} of {flagToResolve.product_name} have expired or broken since {formatDateText(flagToResolve.last_date_check)}?</p>
                            <input min="0" style={{width: '20%', marginRight: '5px'}} type="number" value={brokenExpiredNumber} onChange={(e) => setBrokenExpiredNumber(parseFloat(e.target.value))} /> {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}
                        </div>
                        <button onClick={async () => {
                            setFreeProductDone(false);
                            handlePreviousStep();
                            setFreeProductMessage('');
                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Back
                        </button>
                        <button onClick={() => {

                            if (brokenExpiredNumber != 0 && !isNaN(brokenExpiredNumber) && brokenExpiredNumber !== null) {
                                setBrokenExpiredMessage('|  Broken/Expired Product(s)  ');
                            }
                            setBrokenExpiredDone(true);
                            handleNextStep();

                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Next
                        </button>
                    </>
                }

                {!externalOrderDone && brokenExpiredDone &&
                    <>
                        <div className="flag-resolution-centered">
                            <p>How many {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`} of {flagToResolve.product_name} did you order without using Medvelle?</p>
                            <input min="0" style={{width: '20%', marginRight: '5px'}} type="number" value={externalOrderNumber} onChange={(e) => setExternalOrderNumber(parseFloat(e.target.value))} /> {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}
                        </div>
                        <button onClick={async () => {
                            setBrokenExpiredDone(false);
                            handlePreviousStep();
                            setBrokenExpiredMessage('');
                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Back
                        </button>
                        <button onClick={async () => {

                            if (externalOrderNumber != 0 && !isNaN(externalOrderNumber) && externalOrderNumber !== null) {
                                setExternalOrderMessage('|  External Order(s)  ');
                            }
                            setExternalOrderDone(true);
                            handleNextStep();

                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Next
                        </button>
                    </>
                }

                {/* Gave Away commented for now */}
                {/* { !gaveProductDone && orderDeliveredCheckDone &&
          <>
            <div className="flag-resolution-centered">
              <p>How many {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`} have you given away?</p>
              <input min="0" style={{width:'20%', marginRight: '5px'}} type="number" value={gaveProductNumber} onChange={(e) => setGaveProductNumber(parseFloat(e.target.value))} /> {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}
            </div>
            <button onClick={async () => {
              setOrderDeliveredCheckDone(false);
              handlePreviousStep();
              setOrderDeliveredCheckMessage('');
              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                margin: '20px'
              }}
            >
              Back
            </button>
            <button onClick={async () => {
                
              if (gaveProductNumber != 0){
                setGaveProductMessage('|  Gave Away Product(s)  ');
              }
              setGaveProductDone(true);
              handleNextStep();
              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                right: 0,
                margin: '20px'
              }}
            >
              Next
            </button>
          </>
        } */}


                {!finalDone && externalOrderDone &&
                    <>
                        <div className="flag-resolution-centered" style={{marginTop: '30px'}}>
                            <p>Do you want to submit the information below?</p>
                            <div style={{
                                border: '2px solid #87D3D7',
                                display: 'flex',
                                justifyContent: 'left',
                                flexDirection: 'column',
                                width: '100%',
                                maxHeight: '220px', // Set a fixed height
                                overflowY: 'auto', // Enable vertical scrolling
                                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                            }}>

                                <table style={{
                                    width: '100%',
                                    borderCollapse: 'separate',
                                    borderSpacing: 0,
                                    position: 'relative' // Ensure positioning context
                                }}>
                                    <thead style={{
                                        position: 'sticky', // Stick the header
                                        top: 0, // Stick to the top of the table
                                    }}>
                                        <tr style={{fontSize: '13px', backgroundColor: 'white'}}>
                                            <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left'}}>Problem</th>
                                            <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left'}}>Quantity - {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {issues.map((issue, index) => (
                                            <tr key={index} style={{fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff'}}>
                                                <td style={{padding: '10px', textAlign: 'left'}}>{issue.problem}</td>
                                                <td style={{padding: '10px', textAlign: 'left'}}>{issue.quantity}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <button onClick={async () => {
                            setExternalOrderDone(false);
                            handlePreviousStep();
                            setExternalOrderMessage('');
                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Back
                        </button>
                        <button onClick={async () => {

                            const deltaAfterCorrection = getUpdatedDeltaQuantity({flagToResolve, deltaBeforeProcess, orderDeliveredCheckNumber, moveProductNumber, freeProductNumber, brokenExpiredNumber, gaveProductNumber, externalOrderNumber});
                            setDeltaAfterCorrection(deltaAfterCorrection);

                            const explanationMessage = getUpdatedMessageReason();
                            if (deltaAfterCorrection == 0) {
                                await submitFlagResolution(explanationMessage, deltaAfterCorrection);
                            }
                            else if (deltaAfterCorrection < 0) {
                                setFinalResolutionStatus('Unresolved Lost');
                                await submitFlagResolution(explanationMessage + '  Lost  |', deltaAfterCorrection);
                            } else if (deltaAfterCorrection > 0) {
                                setFinalResolutionStatus('Unresolved Gain');
                                await submitFlagResolution(explanationMessage + '  Gain  |', deltaAfterCorrection);
                            }
                            handleNextStep();
                        }}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                margin: '20px'
                            }}
                            className="action-button"
                        >
                            Submit
                        </button>
                    </>
                }

                {finalDone &&
                    <>
                        <div className="flag-resolution-result-centered">

                            <h4>Resolution Done <img src={tick} alt="green-tick" style={{height: '20px', width: '20px', marginBottom: '-5px'}} /></h4>
                            <ul style={{textAlign: 'left', listStylePosition: 'inside', paddingInlineStart: '20px', marginTop: '10px'}}>
                                <li><b>Result:</b> {finalResolutionStatus}</li>
                                <br />
                                {deltaAfterCorrection === 0 ? (
                                    <li><b>Status:</b> Resolution Fixed!</li>
                                ) : (
                                    <li>
                                        <b>Status:</b> {textDeltaStatus}
                                    </li>
                                )}
                                <br />
                                {countMessage !== '' || moveProductMessage !== '' || freeProductMessage !== '' || externalOrderMessage !== '' || orderDeliveredCheckMessage !== '' || gaveProductMessage !== '' || brokenExpiredMessage !== '' ? (
                                    <li>
                                        <b>Details:</b>
                                        <ul style={{fontSize: '15px', textAlign: 'left', listStylePosition: 'inside', paddingInlineStart: '20px', marginTop: '10px'}}>
                                            {countMessage !== '' && (
                                                <li>
                                                    <b>Miscount</b>
                                                </li>
                                            )}
                                            {orderDeliveredCheckMessage !== '' && (
                                                <li>
                                                    <b>Order(s) not delivered (lost package, contact Medvelle) :</b><span style={{float: 'right', marginLeft: '5px'}}> {orderDeliveredCheckNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}</span>
                                                </li>
                                            )}
                                            {moveProductMessage !== '' && (
                                                <li>
                                                    <b>Moved product(s) not tracked:</b><span style={{float: 'right', marginLeft: '5px'}}> {moveProductNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}</span>
                                                </li>
                                            )}
                                            {freeProductMessage !== '' && (
                                                <li>
                                                    <b>Free product(s) not tracked:</b><span style={{float: 'right', marginLeft: '5px'}}> {freeProductNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}</span>
                                                </li>
                                            )}
                                            {brokenExpiredMessage !== '' && (
                                                <li>
                                                    <b>Broken / expired product(s) not tracked:</b><span style={{float: 'right', marginLeft: '5px'}}> {brokenExpiredNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}</span>
                                                </li>
                                            )}
                                            {externalOrderMessage !== '' && (
                                                <li>
                                                    <b>External order(s) not tracked:</b><span style={{float: 'right', marginLeft: '5px'}}> {externalOrderNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${ flagToResolve.product_unit }(s)`}</span>
                                                </li>
                                            )}
                                            {/* {gaveProductMessage !== '' && (
                        <li>
                          <b>Product(s) given away not tracked:</b><span style={{float: 'right', marginLeft: '5px'}}> {gaveProductNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</span>
                        </li>                     
                      )} */}
                                        </ul>
                                    </li>
                                ) : ('')
                                }
                            </ul>
                            <br />
                            <button onClick={handleClose}
                                style={{
                                    margin: '20px'
                                }}
                                className="action-button"
                            >
                                Finish
                            </button>
                        </div>
                    </>
                }
            </div>
            

        </Modal>
    )
}

export default ResolveRecommendationModal;