import Fuse from 'fuse.js';

export const fuzzySearch = (list, query, fields) => {
    if (!query) return list;

    const options = {
        includeScore: true,
        shouldSort: true,
        findAllMatches: true,
        threshold: .3,
        keys: fields
    };

    const fuse = new Fuse(list, options);
    const result = fuse.search(query);

    return result.map(item => item.item);
};