import React, {useState } from 'react';

export const ServiceTable = ({services, onClick}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedServiceCategory, setSelectedServiceCategory] = useState('');
    const uniqueServiceCategories = Array.from(new Set(services.map(item => item.service_cat)));
    const filteredServices = services.filter(
        item =>
          item.service_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (selectedServiceCategory === '' || item.service_cat.toLowerCase() === selectedServiceCategory.toLowerCase())
    );    
    return (
        <>
            <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px'}}
            />
            <select id="categorySelect" value={selectedServiceCategory} onChange={e => setSelectedServiceCategory(e.target.value)}
                style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px'}}
            >
                <option value="">All Categories</option>
                {uniqueServiceCategories.sort().map((category, index) => (
                    <option key={index} value={category}>{category}</option>
                ))}
            </select>
            <br/>
            <br/>
            <div style={{ 
            border: '2px solid #87D3D7', 
            display: 'flex', 
            justifyContent: 'left', 
            flexDirection: 'column', 
            width: '100%', 
            maxHeight: '610px', // Set a fixed height
            overflowY: 'auto', // Enable vertical scrolling
            boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
        }}>
            <table style={{  
                width: '100%', 
                borderCollapse: 'separate', 
                borderSpacing: 0, 
                position: 'relative' // Ensure positioning context
            }}>
                <thead style={{
                    position: 'sticky', // Stick the header
                    top: 0, // Stick to the top of the table
                    zIndex: 1 // Ensure it stays above the tbody rows
                }}>
                    <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%'  }}>Service Name</th>
                        <th style={{  borderBottom:'2px solid black', padding: '10px', width: '20%' }}>Location</th>
                        <th style={{  borderBottom:'2px solid black', padding: '10px', width: '15%' }}>Category</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%', textAlign: 'center' }}>Sub Category</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>Price</th>
                        <th style={{  borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center'}}>Cost</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%', textAlign: 'center' }}>Duration</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center'}}>Provider Requirement</th>
                        <th style={{  borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center'}}>Room Type</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '5%', textAlign: 'center' }}>Add</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredServices.map((service, index) => (
                        <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                            <td style={{ padding: '10px', }}>{service.service_name}</td>
                            <td style={{  padding: '10px', }}>{service.location_name}</td>
                            <td style={{ padding: '10px',  }}>{service.service_cat}</td>
                            <td style={{padding: '10px' ,textAlign: 'center' }}>{service.service_sub_cat}</td>
                            <td style={{ padding: '10px', textAlign: 'center'}}>{service.service_price}</td>
                            <td style={{  padding: '10px', textAlign: 'center'}}>{service.service_cost}</td>
                            <td style={{ padding: '10px' , textAlign: 'center'}}>{service.duration}</td>
                            <td style={{ padding: '10px' , textAlign: 'center'}}>{service.provider_requirement}</td>
                            <td style={{ padding: '10px' , textAlign: 'center'}}>{service.room_type}</td>
                            <td style={{ padding: '10px' , textAlign: 'center'}}>
                                <button 
                                    onClick={async() => onClick(service)}
                                    style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                >
                                    Add
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </>
        
    );
};