import React from 'react';

const UserProfile = ({ user }) => {

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column'}}>
                <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{ user && user.name}</span>
                <span style={{ fontSize: '16px' }}>user role</span>
            </div>
            <div style={{ 
                width: '50px', 
                height: '50px', 
                borderRadius: '50%', 
                backgroundColor: '#87D3D7', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                color: 'white', 
                fontWeight: 'bold', 
                fontSize: '20px',
                marginLeft: '10px'
            }}>
                {user && user.name[0]}
            </div>
        </div>
    );
};

export default UserProfile;
