import React, { useEffect, useMemo, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import * as d3 from 'd3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import { formatDateText, newDate } from '../utils/time_zones/TimeZones';

const Payments = ({ call }) => {
    const contentRef = useRef();
    const [locations, setLocations] = useState([]);
    const [bundles, setBundles] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [username, setUsername] = useState('');
    const [selectedBundle, setSelectedBundle] = useState(null);
    const [showPaidConfirmation, setShowPaidConfirmation] = useState(false);
    const [sortDueDate, setSortDueDate] = useState('asc'); // 'asc' or 'desc'
    const [timeFilter, setTimeFilter] = useState('all');
    const [ordersPerBundle, setOrdersPerBundle] = useState([]);
    const [expandedBundles, setExpandedBundles] = useState([]);

    useEffect(() => {
        fetchLocations();
        fetchBundles();
        fetchOrdersPerBundle();
        fetchUsername();
        contentRef.current.style.marginLeft = '250px';
        contentRef.current.style.marginRight = '50px';
    }, [selectedLocation, bundles]);

    const fetchOrdersPerBundle = () => {
        fetch(`${call}/orders-per-bundle`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {

                // Convert the object into an array of bundles with IDs
                const bundlesArray = Object.entries(data);

                let filteredBundles;
                if (selectedLocation !== '') {
                    // Filter bundles based on all orders having location_name = selectedLocation
                    filteredBundles = bundlesArray.filter(([bundleId, orders]) =>
                        orders.every(order => order.location_name === selectedLocation)
                    );
                } else {
                    // If selectedLocation is empty, keep all bundles
                    filteredBundles = bundlesArray;
                }

                // Convert back to an object with filtered bundles
                const filteredData = Object.fromEntries(filteredBundles);

                setOrdersPerBundle(filteredData);

            })
            .catch(error => console.error('Error fetching orders per bundle:', error));
    };

    const fetchLocations = () => {
        fetch(`${call}/locations`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setLocations(data);
            })
            .catch(error => console.error('Error fetching locations data:', error));
    };

    // Function to fetch the username
    const fetchUsername = () => {
        // Fetch username using the API and the email from local storage
        const userEmail = localStorage.getItem('userEmail');
    
        if (userEmail) {
        fetch(`${call}/get-name?user_email=${userEmail}`)
            .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then(data => {
            // Store the username in the state variable
            setUsername(data.name);
            })
            .catch(error => console.error('Error fetching username:', error));
        }
    };

    const fetchBundles = async () => {
        try {
          const response = await fetch(`${call}/get-bundles-location`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
          if (!response.ok) {
            throw new Error('Error fetching bundles');
          }
  
          const data = await response.json();
          setBundles(data.filter(bundle => (bundle.bundle_status === 'Delivered' || bundle.bundle_status === 'Shipped')));
        } catch (error) {
          console.error('Error fetching bundles:', error);
          // Handle error, show error message, etc.
        }
    };

    const filteredBundles = selectedLocation
    ? bundles.filter(item => item.location_name === selectedLocation)
    : bundles;

    const sortedFilteredBundles = filteredBundles.slice().sort((a, b) => {
        if (sortDueDate === 'desc') {
          return new Date(b.due_date) - new Date(a.due_date);
        } else {
            return new Date(a.due_date) - new Date(b.due_date);
        }
    });

    const unpaidBundles = sortedFilteredBundles.filter(item => item.paid == 0)
    const paidBundles = sortedFilteredBundles.filter(item => item.paid == 1)

    // Function to handle clicking on the delete button
    const handlePaidEdit = (bundle) => {
        setSelectedBundle(bundle);
        setShowPaidConfirmation(true);
    };

    // Function to confirm deletion and call delete API
    const confirmPaid = async () => {
        try {
            const response = await fetch(`${call}/update-bundle-paid-status`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ paid_status: 1, order_code: selectedBundle.order_code }),
            });
      
            if (!response.ok) {
              throw new Error('Error updating paid status');
            }
      
        } 
        catch (error) {
            console.error('Error updating paid status:', error);
        }

        setShowPaidConfirmation(false);
        fetchBundles();
    };

    const svgRef = useRef(null);

    useEffect(() => {
        const filteredBundlesTime = filterBundlesByTime();
        if (filteredBundlesTime.length > 0) {
            drawHistogram(filteredBundlesTime);
    
            // Add event listener for window resize
            window.addEventListener('resize', handleResize);
        }
    
        // Cleanup function to remove the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [filteredBundles, timeFilter]);
    
    // Function to handle resizing the histogram
    const handleResize = () => {
        const svg = d3.select(svgRef.current);
    
        // Remove existing SVG elements
        svg.selectAll('*').remove();
    
        // Redraw the histogram with updated dimensions
        drawHistogram(filterBundlesByTime());
    };

    const filterBundlesByTime = () => {
        switch (timeFilter) {
            case 'last6months':
                return filteredBundles.filter(bundle => isWithinLastMonths(bundle, 6));
            case 'last3months':
                return filteredBundles.filter(bundle => isWithinLastMonths(bundle, 3));
            case 'lastmonth':
                return filteredBundles.filter(bundle => isWithinLastMonths(bundle, 1));
            case 'thismonth':
                return filteredBundles.filter(bundle => isWithinThisMonth(bundle));
            case 'nextmonth':
                return filteredBundles.filter(bundle => isWithinNextMonths(bundle, 1));
            case 'next3months':
                return filteredBundles.filter(bundle => isWithinNextMonths(bundle, 3));
            case 'next6months':
                return filteredBundles.filter(bundle => isWithinNextMonths(bundle, 6));
            default:
                return filteredBundles;
        }
    };

    const isWithinLastMonths = (bundle, months) => {
        const today = newDate();
        const bundleDate = newDate(bundle.due_date);

        const diffInMonths = (today.getFullYear() - bundleDate.getFullYear()) * 12 + today.getMonth() - bundleDate.getMonth();
        return diffInMonths <= months && diffInMonths > 0;
    };

    const isWithinThisMonth = (bundle) => {
        const today = newDate();
        const bundleDate = newDate(bundle.due_date);
        return (
            bundleDate.getFullYear() === today.getFullYear() &&
            bundleDate.getMonth() === today.getMonth()
        );
    };


    const isWithinNextMonths = (bundle, months) => {
        const today = newDate();
        const bundleDate = newDate(bundle.due_date);

        const diffInMonths = (bundleDate.getFullYear() - today.getFullYear()) * 12 + bundleDate.getMonth() - today.getMonth();
        return diffInMonths <= months && diffInMonths > 0;
    };


    const drawHistogram = (filteredBundlesTime) => {
        // Set the dimensions and margins of the graph
        const windowWidth = window.innerWidth * 80 / 100;
        const windowHeight = window.innerHeight * 70 / 100;
        const margin = { top: 100, right: 150, bottom: 50, left: 50 };
        const width = windowWidth - margin.left - margin.right;
        const height = windowHeight - margin.top - margin.bottom;
    
        // Calculate the range of dates
        const minDate = d3.min(filteredBundlesTime, d => d3.timeDay.floor(newDate(d.due_date)));
        const maxDate = d3.max(filteredBundlesTime, d => d3.timeDay.floor(newDate(d.due_date)));
        const dateRange = d3.timeDay.count(minDate, maxDate) + 1; // Count number of days between min and max date
        
        // Calculate the width of each bin based on the date range and available width
        const binWidth = width / dateRange;
    
        // Group the data by date
        const dataByDate = d3.group(filteredBundlesTime, d => d3.timeDay.floor(newDate(d.due_date)));
    
        // Select the SVG element using the ref
        const svg = d3.select(svgRef.current);
    
        // Remove all existing elements inside the SVG to clear the content
        svg.selectAll('*').remove();
    
        // Append the svg object to the ref element
        svg.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);
    
        // Append a group element for the chart area, considering margins
        const chart = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
    
        // X axis: scale and draw
        const xScale = d3
        .scaleTime()
        .domain([minDate, d3.timeDay.offset(maxDate, 1)]) // Add one day to the maxDate to include it in the range
        .range([0, width]);
    
        chart.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(xScale));
    
        // Calculate the maximum sum of total_with_taxes_shipping per day
        const maxSumPerDay = d3.max(dataByDate, dayData => {
            return d3.sum(dayData[1], bundle => bundle.total_with_taxes_shipping);
        });

        // Y axis: scale and draw
        const yScale = d3
            .scaleLinear()
            .domain([0, maxSumPerDay]) // Use the maximum sum per day as the y-axis domain
            .range([height, 0]);
    
        chart.append('g').call(d3.axisLeft(yScale).tickFormat(d3.format("$,.0f")));
    
        // Append the bar rectangles to the chart area
        chart
                .selectAll('rect')
                .data(dataByDate)
                .enter()
                .append('g')
                .selectAll('rect')
                .data(d => d[1])
                .enter()
                .append('rect')
                .attr('x', d => xScale(newDate(d.due_date)))
                .attr('y', (d, i, nodes) => {
                    const currentDate = newDate(d.due_date);
                    const previousBins = nodes.slice(0, i).filter(node => {
                        const binDate = newDate(node.__data__.due_date);
                        return binDate.getTime() === currentDate.getTime();
                    });
                    const yOffset = previousBins.reduce((acc, bin) => acc + (height - yScale(bin.__data__.total_with_taxes_shipping)), 0);
                    return yScale(d.total_with_taxes_shipping) - yOffset;
                })
                .attr('width', binWidth)
                .attr('height', d => height - yScale(d.total_with_taxes_shipping))
                .attr('fill', d => (d.paid === 0 ? '#e9453b' : '#119b1a'))
                .attr('stroke', 'black') // Add black border
                .attr('stroke-width', 1)
                .on('mouseover', (event, d) => {
                const tooltipX = width / 2;
    
                // Append a rectangle as the background of the tooltip text
                chart.append('rect')
                    .attr('x', tooltipX - 260) // Adjust the positioning as needed
                    .attr('y', -65) // Adjust the positioning as needed
                    .attr('width', 520) // Adjust the width as needed
                    .attr('height', 40) // Adjust the height as needed
                    .attr('fill', '#31bac1')
                    .attr('rx', 5) // Rounded corners
                    .attr('ry', 5); // Rounded corners
    
                // Append the text for the tooltip
                chart.append('text')
                    .attr('x', tooltipX)
                    .attr('y', -40)
                    .text(`Code: ${d.order_code} - Manufacturer: ${d.manufacturer_name} - Price: $${d.total_with_taxes_shipping} - Due Date: ${formatDateText(d.due_date)}`)
                    .attr('font-family', 'sans-serif')
                    .attr('font-size', '12px')
                    .attr('font-weight', 'bold')
                    .attr('fill', 'white')
                    .attr('text-anchor', 'middle'); // Center-align text
            })
            .on('mouseout', (event, d) => {
                chart.selectAll('rect').remove(); // Remove tooltip background rectangle
                chart.selectAll('text').remove(); // Remove tooltip text
            });
    
        // Append a vertical line for today's date if timeFilter is 'all'
        if (timeFilter === 'all' || timeFilter === 'thismonth') {
            const todayDate = newDate();
            chart.append('line')
                .attr('x1', xScale(todayDate))
                .attr('y1', 0)
                .attr('x2', xScale(todayDate))
                .attr('y2', height)
                .attr('stroke', 'black')
                .attr('stroke-width', 2)
                .attr('stroke-dasharray', '3,3'); // Dotted line style
    
            // Add text "Today" on top of the line
            chart.append('text')
                .attr('x', xScale(todayDate) - 15) // Offset from the line
                .attr('y', -10) // Above the line
                .text('Today')
                .attr('font-family', 'sans-serif')
                .attr('font-size', '12px')
                .attr('fill', 'black')
                .attr('text-anchor', 'start'); // Align text to the start of the line
        }
    
        // Append legend
        const legend = svg.append('g')
            .attr('transform', `translate(${width + margin.right - 40},${margin.top})`);
    
        legend.append('rect')
            .attr('width', 10)
            .attr('height', 10)
            .attr('fill', '#119b1a')
            .attr('transform', 'translate(0, 15)');
    
        legend.append('rect')
            .attr('width', 10)
            .attr('height', 10)
            .attr('fill', '#e9453b')
            .attr('transform', 'translate(0, 35)');
    
        legend.append('text')
            .text('Paid')
            .attr('x', 20)
            .attr('y', 25)
            .attr('font-family', 'sans-serif')
            .attr('font-size', '12px');
    
        legend.append('text')
            .text('Unpaid')
            .attr('x', 20)
            .attr('y', 45)
            .attr('font-family', 'sans-serif')
            .attr('font-size', '12px');
    };
    
    const handleBundleClick = (bundleId) => {
        console.log(bundleId);
        if (expandedBundles.includes(bundleId)) {
          setExpandedBundles(expandedBundles.filter(id => id !== bundleId));
        } else {
          // If the bundle is not expanded, add it to the list of expanded bundles
          setExpandedBundles([...expandedBundles, bundleId]);
        }
      };

    return (
        <div ref={contentRef} style={{ textAlign: 'left'}}>
            <ToastContainer />
            <h1 style={{ marginTop: '27px',  fontSize: '40px', fontWeight: 'bold', color: '#87D3D7'}}>Internal Payments</h1>

            <select
                id="location"
                value={selectedLocation}
                onChange={(e) => {setSelectedLocation(e.target.value); setExpandedBundles([]);}}
                style={{ padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
                <option value="">All Locations</option>
                {locations.sort().map((item, index) => (
                    <option key={index} value={item.location_name}>{item.location_name}</option>
                ))}
            </select>

            <select
              value={sortDueDate}
              onChange={(e) => setSortDueDate(e.target.value)}
              style={{ marginLeft: '20px', padding: '10px', fontStyle: 'italic', width: '200px' }}
            >
              <option value="">Sort Due Date</option>
              <option value="asc">Due Date: Ascending</option>
              <option value="desc">Due Date: Descending</option>
            </select>

            <br/>
            <br/>


            <h2>Unpaid Bundles</h2>
            <div style={{ 
                border: '2px solid #87D3D7', 
                display: 'flex', 
                justifyContent: 'left', 
                flexDirection: 'column', 
                width: '100%', 
                maxHeight: '310px', // Set a fixed height
                overflowY: 'auto', // Enable vertical scrolling
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <table style={{  
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0, 
                    position: 'relative' // Ensure positioning context
                }}>
                    <thead style={{
                        position: 'sticky', // Stick the header
                        top: 0, // Stick to the top of the table
                        zIndex: 1 // Ensure it stays above the tbody rows
                    }}>
                        <tr style={{ fontSize: '14px', backgroundColor: 'white'  }}>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}></th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Code</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Date</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Location</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Manufacturer</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Total Product</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Shipping</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Taxes</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Discount</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Total</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Bundle Status</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Due Date</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {unpaidBundles.map((bundle, index) => (
                            <>
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                                    <td 
                                        onClick={() => handleBundleClick(bundle.order_code)} 
                                        style={{ textAlign: 'center' }}
                                    >
                                        <span>
                                            {expandedBundles.includes(bundle.order_code) ? 
                                                <FontAwesomeIcon icon={faAngleUp} /> :
                                                <FontAwesomeIcon icon={faAngleDown} />
                                            }
                                        </span>
                                    </td>
                                    <td onClick={() => handleBundleClick(bundle.order_code)}  style={{ padding: '10px', }}>{bundle.order_code}</td>
                                    <td style={{ padding: '10px'}}>{formatDateText(bundle.bundle_date)}</td>
                                    <td style={{ padding: '10px'}}>{bundle.location_name}</td>
                                    <td style={{ padding: '10px'}}>{bundle.manufacturer_name}</td>
                                    <td style={{ padding: '10px'}}>${bundle.total_price}</td>
                                    <td style={{ padding: '10px'}}>${bundle.shipping}</td>
                                    <td style={{ padding: '10px'}}>${bundle.taxes}</td>
                                    <td style={{ padding: '10px'}}>${bundle.discount}</td>
                                    <td style={{ padding: '10px'}}>${bundle.total_with_taxes_shipping}</td>
                                    <td style={{ padding: '10px'}}>{bundle.bundle_status}</td>
                                    <td style={{ padding: '10px'}}>{formatDateText(bundle.due_date)}</td>
                                    <td style={{ padding: '10px'}}>
                                        <td style={{ padding: '10px' }}>
                                            <button onClick={() => handlePaidEdit(bundle)}
                                                style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer' , borderRadius: '8px'}}
                                            >
                                                Edit
                                            </button>
                                        </td>
                                    </td>
                                </tr>
                                {expandedBundles.includes(bundle.order_code) &&  
                                <tr>
                                {/* Render order details */}
                                <td colSpan="1"></td>
                                <td colSpan="12">
                                    <div style={{ border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden' }}>
                                    <table style={{  
                                        width: '100%', 
                                        borderCollapse: 'separate', 
                                        borderSpacing: 0, 
                                        position: 'relative' // Ensure positioning context
                                    }}>
                                        <thead style={{
                                        position: 'sticky', // Stick the header
                                        top: 0, // Stick to the top of the table
                                        }}>
                                        <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Date</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Name</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Description</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Manufacturer Name</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Unit Price</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Quantity</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Price</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Internal Description</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Client Comment</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Comment</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Status</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Urgency</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {ordersPerBundle[bundle.order_code] && ordersPerBundle[bundle.order_code].map((order, orderIndex) => (
                                        <tr key={orderIndex} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: orderIndex % 2 === 0 ? '#ebf4f5' : '#ffffff'  }}>
                                            <td style={{ padding: '10px' }}>{formatDateText(order.order_date)}</td>
                                            <td style={{ padding: '10px' }}>{order.product_name}</td>
                                            <td style={{ padding: '10px' }}>{order.product_description}</td>
                                            <td style={{ padding: '10px' }}>{order.manufacturer_name}</td>
                                            <td style={{ padding: '10px' }}>${order.unit_price}</td>
                                            <td style={{ padding: '10px' }}>{order.order_quantity}</td>
                                            <td style={{ padding: '10px' }}>${parseFloat(order.order_quantity * order.unit_price).toFixed(2)}</td>
                                            <td style={{ padding: '10px' }}>{order.internal_description}</td>
                                            <td style={{ padding: '10px' }}>{order.client_comment}</td>
                                            <td style={{ padding: '10px' }}>{order.order_comment}</td>
                                            <td style={{ padding: '10px' }}>{order.status_cat}</td>
                                            <td style={{ padding: '10px' }}>{order.order_urgency}</td>
                                        </tr>))}
                                        </tbody>
                                    </table>
                                    </div>
                                </td>
                                </tr>
                                }
                            </>
                        ))}
                    </tbody>
                </table>
            </div>

            <br/>

            <h2>Paid Bundles</h2>
            <div style={{ 
                border: '2px solid #87D3D7', 
                display: 'flex', 
                justifyContent: 'left', 
                flexDirection: 'column', 
                width: '100%', 
                maxHeight: '310px', // Set a fixed height
                overflowY: 'auto', // Enable vertical scrolling
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <table style={{  
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0, 
                    position: 'relative' // Ensure positioning context
                }}>
                    <thead style={{
                        position: 'sticky', // Stick the header
                        top: 0, // Stick to the top of the table
                        zIndex: 1 // Ensure it stays above the tbody rows
                    }}>
                        <tr style={{ fontSize: '14px', backgroundColor: 'white'  }}>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}></th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Code</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Date</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Location</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Manufacturer</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Total Product</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Shipping</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Taxes</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Discount</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Total</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Bundle Status</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Due Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paidBundles.map((bundle, index) => (
                            <>
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                                    <td 
                                        onClick={() => handleBundleClick(bundle.order_code)} 
                                        style={{ textAlign: 'center' }}
                                    >
                                        <span>
                                            {expandedBundles.includes(bundle.order_code) ? 
                                                <FontAwesomeIcon icon={faAngleUp} /> :
                                                <FontAwesomeIcon icon={faAngleDown} />
                                            }
                                        </span>
                                    </td>
                                    <td onClick={() => handleBundleClick(bundle.order_code)}  style={{ padding: '10px', }}>{bundle.order_code}</td>
                                    <td style={{ padding: '10px'}}>{formatDateText(bundle.bundle_date)}</td>
                                    <td style={{ padding: '10px'}}>{bundle.location_name}</td>
                                    <td style={{ padding: '10px'}}>{bundle.manufacturer_name}</td>
                                    <td style={{ padding: '10px'}}>${bundle.total_price}</td>
                                    <td style={{ padding: '10px'}}>${bundle.shipping}</td>
                                    <td style={{ padding: '10px'}}>${bundle.taxes}</td>
                                    <td style={{ padding: '10px'}}>${bundle.discount}</td>
                                    <td style={{ padding: '10px'}}>${bundle.total_with_taxes_shipping}</td>
                                    <td style={{ padding: '10px'}}>{bundle.bundle_status}</td>
                                    <td style={{ padding: '10px'}}>{formatDateText(bundle.due_date)}</td>
                                </tr>
                                {expandedBundles.includes(bundle.order_code) &&  
                                <tr>
                                {/* Render order details */}
                                <td colSpan="1"></td>
                                <td colSpan="11">
                                    <div style={{ border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden' }}>
                                    <table style={{  
                                        width: '100%', 
                                        borderCollapse: 'separate', 
                                        borderSpacing: 0, 
                                        position: 'relative' // Ensure positioning context
                                    }}>
                                        <thead style={{
                                        position: 'sticky', // Stick the header
                                        top: 0, // Stick to the top of the table
                                        }}>
                                        <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Date</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Name</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Description</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Manufacturer Name</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Unit Price</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Quantity</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Price</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Internal Description</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Client Comment</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Comment</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Status</th>
                                            <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Urgency</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {ordersPerBundle[bundle.order_code] && ordersPerBundle[bundle.order_code].map((order, orderIndex) => (
                                        <tr key={orderIndex} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: orderIndex % 2 === 0 ? '#ebf4f5' : '#ffffff'  }}>
                                            <td style={{ padding: '10px' }}>{formatDateText(order.order_date)}</td>
                                            <td style={{ padding: '10px' }}>{order.product_name}</td>
                                            <td style={{ padding: '10px' }}>{order.product_description}</td>
                                            <td style={{ padding: '10px' }}>{order.manufacturer_name}</td>
                                            <td style={{ padding: '10px' }}>${order.unit_price}</td>
                                            <td style={{ padding: '10px' }}>{order.order_quantity}</td>
                                            <td style={{ padding: '10px' }}>${parseFloat(order.order_quantity * order.unit_price).toFixed(2)}</td>
                                            <td style={{ padding: '10px' }}>{order.internal_description}</td>
                                            <td style={{ padding: '10px' }}>{order.client_comment}</td>
                                            <td style={{ padding: '10px' }}>{order.order_comment}</td>
                                            <td style={{ padding: '10px' }}>{order.status_cat}</td>
                                            <td style={{ padding: '10px' }}>{order.order_urgency}</td>
                                        </tr>))}
                                        </tbody>
                                    </table>
                                    </div>
                                </td>
                                </tr>
                                }
                            </>
                        ))}
                    </tbody>
                </table>
            </div>

            <br/>

            <h2>Bundles Histogram</h2>

            <select
                id="time"
                value={timeFilter}
                onChange={(e) => setTimeFilter(e.target.value)}
                style={{ padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
                <option value="all">All</option>
                <option value="last6months">Last 6 Months</option>
                <option value="last3months">Last 3 Months</option>
                <option value="lastmonth">Last Month</option>
                <option value="thismonth">This Month</option>
                <option value="nextmonth">Next Month</option>
                <option value="next3months">Next 3 Months</option>
                <option value="next6months">Next 6 Months</option>
            </select>

            <svg ref={svgRef}></svg>
            
            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={showPaidConfirmation}
                onRequestClose={() => setShowPaidConfirmation(false)}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 2,
                    },
                    content: {
                        width: '20%',
                        height: '15%',
                        textAlign: 'center'
                    }
                }}
            >
                <p>Are you sure you want to set bundle ID {(selectedBundle ? selectedBundle.order_code : 'Unknown')} as paid?</p>
                <button onClick={confirmPaid}
                    style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                >
                    Yes
                </button>
                <button onClick={() => setShowPaidConfirmation(false)}
                    style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                >
                    No
                </button>
            </Modal>

        </div>
    );
}

export default Payments;