import React from 'react';
import { Link } from 'react-router-dom';

const InReviewFlow = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '70vh',
            padding: '20px', 
            overflow: 'auto'
        }}>          
            <p>
                Please process the web product(s) of this bundle in the{' '}
                <a href="/mapping" target="_blank" rel="noopener noreferrer" style={{ color: 'orange', textDecoration: 'none' }}>
                    Mapping
                </a>{' '}
                page first.
            </p>
        </div>
    );
};

export default InReviewFlow;
