export const fetchInventoryQuantity = async ({locationName, productId, signal = undefined}) => {
    try {
        const body = {location_name: locationName, product_id: productId}
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/inventory-quantity`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.length > 0){
            return data[0].inventory_quantity;
        }
        return false
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching locations data:', error);
        }
        return false
    }
};