import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { postFile, fetchFiles } from '../../utils/invoices/invoiceUtils';
import { toast, ToastContainer } from 'react-toastify';

const InvoiceBundleModal = ({ isOpen, onClose, editedBundle, setInvoiceExist }) => {
    const [file, setFile] = useState(null);

    const fileName = "bundle_id_" + editedBundle.order_code;

    const checkFileExists = async (fileName) => {

        let bundlesInvoices = await fetchFiles();

        return setInvoiceExist(bundlesInvoices.includes(fileName));
    };

    const submitUploadInvoice = async () => {
        try {
            const result = await postFile({ file, fileName });
  
            // Check if the result contains a message indicating success
            if (result.message === 'File uploaded successfully') {
                // Show success toast
                toast.success('File uploaded successfully');
                setInvoiceExist(true);
            } else {
                // Show error toast if the message is not as expected
                toast.error('Failed to upload file');
            }
            setFile('');
            onClose();
  
        } catch (error) {
            console.error('Error uploading file:', error);
            // Show error toast for any other errors
            toast.error('Failed to upload file');
        }
    };

    useEffect(() => {
        
        if ( checkFileExists("Bundles-invoices/" + fileName) ){
            onClose();
        }
    
    }, [fileName]);

    return (
        <Modal
            overlayClassName="modal-overlay"
            className="modal-content"
            isOpen={isOpen}
            onRequestClose={() => {
                onClose();
            }}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2,
                },
                content: {
                    width: '20%',
                    height: '30%',
                    textAlign: 'center',
                },
            }}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                submitUploadInvoice(file);
            }}>
                <div style={{ marginTop: '-15px', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                    <h3>Upload File</h3>

                    <input
                        type="file"
                        accept="*"
                        onChange={(e) => setFile(e.target.files[0])}
                        style={{ display: 'none' }} // Hide the actual file input
                        id="fileInput" // Assign an ID for the label to reference
                    />
                    <label htmlFor="fileInput" 
                        style={{ marginBottom: '30px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                    >
                        Choose File
                    </label>
                    {file && 
                        <>
                            <br/>
                            <br/>
                            <span>{file.name}</span>
                            <br/>
                            <br/>
                            <button 
                                type="submit"
                                style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Submit
                            </button>
                        </>
                    }
                </div>
            </form>
            <ToastContainer/>
        </Modal>
    );
};

export default InvoiceBundleModal;
