import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Inventory from './pages/Inventory';
import Orders from './pages/Orders';
import Home from './pages/Home';
import Reporting from './pages/Reporting';
import Account from './pages/Account';
import Recommendation from './pages/Recommendation';
import Automation from './pages/Automation';
import SpotCheck from './pages/SpotCheck';
import System from './pages/System';
import Payments from './pages/Payments';
import Invoices from './pages/Invoices';
import Mapping from './pages/Mapping';
import Corporate from './pages/Corporate';
import logo from './assets/images/logo.png';
import Settings from './pages/Settings';
import Operations from './pages/Operations';
import OperationsNew from './pages/OperationsNew';
import Suppliers from './pages/Suppliers';

import { signOut } from "firebase/auth";
import { auth } from "./pages/authentication/Config";
import { useNavigate } from 'react-router-dom'

import './assets/style/index.css';

// The TopBar component for navigation
const TopBar = ({ locationData, username, onLocationChange }) => {

  const [locationName, setLocationName] = useState(localStorage.getItem('locationName') || '');
  const [permissionCode, setPermissionCode] = useState('');


  const uniqueLocations = Array.from(new Set(locationData.map(item => item.location_name)));


  const location = useLocation();


  // Set the default location and fetch permission code when the component mounts or locationData changes
  useEffect(() => {
    if (uniqueLocations.length > 0 && !localStorage.getItem('locationName')) {
      const defaultLocationName = uniqueLocations[0]; // Get the first location as default
      setLocationName(defaultLocationName); // Set the default location name
      localStorage.setItem('locationName', defaultLocationName); // Save the default location name to localStorage
      fetchPermissionCodeForDefaultLocation(defaultLocationName); // Fetch the permission code for the default location
    } else if (localStorage.getItem('locationName') && uniqueLocations.includes(localStorage.getItem('locationName'))) {
      // If there's a locationName in localStorage, use it to fetch the permission code
      fetchPermissionCodeForDefaultLocation(localStorage.getItem('locationName'));
    }
  }, [locationData]); // Depend on locationData so this effect runs when locationData changes

  useEffect(() => {
    // Call the callback function whenever permissionCode or locationName changes
    onLocationChange(locationName, permissionCode);
  }, [permissionCode, locationName, onLocationChange]);

  const fetchPermissionCodeForDefaultLocation = async (locationName) => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/permission-code/${username}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ locationName }),
    });

    const result = await response.json();
    setPermissionCode(result.permission_code);
  };

  const fetchPermissionCode = async (e) => {
    const selectedLocationName = e.target.value;
    setLocationName(e.target.value);
    localStorage.setItem('locationName', selectedLocationName); // Save the selected location name to localStorage


    const response =  await fetch(process.env.REACT_APP_BACKEND_URL + `/permission-code/${username}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({locationName: e.target.value}),
      
    });
  
    const result = await response.json();

    setPermissionCode(result.permission_code);
  }

  


  return (
    <div className="top-bar">
      <Link to="/" className="logo-link">
        <img src={logo} alt="Logo" className="logo-img" />
      </Link>
      <div className="nav-links">
      {(permissionCode === 11 || permissionCode === 21 || permissionCode === 22) && (
        <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>
          Home
        </Link>
      )}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 22 || permissionCode === 25 || permissionCode === 26) && (
        <Link to="/inventory" className={`nav-link ${location.pathname === '/inventory' ? 'active' : ''}`}>
          Inventory
        </Link>
        )}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 22 || permissionCode === 25 || permissionCode === 26) && (
        <Link to="/orders" className={`nav-link ${location.pathname === '/orders' ? 'active' : ''}`}>
          Orders
        </Link>
        )}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 23 || permissionCode === 22 || permissionCode === 25 || permissionCode === 26) && (
          <Link to="/spotcheck" className={`nav-link ${location.pathname === '/spotcheck' ? 'active' : ''}`}>
            SpotCheck
          </Link>
        )}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 22 || permissionCode === 24) && (
        <Link to="/reports" className={`nav-link ${location.pathname === '/reports' ? 'active' : ''}`}>
          Reporting
        </Link>
        )}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 23 || permissionCode === 22 || permissionCode === 24 || permissionCode === 25 || permissionCode === 26) && (
          <Link to="/suppliers" className={`nav-link ${ location.pathname === '/suppliers' ? 'active' : '' }`}>
            Suppliers
          </Link>
        )}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 23 || permissionCode === 22 || permissionCode === 24 || permissionCode === 25 || permissionCode === 26) && (
        <Link to="/account" className={`nav-link ${location.pathname === '/account' || location.pathname === '/settings' ? 'active' : ''}`}>
          Account
        </Link>
        )}
        
       
      </div>

      <select
        value={locationName}
        onChange={fetchPermissionCode}
        style={{ marginLeft: "10px", padding: '5px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #000', fontStyle: 'italic', width: '150px', backgroundColor: '#87D3D7' }}
        className='nav-link-account'
      >
        {uniqueLocations.map(location_name => (
          <option key={location_name} value={location_name}>
            {location_name}
          </option>
        ))}
      </select>

    </div>
  );
};

const App = () => {
  const call = process.env.REACT_APP_BACKEND_URL; // http://localhost:3000 //lhost:3000

  const [locationData, setLocationData] = useState([]);
  const userEmail = localStorage.getItem('userEmail');
  const [locationName, setLocationName] = useState(''); // State to hold the selected location name
  const [permissionCode, setPermissionCode] = useState('');
  const [username, setUsername] = useState('');

  const navigate = useNavigate();

  useEffect(() => {

    fetchLocations();
  

  }, []);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      navigate('/')
    }).catch((error) => {
      console.error("Error signing out", error);
    });
  };

  const fetchLocations = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/get-user-location/${userEmail}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const result = await response.json();
  
      // Check if result is null, undefined, or an empty array
      if (!result || result.length === 0) {
        // If the result is invalid, call signout()
        handleSignOut();
        alert("Please try logging in again. If the issue persists, please contact Medvelle for assistance.");
      } else {
        // If valid, set the location data and username
        setLocationData(result);
        setUsername(result[0].username);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      // Optionally, handle error by calling signout or another action
      handleSignOut();
    }
  };  

  // Callback function to update locationName in App's state
  const handleLocationChange = (newLocationName, permissionCode) => {
    setLocationName(newLocationName);
    setPermissionCode(permissionCode);
  };

  return (
    <>
      <TopBar locationData = {locationData} username = {username} onLocationChange={handleLocationChange} />
      <Routes>
       
        {/* Conditionally render SpotCheck route based on permissionCode */}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 23 || permissionCode === 22 || permissionCode === 25 || permissionCode === 26) && <Route path="/spotcheck" element={<SpotCheck locationName={locationName} call={call} permissionCode = {permissionCode} />} />}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 22 || permissionCode === 25 || permissionCode === 26) && <Route path="/inventory" element={<Inventory locationName={locationName} call={call} permissionCode = {permissionCode}/>} />}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 22 || permissionCode === 25 || permissionCode === 26) && <Route path="/orders" element={<Orders locationName={locationName} call={call} permissionCode={permissionCode}/>} />}
        {(permissionCode === 11 ) && <Route path="/recommendation" element={<Recommendation locationName={locationName} call={call} />} />}
        {(permissionCode === 11 ) && <Route path="/automation" element={<Automation locationName={locationName} call={call} />} />}
        {(permissionCode === 11 ) && <Route path="/operations" element={<Operations locationName={locationName} call={call} />} />}
        {(permissionCode === 11 ) && <Route path="/operations_new" element={<OperationsNew />} />}
        {(permissionCode === 11 ) && <Route path="/system" element={<System locationName={locationName} call={call} />} />}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 23 || permissionCode === 22 || permissionCode === 24 || permissionCode === 25 || permissionCode === 26) && <Route path="/account" element={<Account locationName={locationName} call={call} permissionCode = {permissionCode}/>} />}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 22) && <Route path="/" element={<Home locationName={locationName} call={call} permissionCode = {permissionCode}/>} />}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 22 || permissionCode === 24) && <Route path="/reports" element={<Reporting locationName={locationName} call={call} permissionCode = {permissionCode} />} />}
        {(permissionCode === 11 ) && <Route path="/invoices" element={<Invoices locationName={locationName} call={call} />} />}
        {(permissionCode === 11 ) && <Route path="/payments" element={<Payments locationName={locationName} call={call} />} />}
        {(permissionCode === 11 ) && <Route path="/mapping" element={<Mapping locationName={locationName} call={call} />} />}
        {(permissionCode === 11 ) && <Route path="/corporate" element={<Corporate locationName={locationName} call={call} />} />}
        {(permissionCode === 11 || permissionCode === 21 || permissionCode === 22 ) && <Route path="/suppliers" element={<Suppliers locationName={locationName} call={call} permissionCode={permissionCode}/>} />}

        {(permissionCode === 11 || permissionCode === 21) && <Route path="/settings" element={<Settings locationName={locationName} permissionCode = {permissionCode}/>} />}

      </Routes>
    </>
  );
};

export default App;