import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { replyToEmail, downloadFile } from '../../utils/notifications/notificationsUtils';
import { fetchEmailConversation } from '../../utils/notifications/notificationsUtils';
import { fetchTicketsInfo } from '../../utils/locations/locationsUtils';

import loadingSpinner from '../../assets/images/loading.gif';

// Make sure to set the app element for accessibility
Modal.setAppElement('#root'); // Adjust the selector based on your app's root element

const EmailTrackingModal = ({ isOpen, onClose, emailConversation, setEmailConversation, emailID, bundleID, setTickets, locationNameSelected }) => {

    const { body, from, sent_date, subject, message_id, to_recipients, cc_recipients } = emailConversation.lastMessage;
    const attachments = emailConversation.attachments;
    const [replyMessage, setReplyMessage] = useState('');
    const [senderEmail, setSenderEmail] = useState('order@medvelle.com');
    const [recipientEmails, setRecipients] = useState(from === senderEmail ? to_recipients :[from]);
    const [ccEmails, setCCEmails] = useState(cc_recipients);
    const [isSendReplyLoading, setIsSendReplyLoading] = useState(false);

    const handleReply = async () => {
        try {
            setIsSendReplyLoading(true);
            await replyToEmail(senderEmail, message_id, replyMessage, recipientEmails, ccEmails, bundleID); // Replace 'your_bundle_id' with the actual bundle ID if needed
            setIsSendReplyLoading(false);
            alert('Reply sent successfully!');
            setReplyMessage('');
            onClose();
            await fetchEmailConversation(emailID, 'order@medvelle.com', setEmailConversation);
            await fetchTicketsInfo(setTickets, locationNameSelected);
        } catch (error) {
            console.error('Failed to send reply:', error);
            alert('Failed to send reply.');
            setIsSendReplyLoading(false);

        }
    };

    const parseEmails = (input) => {
        // Split the string by commas or comma followed by spaces
        const emailArray = input.split(/,\s*/);
        // Trim whitespace from each email
        return emailArray.map(email => email.trim());
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="modal-content"
            overlayClassName="modal-overlay"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2
                },
                content: {
                    width: '60%',
                    height: '80%',
                    position: 'relative',
                }
            }}
        >
            <button
                style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '15px',
                    right: '15px'
                }}
                className="button-style"
                onClick={onClose}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>

            <h2>Email Conversation</h2>

            <div
                style={{
                    border: '2px solid #87D3D7',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxHeight: '80%', // Adjust height to account for header and button
                    overflowY: 'auto',
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                    backgroundColor: 'white'
                }}
            >
                <h3>Subject: {subject}</h3>
                <p><strong>From:</strong> {from}</p>
                <strong>To:</strong> {to_recipients.join(', ')}
                <br />
                <strong>CC:</strong> {cc_recipients.join(', ')}
                <br />
                <p><strong>Last Message Date:</strong> {new Date(sent_date).toLocaleString()}</p>
                <div
                    className="email-body"
                    dangerouslySetInnerHTML={{ __html: body }}
                    style={{
                        marginTop: '20px',
                        maxHeight: '100%' // Ensure content is scrollable if necessary
                    }}
                />
                
                {attachments && attachments.length > 0 && (
                    <div
                        style={{
                            marginTop: '20px',
                            borderTop: '1px solid #ddd',
                            paddingTop: '10px'
                        }}
                    >
                        <h4>Attachments:</h4>
                        <ul>
                            {attachments.map(attachment => (
                                <li key={attachment.id}>
                                    <button
                                        onClick={() => downloadFile(attachment.email_id, senderEmail, attachment.id, attachment.name)}
                                        style={{
                                            backgroundColor: '#87D3D7',
                                            border: 'none',
                                            borderRadius: '4px',
                                            color: 'white',
                                            padding: '5px 10px',
                                            cursor: 'pointer',
                                            margin: '5px'
                                        }}
                                    >
                                        Download {attachment.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div
                    style={{
                        marginTop: '20px',
                        borderTop: '1px solid #ddd',
                        paddingTop: '10px'
                    }}
                >
                    <label htmlFor="emailAddress">Recipient Email(s):</label>
                    <input
                        type="email"
                        id="emailAddress"
                        value={recipientEmails}
                        onChange={(e) => setRecipients(parseEmails(e.target.value))}
                        style={{ width: '100%' }}
                    />
                    <label htmlFor="emailAddress">CC Email(s):</label>
                    <input
                        type="email"
                        id="emailAddress"
                        value={ccEmails}
                        onChange={(e) => setCCEmails(parseEmails(e.target.value))}
                        style={{ width: '100%' }}
                    />
                    <h4>Reply:</h4>
                    <textarea
                        value={replyMessage}
                        onChange={(e) => setReplyMessage(e.target.value)}
                        style={{
                            width: '100%',
                            height: '100px',
                            borderRadius: '4px',
                            padding: '10px',
                            border: '1px solid #ccc'
                        }}
                    />
                </div>

                <div
                    style={{
                        marginTop: '20px',
                        borderTop: '1px solid #ddd',
                        paddingTop: '10px'
                    }}
                >
                    <h4>Sender Email:</h4>
                    <input
                        type="email"
                        value={senderEmail}
                        onChange={(e) => setSenderEmail(e.target.value)}
                        style={{
                            width: '100%',
                            height: '30px',
                            borderRadius: '4px',
                            padding: '10px',
                            border: '1px solid #ccc'
                        }}
                    />
                </div>

                {isSendReplyLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, marginBottom:'20px' }}>
                        <img
                            src={loadingSpinner}
                            alt="loading-spinner"
                            style={{
                            height: '20px',
                            marginRight: '10px',
                            padding: '10px'
                        }} />
                    </div>
                    ) : (
                    <button
                        onClick={handleReply}
                        style={{
                            marginTop: '20px',
                            backgroundColor: '#87D3D7',
                            border: 'none',
                            borderRadius: '4px',
                            color: 'white',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    >
                        Send Reply
                    </button>
                )}

            </div>
        </Modal>
    );
};

export default EmailTrackingModal;
