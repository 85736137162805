import React from 'react';
import Modal from 'react-modal';
import ModalHeader from '../../components/ModalHeader';
import {formatDateText} from '../../utils/time_zones/TimeZones';
import tick from '../../assets/images/green-tick.png';

const NotificationsModal = ({isOpen, setIsOpen, onClose=()=>{}, messages, handleMessage, locationName }) => {
    
    
    const handleClose = () =>{
        onClose();
        setIsOpen(false);
    };

    return (
        <Modal
            overlayClassName="white-modal-overlay"
            className="white-modal"
            isOpen={isOpen}
            onRequestClose={handleClose}
            style={{content:{width:'40vw'}}}
        >
            <ModalHeader title={'Notifications'} showExit={true} onClose={handleClose} />
            <div style={{margin:'20px'}}>
                <div style={{maxHeight: '300px', overflowY: 'auto'}}>
                    <table style={{width: '100%', borderCollapse: 'collapse'}}>
                        <thead>
                            <tr>
                                <th style={{borderBottom: '1px solid black', borderRight: '1px solid black', textAlign: 'center', padding: '5px'}}>Date</th>
                                <th style={{borderBottom: '1px solid black', textAlign: 'center', padding: '5px'}}>Message</th>
                                <th style={{borderBottom: '1px solid black', textAlign: 'center', padding: '5px', borderLeft: '1px solid black'}}>Mark as Read</th> {/* Added borderLeft */}
                            </tr>
                        </thead>
                        <tbody>
                            {messages
                                .filter(message => message.read_status === 0 && message.location_name === locationName) // Add locationName filter
                                .map((message, index) => (
                                    <tr key={index}>
                                        <td style={{borderBottom: '1px solid black', borderRight: '1px solid black', textAlign: 'center', padding: '5px'}}>{formatDateText(message.date_time)}</td>
                                        <td style={{borderBottom: '1px solid black', textAlign: 'center', padding: '5px'}}>{message.message}</td>
                                        <td style={{borderBottom: '1px solid black', textAlign: 'center', padding: '5px', borderLeft: '1px solid black'}}> {/* Added borderLeft */}
                                            <button onClick={() => handleMessage(message)} style={{backgroundColor: 'transparent', marginRight: '10px', border: 'none', cursor: 'pointer'}}> {/* Pass the message to handleMessage function */}
                                                <img src={tick} alt="green-tick" style={{height: '20px', width: '20px'}} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    )
    
}
export default NotificationsModal