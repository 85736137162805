import React, {useEffect, useState, useRef} from 'react';
import Modal from 'react-modal';
import ModalHeader from '../../components/ModalHeader';
import Table from '../../components/Table';
import LineGraph from '../../components/LineGraph';
import PromotionCarousel from './PromotionCarousel';
import Credentials from '../../components/Credentials';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faUnlock, faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import { updateCredentials } from '../../utils/suppliers/supplierUtils';
import RepModal from './RepModal';

const SupplierModal = ({supplier, isOpen, setIsOpen, onClose, promotions, locationName, fetchClientSuppliers, permissionCode}) => {
    const [totalSpend, setTotalSpend] = useState({});
    const [topProducts, setTopProducts] = useState([]);
    const [credentials, setCredentials] = useState({});
    const [repModalOpen, setRepModalOpen] = useState(false)
    const [isPressed, setIsPressed] = useState(false);
    const [isEditPressed, setIsEditPressed] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showEditTooltip, setShowEditTooltip] = useState(false);
    const [representatives, setRepresentatives] = useState([])
    const unlockable = (permissionCode === 21 || permissionCode === 11);
    const hoverText = unlockable ? 'Click to view credentials' : 'You must be an owner to access supplier credentials.'
    const hoverEditText = 'Click to edit credentials.'

    useEffect(() => {
        setCredentials({password: supplier.password, username: supplier.username});
    }, [promotions]);

    useEffect(() => {
        fetchSupplierVisualiation();
        fetchTopProducts();
        fetchRepresentatives()
    }, [supplier, isOpen]);

    useEffect(()=>{
        if (isPressed) {
            setIsEditPressed(false);
        }
    }, [isPressed])
    useEffect(() => {
        if (isEditPressed) {
            setIsPressed(false);
        }
    }, [isEditPressed])


    const handleClose = () => {
        setIsOpen(false);
        onClose();
    };

    const onSave = async (username, password) => {
        const result = await updateCredentials(supplier.supplier_id, locationName, username, password)
        fetchClientSuppliers();
        return result
    }

    const fetchRepresentatives = async () => {
        try {
            const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/client-supplier-reps`);
            const data = await response.json();
            setRepresentatives(data.filter(rep=> (rep.location === locationName && rep.supplier_id === supplier.supplier_id)));
            return data;
        } catch (error) {
            console.error('Error fetching reps data:', error);
        }
    };

    const fetchSupplierVisualiation = async () => {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/location-supplier-summary/${ locationName }/${ supplier.supplier_id }`);
        if (!response.ok) {
            throw new Error('Error fetching supplier data');
        }
        const result = await response.json();
        setTotalSpend(result.reduce((acc, item) => {
            acc[item.ym] = item.total_spent;
            return acc;
        }, {}));
    };

    const fetchTopProducts = async () => {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/location-supplier-top-products/${ locationName }/${ supplier.supplier_id }`);
        if (!response.ok) {
            throw new Error('Error fetching supplier data');
        }
        const result = await response.json();
        setTopProducts(result);

    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            className="white-modal"
            overlayClassName="white-modal-overlay"
            style={{content: {width: '80vw', height: '80vh'}}}
        >
            <ModalHeader
                title={supplier.supplier_name}
                description={'Supplier info for'}
                subheader={supplier.description}
                onClose={handleClose}
                showExit={true}
            />
            {/* Modal header with download and close buttons */}
            <div style={{
                margin: '20px',
            }}>
                <>
                    <div style={{display: 'flex', marginBottom:'10px'}}>
                        <div style={{width: '45%', }}>
                            <h3 style={{marginTop: '0px', marginBottom: '8px'}}>General Info</h3>
                            <div style={{display: 'flex',gap:'40px'}}>
                                <div>
                                    <div style={{color: 'grey'}}>Total Spend</div>
                                    <div style={{fontSize: 'large',marginTop:'4px'}}>${parseInt(supplier.total_spent?.toFixed(0)).toLocaleString()}</div>
                                </div>
                                <div>
                                    <div style={{color: 'grey'}}>Total Orders</div>
                                    <div style={{fontSize: 'large',marginTop: '4px'}}>{supplier.total_orders}</div>
                                </div>
                                <div>
                                    <div style={{color: 'grey'}}>Promotions</div>
                                    <div style={{fontSize: 'large',marginTop: '4px'}}>{supplier.promotions}</div>
                                </div>
                            </div>
                        </div>

                        <div style={{width: '45%', marginLeft: '20px' }}>
                            <div style={{display:'flex'}}>
                                <h3 style={{marginTop: '0px', marginBottom: '8px'}}>Contact & Credentials</h3>

                                <div
                                    style={{
                                        position: 'relative', // Set position relative for tooltip positioning
                                        cursor: 'pointer',
                                        marginLeft: '8px',
                                        alignContent: 'flex-end',
                                        marginBottom: '10px',
                                        display: !credentials.username && !credentials.password ? 'none' : ''
                                    }}
                                  
                                    onClick={() => {setIsPressed(unlockable ? !isPressed : false)}}
                                    onMouseEnter={() => setShowTooltip(true)} // Show tooltip on hover
                                    onMouseLeave={() => {setShowTooltip(false);}} // Hide tooltip when not hovering
                                >
                                    <FontAwesomeIcon
                                        icon={isPressed ? faUnlock : faLock} // Toggle between lock and unlock icons
                                        // size=""
                                        color={isPressed ? '#E4846E' : '#31bac1'}
                                        className={!isPressed ? 'icon-hover' : ''}

                                    />
                                    {(showTooltip && hoverText && !isPressed) && (
                                        <div style={{
                                            position: 'absolute',
                                            bottom: '110%', // Position above the icon
                                            left: '0%',
                                            backgroundColor: '#333',
                                            color: '#fff',
                                            padding: '5px 10px',
                                            borderRadius: '5px',
                                            whiteSpace: 'nowrap',
                                            zIndex: 1, // Ensure it appears above other elements
                                            fontSize: '12px',
                                        }}>
                                            {hoverText}
                                        </div>
                                    )}
                                </div>
                                <div
                                    style={{
                                        position: 'relative', // Set position relative for tooltip positioning
                                        cursor: 'pointer',
                                        marginLeft: '8px',
                                        alignContent: 'flex-end',
                                        marginBottom: '10px',
                                        display: !unlockable ? 'none' : !credentials.username && !credentials.password ? 'none' : ''
                                    }}

                                    onClick={() => {setIsEditPressed(!isEditPressed)}}
                                    onMouseEnter={() => setShowEditTooltip(true)} // Show tooltip on hover
                                    onMouseLeave={() => {setShowEditTooltip(false);}} // Hide tooltip when not hovering
                                >
                                    <FontAwesomeIcon
                                        icon={faPencilAlt} // Toggle between lock and unlock icons
                                        color={isEditPressed ? '#E4846E' : '#31bac1'}
                                        className= {!isEditPressed ? 'icon-hover' : ''}
                                    />
                                    {(showEditTooltip && hoverEditText) && (
                                        <div style={{
                                            position: 'absolute',
                                            bottom: '110%', // Position above the icon
                                            left: '0%',
                                            backgroundColor: '#333',
                                            color: '#fff',
                                            padding: '5px 10px',
                                            borderRadius: '5px',
                                            whiteSpace: 'nowrap',
                                            zIndex: 1, // Ensure it appears above other elements
                                            fontSize: '12px',
                                        }}>
                                            {hoverEditText}
                                        </div>
                                    )}
                                </div>
                            </div>
                            
                            <div style={{display:'flex', gap:'10px'}}>
                                <div style={{width: 'auto', marginRight: '0px', display: representatives.length === 0 ? 'none' : ''}}>
                                    <div style={{color: 'grey', alignContent: 'flex-start'}}>Representatives</div>
                                    <button className='action-button' disabled={!unlockable} onClick={() => setRepModalOpen(true)} style={{marginTop: '4px', minWidth: '150px', alignContent:'flex-end'}}>
                                        View Representatives
                                    </button>
                                </div>
                                <div style={{display: !credentials.username && !credentials.password ? 'none' : '', width:'100%' }}>
                                    <Credentials password={credentials.password} username={credentials.username} unlocked={isPressed || isEditPressed} isUnlockable={unlockable} isEditable={isEditPressed} onSave={onSave}/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{display: 'flex', gap: '8px', flexWrap: 'wrap'}}>
                        <div style={{width: promotions.length > 0 ? '45%' : '95%', minWidth: '350px', maxHeight: '400px'}}>
                            <h3 style={{color: 'gray', marginBottom: '-20px'}}>Monthly Spend</h3>

                            <LineGraph
                                data={totalSpend}
                                title=""
                                xAxisLabel=""
                                yAxisLabel={
                                    "Spend" // Fallback in case product_unit is undefined
                                }
                                lineColor="#F49C7D"
                                width={promotions.length > 0 ? 1000 : 3000}
                                height={800}
                                yPrefix='$'
                            />
                        </div>
                        
                        <div style={{width: '50%', minWidth:'350px', display:promotions.length > 0 ? '' : 'none'}}>
                            <h3 style={{color: 'gray', marginBottom: '10px', marginLeft: '10px'}}>Promotions</h3>
                            <PromotionCarousel cards={promotions} waitTime={3000} />
                        </div>
                    </div>

                    <h3 style={{color: 'gray', marginBottom: '8px'}}>Top Products</h3>
                    <div>
                        <Table
                            data={topProducts}
                            columns={[
                                'Product Name',
                                'Total Sales Price',
                                'Total Orders',
                                'Total Units',
                                'Avg. Sales Price Per Unit',
                                'Inventory Quantity',
                                'Predicted Weekly Usage',
                                'Estimated Weeks Remaining'
                            ]}
                            mapping={{
                                'Product Name': 'product_name',
                                'Total Orders': 'total_orders',
                                'Total Sales Price': 'total_spend',
                                'Total Units': 'total_order_quantity',
                                'Avg. Sales Price Per Unit': 'avg_price',
                                'Inventory Quantity': 'inventory_quantity',
                                'Predicted Weekly Usage':'usage_forecast',
                                'Estimated Weeks Remaining':'weeks_remaining'
                            }}
                            prefix={{'Total Sales Price': '$', 'Avg. Sales Price Per Unit': '$'}}
                            isHoverable={true}
                            height={'250px'}
                            columnWidths={{'Product Name': '20%'}}
                            callBack={
                                {
                                    'Total Sales Price': (number) => {return parseInt(number.toFixed(0)).toLocaleString();},
                                    'Total Orders': (number) => {return parseInt(number.toFixed(0)).toLocaleString();},
                                    'Avg. Sales Price Per Unit': (number) => {return parseFloat(number.toFixed(2)).toLocaleString();}
                                }
                            }
                        />
                    </div>

                </>
                <RepModal reps={representatives} supplier={supplier} isOpen={repModalOpen} setIsOpen={setRepModalOpen} onClose={()=>{}}/>


            </div>
            
        </Modal>
    );
};

export default SupplierModal;