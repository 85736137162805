import React, {useEffect, useState, useRef} from 'react';
import {Slide, toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Coin from '../assets/images/coin.png';
import Coin2 from '../assets/images/coin2.png';
import CoinGIF from '../assets/images/coin1.gif';
import {Buffer} from 'buffer';
import {formatDateToUTCString, newDate} from '../utils/time_zones/TimeZones';
import {sortArrayByAttribute, handleSortChange, getArrow} from '../utils/tables/sortUtils';
import { fetchUsername } from '../utils/users/userUtils';
import SupportForm from '../features/support/SupportForm';
import UnitConversionDisplay from '../features/inventory/UnitConversionDisplay'
import {checkFirstSpotcheck, fetchRewards, fetchSpotcheckInventory, fetchTokens, loadMoreSpotcheck} from '../utils/spotcheck/spotcheckInfoUtils';
import RetailModal from '../features/spotcheck/RetailModal';
import SuccessModal from '../features/spotcheck/SuccessModal';
import {closeAbortController, createAbortController, getAbortSignal} from '../utils/controller/abortUtils';

const SpotCheck = ({locationName, call, permissionCode}) => {
  const contentRef = useRef();
  const abortControllerRef = useRef(null);  // Create a ref to hold the AbortController
  const [inventoryData, setInventoryData] = useState([]); //store the spotcheck products
  const [selectedCategory, setSelectedCategory] = useState(''); // State to manage selected category
  const [username, setUsername] = useState(''); //store the username
  const [isContentVisible, setIsContentVisible] = useState(false); // State to manage content visibility
  const [isModalOpen, setIsModalOpen] = useState(false);  //Spotcheck popup modal
  const [modalMessage, setModalMessage] = useState('');
  const [isRetailModalOpen, setIsRetailModalOpen] = useState(false);  //Spotcheck popup modal
  const [modalRef, setModalRef] = useState(null);
  const [correctOption, setCorrectOption] = useState(null);
  const [retailMessage, setRetailMessage] = useState("");
  const [retailTitle, setRetailTitle] = useState("");
  const [modalQuantConv, setModalQuantConv] = useState(1);
  const [unitModalInput, setUnitModalInput] = useState(0);
  const [modalItem, setModalItem] = useState({});
  const [tokens, setTokens] = useState(0);
  const [isRewardsModalOpen, setIsRewardsModalOpen] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [isFirst, setIsFirst] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [selectedUnitOptions, setSelectedUnitOptions] = useState({});


  const [coinGifPosition, setCoinGifPosition] = useState({top: 0, left: 0});
  const [animateCoin, setAnimateCoin] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null); // Add this line to track the selected 
  const [successMessage, setSuccessMessage] = useState('');

  const [showCoinGif, setShowCoinGif] = useState(false);

  const [coin2GifPosition, setCoin2GifPosition] = useState({top: 0, left: 0});
  const [showCoin2Gif, setShowCoin2Gif] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [sortAttribute, setSortAttribute] = useState("");
  const [sortAsc, setSortAsc] = useState(true);

  const [showContact, setShowContact] = useState(false);

  const locationInventoryData = inventoryData.filter(item => item.location_name === locationName);

  const uniqueCategories = Array.from(new Set(locationInventoryData.map(item => item.product_cat)));

  const filteredInventory = sortArrayByAttribute(locationInventoryData.filter(item =>
    (selectedCategory === '' || item.product_cat.toLowerCase() === selectedCategory.toLowerCase())
  ), sortAttribute, sortAsc);

  const isRedeemButtonDisabled = selectedReward ? tokens < selectedReward.reward_points : true;
  const sortedRewards = rewards.sort((a, b) => a.reward_points - b.reward_points);

  useEffect(() => {
    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '50px';
  }, []);

  useEffect(() => {
    createAbortController(abortControllerRef);
    const signal = getAbortSignal(abortControllerRef);
    
    checkTimeVisibility(); // Check visibility on component mount

    fetchUsername(setUsername, signal);

    fetchData();

    fetchRewards(setRewards, locationName, signal)
    checkFirstSpotcheck(locationName, setIsFirst, signal)

    return () => {
      closeAbortController(abortControllerRef);
    };
  }, [locationName]);


  useEffect(() => {

    if (tokens === 0) {
      return;
    }

    if (tokens > 0) {  // Assuming tokens are initially 0 or reset somehow in your app
      setShowCoinGif(true);
      const timer = setTimeout(() => {
        setShowCoinGif(false);
      }, 1500); // Hide after 1 second

      return () => clearTimeout(timer);
    }
  }, [tokens]); // This effect runs every time 'tokens' changes

  //function to fetch the spotcheck products (spotcheck status = 3 or 4)
  const fetchData = async () => {
    const signal = getAbortSignal(abortControllerRef)
    fetchSpotcheckInventory(locationName, setInventoryData, signal);
    fetchTokens(setTokens, signal);
  };

  // Function to open rewards modal
  const openRewardsModal = () => {
    setIsRewardsModalOpen(true);
  };

  // Function to close rewards modal
  const closeRewardsModal = () => {
    setIsRewardsModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (['e', 'E', '+', '-'].includes(event.key)) {
      event.preventDefault();
    }
  };

  // Add the handleRedeem function
  const handleRedeem = async (reward) => {
    setIsRewardsModalOpen(false); // Close the rewards shop modal
    setSuccessMessage(`You have successfully redeemed ${ reward.product_name }. An order has been created, and Medvelle will process and deliver to your location, thank you.`);

    setIsSuccessModalOpen(true); // Open the success modal

    try {
      const response = await fetch(`${ call }/handle-redeemed-rewards`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reward_id: reward.product_id,
          username: username,
          locationName: locationName,
          reward_points: reward.reward_points,
          supplier_id: reward.supplier_id
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

    } catch (error) {
      console.error('Error redeeming reward:', error);
    }

    fetchData();
  };

  const handleSelectChange = (rowId, value) => {
    // Update the selected option for the given row
    setSelectedUnitOptions(prevState => ({
      ...prevState,
      [rowId]: value,
    }));
  };

  // Function to check if current time is within the allowed time range
  const checkTimeVisibility = () => {
    const currentDateTime = newDate();
    const hours = currentDateTime.getHours();

    // Content is visible before 11 AM EST
    if (hours < 11) {
      setIsContentVisible(true);
    } else {
      setIsContentVisible(true);
    }
  };

  function alert(input_value, expected_value, product_conversion) {

    // Calculate the absolute difference between input_value and expected_value
    const diffInputValue = Math.abs(input_value - expected_value);

    // Calculate the absolute difference between (input_value / product_conversion) and expected_value
    const diffConvertedValue = Math.abs((input_value / product_conversion) - expected_value);

    // Compare the differences and return the appropriate value
    if (diffInputValue < diffConvertedValue) {
      // input_value is closer to expected_value
      return 0;
    } else {
      // (input_value / product_conversion) is closer to expected_value
      return 1;
    }

  }

  const handleLoadSpotcheck = async () => {
    const success = await loadMoreSpotcheck(locationName)
    if (success) fetchData(); // Refresh the data after loading more spotchecks
  };

  const handleSubmit = async (productID, invQuantity, product_conversion, item, spotcheck_status, product_cat, product_name, product_unit, product_class) => {

    // Validation: Check if input quantity is empty or not a number
    if (!invQuantity || isNaN(parseFloat(invQuantity)) || parseFloat(invQuantity) < 0) {
      // If input quantity is empty or not a number, show error message
      toast.error('Please enter a valid quantity.');
      return false;
    }


    const parsedQuantity = parseFloat(invQuantity).toFixed(2);
    invQuantity = product_conversion ? Math.round(parsedQuantity * product_conversion) : Math.round(parsedQuantity);

    const currentInventoryDataResponse = await fetch(`${ call }/inventory_product?product_id=${ productID }&location_name=${ locationName }`);
    const currentInventoryData = await currentInventoryDataResponse.json();

    const {inventory_quantity} = currentInventoryData[0];

    const response = await fetch(`${ call }/check-inventory-quantity/${ productID }`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({invQuantity, locationName, spotcheck_status}),
    });

    const data = await response.json();

    if (isFirst.includes(productID)) {

      const response = await fetch(`${ call }/insert-inventory-quantity/${ productID }`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({invQuantity, locationName}),
      });

      const data = await response.json();

    }

    if (spotcheck_status === 3) {
      if (data.isQuantityMatching || isFirst.includes(productID)) {

        setInputValues(prevValues => ({
          ...prevValues,
          [productID]: ''
        }));

        toast.success(`Quantity verified and up-to-date!`);
        // Calculate positions for animation
        const submitButtonRect = item.submitRef.getBoundingClientRect();
        const rewardsButtonRect = document.getElementById('rewards-button').getBoundingClientRect();
        setCoinGifPosition({top: submitButtonRect.top, left: submitButtonRect.left});

        if (product_class >= 1.25) {
          setAnimateCoin(true);
        }

        setTimeout(() => {
          setCoinGifPosition({top: rewardsButtonRect.top, left: rewardsButtonRect.left});
          setTimeout(() => {
            setShowCoinGif(true);
            setAnimateCoin(false);
          }, 1000); // Duration of the animation
        }, 100);

        const old_inventory_quantity = inventory_quantity;
        let new_inventory_quantity = inventory_quantity;
        if (isFirst.includes(productID)) {
          new_inventory_quantity = invQuantity;
        }
        const product_id = productID;
        const location_name = locationName;
        const origin = "Spotcheck";
        const date_time = formatDateToUTCString(new Date());

        fetch(`${ call }/insert-tracking-data/${ productID }`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({location_name, username, product_id, old_inventory_quantity, new_inventory_quantity, date_time, origin}),
        });

        fetch(`${ call }/insert-tokens`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({username, product_class}),
        });

        if (product_class < 1.25) {
          // Calculate positions for animation
          const submitButtonRect = item.submitRef.getBoundingClientRect();
          const rewardsButtonRect = document.getElementById('rewards-button').getBoundingClientRect();
          setCoin2GifPosition({top: submitButtonRect.top - 20, left: submitButtonRect.left - 20});
          setShowCoin2Gif(true);

          setTimeout(() => {
            setCoin2GifPosition({top: '300px', left: '700px'});
            setTimeout(() => {
              setCoin2GifPosition({top: rewardsButtonRect.top, left: rewardsButtonRect.left});
              setTimeout(() => {
                setShowCoin2Gif(false);
              }, 1000); // Duration of the animation
            }, 2000); // Duration of the animation
          }, 100);
        }

        return true;
      }
      else {
        const expected_value = product_conversion ? inventory_quantity / product_conversion : inventory_quantity;

        const flag = alert(parsedQuantity, expected_value, product_conversion);

        const isRetail = product_cat.toLowerCase().includes('retail');

        if (isRetail || item.product_tier !== 1 || (item.product_tier === 1 && item.quantity_conversion === 1)) {
          const errorTitle = `The entered count does not match the inventory records for this ${item.product_cat.toLowerCase()} product`;
          const error_message = `Please make sure you have counted all products in ${locationName}${isRetail ? ', including inventory on display' : ''}.`;
          setRetailTitle(errorTitle)
          setRetailMessage(error_message);
          setIsRetailModalOpen(true);
        }
        else {
          if (flag === 1) {
            setUnitModalInput(parseFloat((invQuantity / item.quantity_conversion).toFixed(2)));
            setModalItem(item);
            openModalWithQuestion(item.quantity_conversion, product_unit, item.product_name);
          }
          else {
            toast.error(`Please re-count this product in your inventory`);
          }
        }
        return false;
      }
    }
    else if (spotcheck_status === 4) {
      toast.success(`Quantity verified and up-to-date!`);

      setInputValues(prevValues => ({
        ...prevValues,
        [productID]: ''
      }));
      // Calculate positions for animation
      const submitButtonRect = item.submitRef.getBoundingClientRect();
      const rewardsButtonRect = document.getElementById('rewards-button').getBoundingClientRect();
      setCoinGifPosition({top: submitButtonRect.top, left: submitButtonRect.left});
      if (product_class >= 1.25) {
        setAnimateCoin(true);
      }

      setTimeout(() => {
        setCoinGifPosition({top: rewardsButtonRect.top, left: rewardsButtonRect.left});
        setTimeout(() => {
          setShowCoinGif(true);
          setAnimateCoin(false);
        }, 1000); // Duration of the animation
      }, 100);

      if (product_class < 1.25) {
        // Calculate positions for animation
        const submitButtonRect = item.submitRef.getBoundingClientRect();
        const rewardsButtonRect = document.getElementById('rewards-button').getBoundingClientRect();
        setCoin2GifPosition({top: submitButtonRect.top - 20, left: submitButtonRect.left - 20});
        setShowCoin2Gif(true);

        setTimeout(() => {
          setCoin2GifPosition({top: '300px', left: '700px'});
          setTimeout(() => {
            setCoin2GifPosition({top: rewardsButtonRect.top, left: rewardsButtonRect.left});
            setTimeout(() => {
              setShowCoin2Gif(false);
            }, 1000); // Duration of the animation
          }, 2000); // Duration of the animation
        }, 100);
      }

      fetch(`${ call }/insert-tokens`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({username, product_class}),
      });

      if (data.isQuantityMatching) {

        const old_inventory_quantity = inventory_quantity;
        const new_inventory_quantity = inventory_quantity;
        const product_id = productID;
        const location_name = locationName;
        const origin = "Spotcheck";
        const date_time = formatDateToUTCString(new Date());

        fetch(`${ call }/insert-tracking-data/${ productID }`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({location_name, username, product_id, old_inventory_quantity, new_inventory_quantity, date_time, origin}),
        });
      }

      else {


        const origin = "Spotcheck Open";
        const date_time = formatDateToUTCString(new Date());

        const old_inventory_quantity = inventory_quantity;
        const new_inventory_quantity = invQuantity;
        const product_id = productID;
        const location_name = locationName;

        fetch(`${ call }/insert-tracking-data/${ productID }`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({location_name, username, product_id, old_inventory_quantity, new_inventory_quantity, date_time, origin}),
        });

      }
      return true
    };


  };

  const handleSubmissionAndRefresh = async (productID, invQuantity, product_conversion, item, spotcheck_status, product_cat, product_name, product_unit, product_class) => {
    const result = await handleSubmit(productID, invQuantity, product_conversion, item, spotcheck_status, product_cat, product_name, product_unit, product_class); // Wait for handleSubmit to finish
    fetchData();
    return result
  };

  const toggleContactInfo = () => {
    setShowContact(!showContact);
  };

  const openModalWithQuestion = (product_conversion, product_unit, product_name) => {
    setModalQuantConv(product_conversion);
    setCorrectOption(product_unit);
    setIsModalOpen(true);
    setModalMessage(`Our system detected an anomaly with your previous submission for ${product_name}. Please check your count before submitting the spotcheck.`)
  };

  const handleClose = () => {
    setIsRewardsModalOpen(false);
  };

  const closeAndResetModal = () => {
    setIsModalOpen(false); // Close the modal
    setModalItem({});
    setModalQuantConv(1);
    setUnitModalInput(0);
    setModalRef(null);
  };

  const bufferToBase64 = (buffer) => {
    const base64String = Buffer.from(buffer).toString('base64');
    return `data:image/png;base64,${ base64String }`;
  };

  const handleCardClick = (reward) => {
    if (selectedReward === reward) {
      setSelectedReward(null); // Deselect if the same card is clicked again
    } else {
      setSelectedReward(reward); // Select the clicked card
    }
  };

  return (

    <div ref={contentRef} style={{textAlign: 'left'}}>

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
        <h1 style={{fontSize: '40px', fontWeight: 'bold', color: '#87D3D7', }}>SpotCheck</h1>

        <div style={{display: 'flex', alignItems: 'center', }}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'left', position: 'relative', marginRight: '30px', marginTop: '20px'}}>
            <div style={{display: 'flex', flexDirection: 'row', }}>
              <img
                id="rewards-button"
                src={Coin}
                alt="Coin"
                style={{
                  width: '35px',

                }}
              />
              <span style={{fontSize: '18px', marginTop: '5px', marginLeft: '4px'}}>{tokens} coins</span>
            </div>
            <button
              className='support-button'
              onClick={openRewardsModal}
              style={{
                fontSize:'small',
                width: '140px',
              }}
            >
              Claim Rewards
            </button>
          </div>


          <button
            className="support-btn"
            onClick={toggleContactInfo}
            style={{
              backgroundColor: '#87D3D7',
              padding: '10px',
              borderRadius: '50%',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            <h1 style={{fontSize: '20px', margin: 0}}>?</h1>
          </button>
        </div>

        {showContact && (
          <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
        )}
      </div>


      <Modal className="modal-content"
        isOpen={isRewardsModalOpen}
        onRequestClose={closeRewardsModal}
        style={{
          content: {
            width: '1200px',
            height: '720px',
            borderRadius: '20px', // For rounded edges
            backgroundColor: '#87D3D7' // Setting modal's color
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)' // Dimmed background
          }
        }}
      >
        <div style={{display: 'block', justifyItems: 'center', marginBottom: '20px'}}>
          <h1 style={{color: 'black', marginLeft: '500px'}}>Rewards Shop</h1>

          <div style={{display: 'flex', alignItems: 'right', marginTop: '-55px', marginLeft: '1055px'}}>
            <img
              id="rewards-button"
              src={Coin}
              alt="Coin"
              style={{
                width: '35px',
                marginRight: '2px',
              }}
            />
            <span style={{textAlign: 'right', fontSize: '15px', marginTop: '7px'}}>{tokens} coins</span>
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          {sortedRewards.map((reward, index) => (
            <div
              key={index}
              className={`reward-card ${ reward === selectedReward ? 'selected' : '' }`}
              onClick={() => {handleCardClick(reward); }} // Add onClick handler
            >
              {reward === selectedReward && (
                <div className='tick'>✓</div> // Display tick if selected
              )}
              {reward.product_picture ? (
                <img className='reward-image' src={bufferToBase64(reward.product_picture.data)} alt={reward.product_name} />
              ) : (
                  <div className='reward-image' style={{ backgroundColor: '#ddd', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <span>No Image</span>
                </div>
              )}
              <h3>{reward.product_name}</h3>
              <p>{reward.reward_points} points</p>
            </div>
          ))}
        </div>
        <div style={{display: 'flex', justifyContent: 'center', gap: '20px'}}>
          <button className='reward-button' 
            onClick={() => handleClose()}  >Close</button>
          <button className='reward-button'
            onClick={() => handleRedeem(selectedReward)} disabled={isRedeemButtonDisabled} >Redeem</button>
        </div>

      </Modal>

      <Modal className="white-modal" overlayClassName="white-modal-overlay" isOpen={isModalOpen}
        onRequestClose={() => {
          closeAndResetModal();
        }}>

        <div style={{
          display: 'flex', // Use flexbox
          justifyContent: 'center', // Center horizontally in the flex container
          width: '100%' // Take up the full width to allow centering
        }}>
          <h1>Attention!</h1>
        </div>
        <div style={{flex: '0 1 auto', borderBottom: '1px solid #ccc'}}>
        </div>
        <h3 style={{textAlign: 'center', fontWeight:'normal', margin:'20px'}}>{modalMessage}</h3>

        <div style={{display: 'flex', marginTop:'20px', justifyContent: 'center', alignItems: 'center'}}>
          <UnitConversionDisplay quantity={unitModalInput} setQuantity={setUnitModalInput} quantityConversion={modalQuantConv} units={correctOption} subUnits={modalItem.product_subunit} tier={modalItem.product_tier}/>
        </div>
        <div style={{marginTop: '40px', marginBottom:'40px', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
          <button
            className='support-button'
            onClick={async () => {
              const item = {...modalItem};
              item.submitRef = modalRef;
              const verifiedSpotcheckStatus = 4;
              // submit the modalInput amount (not individual count) with the item quantity conversion. Original behavior and function. 
              const success = await handleSubmissionAndRefresh(modalItem.product_id, unitModalInput, modalItem.quantity_conversion, item, verifiedSpotcheckStatus, modalItem.product_cat, modalItem.product_name, modalItem.product_unit, modalItem.class);
              if (success){
                closeAndResetModal();
              }
            }}
          >Submit</button>
        </div>
      </Modal>

      <RetailModal isOpen={isRetailModalOpen} setIsOpen={setIsRetailModalOpen} retailTitle={retailTitle} retailMessage={retailMessage} onClose={()=>setIsRetailModalOpen(false)}/>

      <ToastContainer
        transition={Slide}
        position="top-center"
        style={{

          width: 'fit-content'
        }}
      />
      <div style={{display: 'flex', justifyContent: 'left', marginBottom: '20px', marginTop: '9px'}}>
        <select
          className='select-box'
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}

          style={{marginTop: '-10px', marginRight: '20px', padding: '10px', fontStyle: 'italic', width: '300px'}}

        >
          <option value="">All Categories</option>
          {uniqueCategories.sort().map(product_cat => (
            <option key={product_cat} value={product_cat}>
              {product_cat}
            </option>
          ))}
        </select>

      </div>

      {isContentVisible ? (


        <div style={{
          border: '2px solid #87D3D7',
          justifyContent: 'left',
          width: '100%',
          maxHeight: '610px',
          minHeight: '610px',
          overflowY: 'auto', // Enable vertical scrolling
          boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
        }}>


          <table style={{
            backgroundColor: '#ffffff',
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
            position: 'relative' // Ensure positioning context
          }}>
            <thead style={{
              position: 'sticky', top: 0
            }}>
              <tr style={{fontSize: '13px', backgroundColor: '#fffff', color: '#000000'}}>
                <th onClick={() => handleSortChange('product_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: "30%", textAlign: 'left', backgroundColor: '#ffffff', cursor: 'pointer'}}>Product Name {getArrow('product_name', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('product_description', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: "20%", textAlign: 'left', backgroundColor: '#ffffff', cursor: 'pointer'}}>Description {getArrow('product_description', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('product_cat', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: "20%", textAlign: 'left', backgroundColor: '#ffffff', cursor: 'pointer'}}>Category {getArrow('product_cat', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('manufacturer_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: "15%", textAlign: 'left', backgroundColor: '#ffffff', cursor: 'pointer'}}>Manufacturer {getArrow('manufacturer_name', sortAttribute, sortAsc)}</th>
                <th style={{borderBottom: '2px solid black', padding: '10px', width: "30%", textAlign: 'left', backgroundColor: '#ffffff'}}>Quantity</th>
                <th style={{borderBottom: '2px solid black', padding: '10px', width: "10%", textAlign: 'center', backgroundColor: '#ffffff'}}>Submit</th>
              </tr>
            </thead>
            <tbody>
              {filteredInventory.length === 0 ? (
                <tr>
                  <td colSpan="6" style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '16px', fontStyle: 'italic', padding: '50px 10px', height: '350px'}}>
                    <button
                      className='enabled-button'
                      onClick={handleLoadSpotcheck}
                    >
                      <p style={{fontWeight: 'bold'}}>Earn More Points</p>
                    </button>
                  </td>
                </tr>
              ) : (
                filteredInventory.map((item, index) => (
                  <tr key={item.product_id} style={{fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff'}}>
                    <td style={{padding: '10px'}}>{item.product_name}</td>
                    <td style={{padding: '10px'}}>{item.product_description}</td>
                    <td style={{padding: '10px'}}>{item.product_cat}</td>
                    <td style={{padding: '10px'}}>{item.manufacturer_name}</td>
                    <td style={{padding: '10px', textAlign: 'center'}}>
                      <div style={{display: 'flex'}}>
                        <input
                          type="number"
                          min="0"
                          onKeyDown={handleKeyDown}
                          onWheel={e => {
                            e.target.blur(); // Blur the input when scrolling
                          }}
                          style={{width: '50px'}}
                          value={inputValues[item.product_id] || ''}
                          onChange={e => setInputValues(prevValues => ({
                            ...prevValues,
                            [item.product_id]: e.target.value
                          }))}
                          ref={inputRef => item.inputRef = inputRef}  // Save a reference to the input field
                        />
                          {(item.quantity_conversion > 1 && item.product_tier === 1) ? (
                            <select
                              value={selectedUnitOptions[item.product_id] || 'unit'}
                              onChange={(e) => handleSelectChange(item.product_id, e.target.value)}

                              style={{marginLeft: '4px', minWidth:'80px', padding: '4px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7',}}

                            >
                              <option value="unit">{item.product_unit === 'box' ? 'box(es)' : `${ item.product_unit }(s)`}</option>
                              <option value="individual">unit(s)</option> 
                            </select>
                            ) :
                            (
                              <span style={{marginLeft: '12px'}}>{item.product_unit === 'box' ? 'box(es)' : `${ item.product_unit }(s)`}</span>
                            )
                          }
                      </div>

                    </td>
                    <td style={{padding: '10px', textAlign: 'center'}}>
                      <button ref={submitRef => item.submitRef = submitRef}
                        className='action-button'

                        onClick={(event) => {
                          setModalRef(event.target);
                          const conversion = (selectedUnitOptions?.[item.product_id] === 'individual') ? 1 : (item.quantity_conversion);
                          handleSubmissionAndRefresh(item.product_id, item.inputRef.value, conversion, item, item.spotcheck_status, item.product_cat, item.product_name, item.product_unit, item.class);
                        }}
                        style={{fontSize:'small'}}>Submit</button>
                    </td>
                  </tr>
                )))}
            </tbody>
          </table>

        </div>



      ) : (


        <div style={{textAlign: 'center', marginTop: '50px', fontSize: '20px'}}>
          You are currently outside the SpotCheck time window. SpotCheck closes at 11 am EST.
        </div>
      )}


      {animateCoin && (
        <img
          src={CoinGIF}
          alt="Coin Animation"
          style={{
            position: 'fixed',
            top: coinGifPosition.top,
            left: coinGifPosition.left,
            width: '50px',
            height: '50px',
            transition: 'all 1s ease'
          }}
        />
      )}

      {showCoin2Gif && (
        <img
          src={Coin2}
          alt="Coin2 Animation"
          style={{
            position: 'fixed',
            top: coin2GifPosition.top,
            left: coin2GifPosition.left,
            width: '150px',
            height: '150px',
            transition: 'all 2s ease',
            transform: coin2GifPosition.transform || 'none',
            transformOrigin: 'center',
            animation: showCoin2Gif ? 'none' : 'none'
          }}
        />
      )}
      <SuccessModal isOpen={isSuccessModalOpen} setIsOpen={setIsSuccessModalOpen} onClose={()=>setIsSuccessModalOpen(false)} successMessage={successMessage}/>
    </div>

  );
};

export default SpotCheck;