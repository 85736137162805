
import Modal from 'react-modal';
import LineGraph from '../../components/LineGraph';
import React, {useEffect, useState} from 'react';
import {fetchUserDetails} from "../../utils/users/userUtils";

const RecommendationDetailsModal = ({isOpen, setIsOpen, onClose, recommendationDetails, recommendationPrediction, productUsageData, onDecline, onAccept}) => {
    const [requestingUser, setRequestingUser] = useState(null)

    const handleClose = () => {
        setRequestingUser(null)
        onClose();
        setIsOpen(false);
    };

    useEffect(()=>{
        if (recommendationDetails.recommendation_rationale === 'Staff Request'){
            fetchUserDetails({locationName: recommendationDetails.location_name, setUserDetails: setRequestingUser, username:recommendationDetails.order_comment})
        }
    }, [isOpen])

    return (
        <Modal
            overlayClassName="modal-overlay"
            isOpen={isOpen}
            onRequestClose={() => {
                handleClose()
            }}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
                content: {
                    width: '50%',
                    height: '85%',
                    margin: 'auto',
                    padding: '0',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'rgba(251, 251, 251)', // Set the background color to white
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <div style={{flex: '0 1 auto', padding: '20px', borderBottom: '1px solid #ccc'}}>
                <span style={{color: 'gray', fontSize: '20px'}}>Product Details for</span>
                <br />
                <b style={{color: 'black', fontSize: '25px'}}>
                    {recommendationDetails.product_name} {recommendationDetails.product_description ? `- ${ recommendationDetails.product_description }` : ''}
                </b>
            </div>

            <div style={{flex: '1 1 auto', padding: '20px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '20px'}}>
                    <div style={{width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <h3 style={{color: 'gray'}}>Current Quantity</h3>
                        <ul style={{listStyleType: 'none', padding: 0}}>
                            <li>
                                <b style={{fontSize: '40px', color: 'black'}}>
                                    {Object.keys(recommendationPrediction).length > 0 && (
                                        <>{recommendationPrediction.current_inventory_quantity.toFixed(1)}</>
                                    )}
                                </b>
                            </li>
                        </ul>
                    </div>

                    <div style={{width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <h3 style={{color: 'gray'}}>Weeks Remaining</h3>
                        <ul style={{listStyleType: 'none', padding: 0}}>
                            <li>
                                <b style={{fontSize: '40px', color: 'rgb(132, 94, 29)'}}>
                                    {Object.keys(recommendationPrediction).length > 0 && (
                                        <>{(recommendationPrediction.current_inventory_quantity / (recommendationPrediction.usage_forecast / 2)).toFixed(1)}</>
                                    )}
                                </b>
                            </li>
                        </ul>
                    </div>

                    <div style={{width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <h3 style={{color: 'gray'}}>Suggested to Order</h3>
                        <ul style={{listStyleType: 'none', padding: 0}}>
                            <li>
                                <b style={{fontSize: '40px', color: '#31bac1'}}>
                                    {recommendationDetails.order_quantity}
                                </b>
                            </li>
                        </ul>
                    </div>
                </div>

                <h3 style={{color: 'gray'}}>Product Usage Per Week</h3>
                <div style={{width: '100%', height: '250px', marginTop: '-30px'}}>
                    <LineGraph
                        data={productUsageData}
                        title=""
                        xAxisLabel=""
                        yAxisLabel={
                            recommendationPrediction.product_unit
                                ? (recommendationPrediction.product_unit === 'box' ? 'box(es)' : `${ recommendationPrediction.product_unit }(s)`) + " used"
                                : "units used" // Fallback in case product_unit is undefined
                        }
                        lineColor="#F49C7D"
                        width={1000}
                        height={400}
                    />
                </div>
                {recommendationDetails.recommendation_rationale === 'Staff Request' && ( 
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '20px'}}>
                        <div style={{width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            <h3 style={{color: 'gray', marginBottom:'0px'}}>Requested By</h3>
                            <ul style={{listStyleType: 'none', padding: 0}}>
                                <li>
                                    <b style={{fontSize: '20px', color: 'black'}}>
                                    {(requestingUser?.first_name || requestingUser?.last_name) && (
                                        <>
                                            <div>{requestingUser?.first_name ? requestingUser?.first_name : ''} {requestingUser?.last_name ? requestingUser?.last_name : ''} </div>
                                        </>
                                    )}
                                    </b>
                                </li>
                            </ul>
                        </div>

                        <div style={{width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            <h3 style={{color: 'gray', marginBottom: '0px'}}>Requested Comment</h3>
                            <ul style={{listStyleType: 'none', padding: 0}}>
                                <li>
                                    <b style={{fontSize: '20px', color: 'black'}}>
                                    {recommendationDetails?.client_comment && (
                                        <>
                                            <div>{recommendationDetails.client_comment}</div>
                                        </>
                                    )}
                                    </b>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
                
            </div>

            <div style={{flex: '0 1 auto', padding: '20px', borderTop: '1px solid #ccc'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <button
                    className="decline-button"
                        onClick={onDecline}
                        style={{
                            fontSize: 'small'
                        }}
                    >
                        Decline
                    </button>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <button
                            className="action-button-white"
                            onClick={handleClose}
                            style={{
                                // fontSize: 'medium'
                            }}
                        >
                            Close
                        </button>
                        <button
                            onClick={onAccept}
                            className="action-button"
                            style={{
                                // fontSize:'medium'
                            }}
                        >
                            Accept
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RecommendationDetailsModal;