import React, { useState, useEffect } from 'react';
import loadingSpinner from '../../assets/images/loading.gif';
import { fetchTicketsInfo, fetchSuppliers } from '../../utils/locations/locationsUtils';
import { handleBundleSubmit } from '../../utils/procurement/procurementUtils';
import { formatDateToUTCString, formatDateToLocalString } from '../../utils/time_zones/TimeZones';

const ShippedFlow = ({ ticketInfo, setTicketInfo, onClose, origin, locationNameSelected, setTickets, username }) => {
   
    const [eta, setEta] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [comment, setComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [supplier, setSupplier] = useState(null);

    useEffect(() => {

        // Update bundle info
        updateBundleInfo();

        // Fetch suppliers
        const loadSuppliers = async () => {
            const supplier_data = await fetchSuppliers(ticketInfo.location_name, ticketInfo.bundle_supplier_id);
            setSupplier(supplier_data[0]);

        };
        loadSuppliers();
    
    }, [ticketInfo]);

    const updateBundleInfo = async () => {
        setEta(ticketInfo.eta);
        setTrackingNumber(ticketInfo.tracking_number)
        setComment(ticketInfo.bundle_comment)
    };

    const handleFormSubmit = async (status) => {
        await handleBundleSubmit(
            ticketInfo, status, '', setTicketInfo, onClose,
            setIsLoading, ticketInfo.bundle_supplier_id, origin,
            {}, trackingNumber, true,
            ticketInfo.shipping, ticketInfo.taxes, ticketInfo.discount, comment, ticketInfo.due_date, username, eta
        );

        await fetchTicketsInfo(setTickets, locationNameSelected);
    };

    const formatPhoneNumber = (phoneNumber) => {
        try{

            if (phoneNumber === '' || !phoneNumber){
                return '';
            }

            const phoneString = phoneNumber.toString();
          
            const areaCode = phoneString.slice(0, 3);
            const centralOfficeCode = phoneString.slice(3, 6);
            const lineNumber = phoneString.slice(6, 10);
          
            return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
        } catch (error){
            return phoneNumber;
        } 
    };

    const getdetailsWindow = () => (
        <div>
            <div>
                <p><strong>Customer Service Phone Number:</strong> {supplier && formatPhoneNumber(supplier.cust_service_phone)} | <strong>Customer Service Email:</strong> {supplier && supplier.cust_service_email}</p>
            </div>
            <div>
                <p><strong>Platform website:</strong> {supplier && supplier.website} | <strong>Username:</strong> {supplier && supplier.username} | <strong>Password:</strong> {supplier && supplier.password}</p>
            </div>
            <div>
                <p><strong>Rep Name:</strong> {supplier && (supplier.rep_first_name + ' ' + supplier.rep_last_name)} | <strong>Rep Email:</strong> {supplier && supplier.rep_email} | <strong>Rep Phone Number:</strong> {supplier && formatPhoneNumber(supplier.rep_phone)}</p>
            </div>
            <div style={{ display: 'flex'}}>
                <div style={{marginRight: '10px'}}>
                    <label>Tracking Number:</label>
                    <input type="text" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} style={{ padding: '10px', marginBottom: '10px' }} />
                </div>
                <div style={{ marginRight: '10px' }}>
                    <label>ETA:</label>
                    <input 
                        type="datetime-local" 
                        value={eta && formatDateToLocalString(eta)} 
                        onChange={(event) => {
                            setEta(formatDateToUTCString(event.target.value));
                        }}
                        placeholder="YYYY-MM-DDTHH:MM"
                        style={{ padding: '10px', marginBottom: '10px' }} 
                    />
                </div>
            </div>
            <div className="input-group">
                <strong>Comment:</strong>
                <br/>
                <textarea value={comment} onChange={(e) => setComment(e.target.value)} style={{ padding: '10px', marginBottom: '10px', width: '100%', boxSizing: 'border-box' }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', marginTop: '20px' }}>
                
                { isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <img
                            src={loadingSpinner}
                            alt="loading-spinner"
                            style={{
                                height: '20px',
                                marginRight: '10px',
                                padding: '10px'
                            }}
                        />
                    </div>
                ) : (
                    <>
                    <button 
                        onClick={() => handleFormSubmit('Delivered')}
                        className='button-style'
                    >
                        Move to Delivered
                    </button>
                    </>
                )}
            </div>
        </div>
    );

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '70vh',
            padding: '20px', 
            overflow: 'auto'
        }}>          
            {getdetailsWindow()}
        </div>
    );
};

export default ShippedFlow;
