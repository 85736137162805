import React, {useState} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEquals} from '@fortawesome/free-solid-svg-icons';

const UnitConversionDisplay = ({quantity, setQuantity,  quantityConversion, units, subUnits, maxQuantity, tier}) => {
    const [indQuantity, setIndQuantity] = useState(parseFloat((quantity * quantityConversion).toFixed(0)))
    const handleKeyDown = (event) => {
        if (['e', 'E', '+', '-'].includes(event.key)) {
            event.preventDefault();
        }
    };
    return (
        <div style={{display: 'flex' }}>
            <input
                type="number"
                step={.1}
                min="0"
                className='select-box'
                max={maxQuantity ? maxQuantity/quantityConversion : ''}
                onWheel={e => {
                    e.target.blur(); // Blur the input when scrolling
                }}
                style={{width: '100px', marginRight: '8px'}}
                value={parseFloat(quantity?.toFixed(1))}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                    setQuantity(parseFloat(e.target.value));
                    setIndQuantity(parseFloat((e.target.value * quantityConversion).toFixed(2)));
                }}
            />
            {units && (
                <span style={{marginRight: '8px', alignContent: 'center'}}>{units === 'box' ? 'box(es)' : `${ units }(s)`}</span>
            )}
            
            {(tier === 1 && quantityConversion > 1) && (
                <>
                    <div style={{alignContent: 'center'}}>
                        <FontAwesomeIcon icon={faEquals} />
                    </div>

                    <input
                        type="number"
                        step={1}
                        min="0"
                        max={maxQuantity ? maxQuantity : ''}
                        className='select-box'

                        onWheel={e => {
                            e.target.blur(); // Blur the input when scrolling
                        }}
                        style={{width: '100px', marginLeft: '8px', marginRight: '8px', alignContent: 'center'}}
                        value={indQuantity}
                        onKeyDown={handleKeyDown}

                        onChange={(e) => {
                            setIndQuantity(parseFloat(e.target.value));
                            setQuantity(parseFloat((e.target.value / quantityConversion).toFixed(2)));
                        }}
                    />
                    <span style={{marginRight: '8px', alignContent: 'center'}}>{subUnits}</span>
                </>
            )}
            
        </div>
    );
};

export default UnitConversionDisplay;