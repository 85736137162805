import React, { useState, useEffect } from 'react';
import OperationsModal from './OperationsModal';
import { fetchTicketInfo, fetchTicketsInfo } from '../../utils/locations/locationsUtils';
import { formatDateToUTCString, formatDateTimeText } from '../../utils/time_zones/TimeZones';
import { updateOrdersCode } from '../../utils/procurement/procurementUtils';
import loadingSpinner from '../../assets/images/loading.gif';
import ManageModal from './ManageModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash} from '@fortawesome/free-solid-svg-icons';

const TicketTable = ({ tickets, userList, setTickets, locationName, suppliers, selectedBundles, setSelectedBundles }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ticketInfo, setTicketInfo] = useState(null);
    const [isBundleLoading, setIsBundleLoading] = useState(null);
    const [isManageModalOpen, setIsManageModalOpen] = useState(false);
    const [bundleID, setBundleID] = useState(null);

    const handleStartClick = async (ticket) => {
        setIsBundleLoading(ticket.bundle_id);
        
        await fetchTicketInfo(setTicketInfo, ticket.bundle_id);
        
        setIsBundleLoading(null);
        setIsModalOpen(true);
    };

    // Function to handle order checkbox change
    const handleBundleCheckboxChange = (bundle) => {
        if (selectedBundles.some(b => b.bundle_id === bundle.bundle_id)) {
            setSelectedBundles(selectedBundles.filter(b => b.bundle_id !== bundle.bundle_id));
        } else {
            setSelectedBundles([...selectedBundles, bundle]);
        }
    };

    const getAllOrders = (selectedBundles) => {
        return selectedBundles.reduce((accumulator, bundle) => {
            return accumulator.concat(bundle.orders);
        }, []);
    };

    const getNewBundleDate = (bundles) => {
        // Extract order_date values from all objects in the bundle
        const allBundleDates = bundles.map(bundle => bundle.bundle_date);
        
        // Find the maximum date from the array of order dates
        const maxDate = new Date(Math.max(...allBundleDates.map(date => new Date(date))));

        return maxDate;
    };

    const checkOneLocation = (bundles) => {
    
        const firstLocationName = bundles[0].location_name;
    
        return selectedBundles.every(bundle => bundle.location_name === firstLocationName);
    };

    // Function to handle Apply button click
    const mergeBundles = async () => {

        let orderCodeToSend;

        if (selectedBundles.length <= 1){
            alert("You have to select 2 tickets minimum to merge.");
            return;
        }

        if (!checkOneLocation(selectedBundles)){
            alert("You can only merge bundles from the same location.");
            return;
        }

        try {
            // Fetch the next order code from the API
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get_max_order_code');
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const data = await response.json();
            
            // Use the returned next order code
            orderCodeToSend = data.next_order_code;
    
        } catch (error) {
            console.error('Error fetching max order code:', error);
        
            // If there's an error, fallback to order code 1
            orderCodeToSend = 1;
        }

        await fetch(process.env.REACT_APP_BACKEND_URL + '/create-bundle', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            orderCode: orderCodeToSend, // Example order code
            shipping: 0, // Example shipping
            taxes: 0, // Example taxes
            discount: 0,
            bundleDate: formatDateToUTCString(getNewBundleDate(selectedBundles)).split('T')[0].split(' ')[0],
            dueDate: formatDateToUTCString(getNewBundleDate(selectedBundles)).split('T')[0].split(' ')[0]
        })
        })
        .then(response => {
        if (response.ok) {
            console.log('Bundle updated successfully');
        } else {
            console.error('Failed to update bundle');
        }
        })
        .catch(error => {
        console.error('Error updating bundle:', error);
        });

        await updateOrdersCode(orderCodeToSend, getAllOrders(selectedBundles));

        await fetchTicketsInfo(setTickets, locationName);
    
        // Clear selected orders and order code
        setSelectedBundles([]);
    
    };

     // Function to get the appropriate content based on the order code
    function renderBundleID(codeID) {
        switch (codeID) {
        case 0:
            return <FontAwesomeIcon icon={faTrash} />;
        case -1:
            return 'Recommended';
        case -2:
            return 'Free Products';
        case -3:
            return 'Declined';
        case -4:
            return 'Web';
        case -5:
            return 'Rewards';
        case -999:
            return 'Errors';
        default:
            return null; // Or some default content if necessary
        }
    }

    function sortTicketsByBundleId(tickets) {
        return tickets.sort((a, b) => {
          if (a.bundle_id > 0 && b.bundle_id <= 0) {
            return -1; // a comes before b
          }
          if (a.bundle_id <= 0 && b.bundle_id > 0) {
            return 1; // b comes before a
          }
          if (a.bundle_id <= 0 && b.bundle_id <= 0) {
            return a.bundle_id - b.bundle_id; // Sort in increasing order for bundle_id <= 0
          }
          return 0; // Preserve original order for other elements
        });
    } 

    useEffect(() => {
        const intervalId = setInterval(async () => {
            await fetchTicketsInfo(setTickets, locationName);
        }, 5000); // fetch every 5 seconds
    
        return () => clearInterval(intervalId); // cleanup on unmount
    }, [locationName]);

    return (

        <div>
            <button className="button-style" style={{cursor: 'pointer', marginBottom: '20px'}} onClick={mergeBundles}>Merge</button>
            
            <div style={{ border: '2px solid #87D3D7', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%', height: '610px', overflow: 'auto' }}>
                <table style={{  
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0, 
                    position: 'relative' // Ensure positioning context
                }}>
                    <thead style={{
                        position: 'sticky', // Stick the header
                        top: 0, // Stick to the top of the table
                    }}>
                        <tr style={{ fontSize: '14px', backgroundColor: 'white'  }}>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Select</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Bundle ID</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>importance</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Location Name</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Status</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Manufacturer</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Supplier</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>ETA</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Tracking Number</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Assigned To</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Assignement Comment</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Action</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tickets && sortTicketsByBundleId(tickets).map((ticket, index) => (
                            ticket.bundle_id > 0 ? (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: ticket.flag === 1 ? '#e9453b' : (index % 2 === 0 ? '#ebf4f5' : '#ffffff') }} >
                                    <td style={{ textAlign: 'center' }}>
                                        {(ticket.bundle_status !== 'Delivered' && ticket.bundle_status !== 'Shipped' && ticket.bundle_status !== 'Moved') ? (
                                            <input
                                                type="checkbox"
                                                checked={selectedBundles.some(bundle => bundle.bundle_id === ticket.bundle_id)}
                                                onChange={() => handleBundleCheckboxChange(ticket)}
                                                style={{ width: '20px', height: '20px' }}
                                            />
                                        )
                                            :
                                            (
                                                <span></span>
                                            )
                                        }
                                    </td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.bundle_id}</td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center'}}>{ticket.minImportanceScore}</td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.location_name}</td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.bundle_status}</td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket?.bundle_manufacturer_name || 'Unknown Manufacturer'}</td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{suppliers.find(item => item.supplier_id === ticket.bundle_supplier_id)?.supplier_name || 'Unknown Supplier'}</td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.eta ? formatDateTimeText(ticket.eta) : ''}</td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.tracking_number}</td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.assigned_to}</td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.assignment_comment}</td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center'}}>
                                        {isBundleLoading === ticket.bundle_id ? (
                                            <img
                                                src={loadingSpinner}
                                                alt="loading-spinner"
                                                style={{
                                                height: '20px',
                                                marginRight: '10px',
                                            }} />
                                            ) : (
                                            <button 
                                                style={{ padding: '5px 10px', backgroundColor: '#87D3D7', border: 'none', borderRadius: '4px', color: 'white', cursor: 'pointer' }} 
                                                onClick={() => handleStartClick(ticket)}
                                            >
                                                Start
                                            </button>
                                        )}
                                
                                    </td>
                                    <td style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center'}}>
                                        <button 
                                            style={{ padding: '5px 10px', backgroundColor: '#87D3D7', border: 'none', borderRadius: '4px', color: 'white', cursor: 'pointer' }} 
                                            onClick={() => { setBundleID(ticket.bundle_id); setIsManageModalOpen(true); }}
                                        >
                                            Assign
                                        </button>
                                    </td>
                                </tr>
                            ) : (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: ticket.flag === 1 ? '#e9453b' : (index % 2 === 0 ? '#ebf4f5' : '#ffffff') }} >
                                <td></td>
                                <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{renderBundleID(ticket.bundle_id)}</td>
                                <td colSpan="9"></td>
                                <td style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center'}}>
                                    {isBundleLoading === ticket.bundle_id ? (
                                        <img
                                            src={loadingSpinner}
                                            alt="loading-spinner"
                                            style={{
                                            height: '20px',
                                            marginRight: '10px',
                                        }} />
                                        ) : (
                                        <button 
                                            style={{ padding: '5px 10px', backgroundColor: '#87D3D7', border: 'none', borderRadius: '4px', color: 'white', cursor: 'pointer' }} 
                                            onClick={() => handleStartClick(ticket)}
                                        >
                                            Start
                                        </button>
                                    )}
                            
                                </td>
                                <td></td>
                            </tr>
                            )
                        ))}
                    </tbody>
                </table>
                {ticketInfo && <OperationsModal isOpen={isModalOpen} onClose={() => {setIsModalOpen(false); setTicketInfo(null);}} ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} setTickets={setTickets} locationName={locationName} />}
                {bundleID && <ManageModal bundleID={bundleID} isOpen={isManageModalOpen} onClose={() => { setIsManageModalOpen(false); setBundleID(null); }} userList={userList} setTickets={setTickets} locationName={locationName} />}
            </div>
        </div>
    );
};

export default TicketTable;
