import React from 'react';
import Modal from 'react-modal';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { formatDateTimeText } from '../../utils/time_zones/TimeZones';

const TrackingModal = ({ isOpen, onRequestClose, trackingData }) => {
  return (
    <Modal
      overlayClassName="modal-overlay"
      className="modal-content"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 2,
        },
        content: {
          width: '50%',
          height: '80%',
          overflowY: 'auto',
          padding: '20px',
        },
      }}
    >
      <div>
        {trackingData && trackingData.success ? (
          <div>
            <h2 style={{ textAlign: 'center' }}>Tracking Information</h2>
            <div style={{ 
              margin: 'auto', 
              textAlign: 'left', 
              maxWidth: 'fit-content', 
              marginBottom: '50px',
              backgroundColor: '#fff', 
              borderRadius: '10px',
              padding: '20px'
            }}>
              <p><strong>Carrier:</strong> <span style={{color: 'black'}}>{trackingData.carrier}</span></p>
              <p><strong>Summary:</strong> <span style={{color: 'black'}}>{trackingData.summary}</span></p>
              <p><strong>Is Delivered:</strong> <span style={{color: 'black'}}>{trackingData.is_delivered ? 'Yes' : 'No'}</span></p>
              {!trackingData.is_delivered && <p><strong>Estimated Delivery:</strong> <span style={{color: 'black'}}>{trackingData.estimated_delivery}</span></p>}
            </div>

            <br/>

            <VerticalTimeline>
              {trackingData.scans_detail.map((scan, index) => (
                <VerticalTimelineElement
                  key={index}
                  contentStyle={{ background: '#f0f0f0', color: '#000' }}
                  contentArrowStyle={{ borderRight: '7px solid  #f0f0f0' }}
                  date={<div style={{marginTop:'-20px'}}><p style={{margin:'20px'}}>{formatDateTimeText(scan.timestamp)}</p></div>}
                  iconStyle={{ background: '#F49C7D', color: '#000' }}
                >
                  <p style={{ textAlign: 'center', marginTop: '5px' }}>{scan.status} - {scan.location}</p>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>

            <br/>
            <div style={{ textAlign: 'center' }}>
              <button onClick={onRequestClose} style={{ backgroundColor: '#87D3D7', color: 'black', border: '1px solid black', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>Close</button>
            </div>
          </div>
        ) : (
          <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <h2 style={{ textAlign: 'center' }}>Tracking Information</h2>
            <div style={{ textAlign: 'center' }}>Could not find tracking data for this order.</div>

            <br/>
            <div style={{ textAlign: 'center' }}>
              <button onClick={onRequestClose} style={{ backgroundColor: '#87D3D7', color: 'black', border: '1px solid black', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>Close</button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TrackingModal;
