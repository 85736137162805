import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { fetchRecentBundleUpdates } from '../../utils/procurement/procurementUtils';
import { formatDateTimeText } from '../../utils/time_zones/TimeZones';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const BundleTrackingTableModal = ({ isOpen, onClose, bundleID }) => {
    const [recentBundleUpdates, setRecentBundleUpdates] = useState([]);

    useEffect(() => {
        fetchRecentBundleUpdates(setRecentBundleUpdates, bundleID);
    }, [recentBundleUpdates]);

    return (
        <Modal
            overlayClassName="modal-overlay"
            className="modal-content"
            isOpen={isOpen}
            onRequestClose={() => {onClose(); setRecentBundleUpdates([]);}}
            style={{
                overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 2
                },
                content: {
                width: '60%',
                height: '70%',
                }
            }}
        >
            <button
                style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '15px',
                    right: '15px'
                }}
                className="button-style"
                onClick={() => {onClose(); setRecentBundleUpdates([]);}}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>
            
            <br/>
            <br/>

            <div style={{
                border: '2px solid #87D3D7',
                display: 'flex',
                justifyContent: 'left',
                flexDirection: 'column',
                width: '100%',
                maxHeight: '410px',
                overflowY: 'auto',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <table id="order-updates-table" style={{
                    backgroundColor: '#ffffff',
                    width: '100%',
                    borderCollapse: 'separate',
                    borderSpacing: 0,
                    position: 'relative'
                }}>
                    <thead style={{ position: 'sticky', top: 0 }}>
                        <tr style={{ fontSize: '13px', backgroundColor: '#ffffff', color: '#000000' }}>
                            <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left', backgroundColor: '#ffffff', width: '20%' }}>Location Name</th>
                            <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left', backgroundColor: '#ffffff', width: '20%' }}>Product Name</th>
                            <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left', backgroundColor: '#ffffff', width: '40%' }}>Recent Actions</th>
                            <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left', backgroundColor: '#ffffff', width: '10%' }}>Change by</th>
                            <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left', backgroundColor: '#ffffff', width: '10%' }}>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(recentBundleUpdates && recentBundleUpdates.length > 0) ?
                            (
                                <>
                                    {recentBundleUpdates.map((item, index) => (
                                        <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                            <td style={{ padding: '10px' }}>{item.location_name}</td>
                                            <td style={{ padding: '10px' }}>{item.product_name}</td>
                                            <td style={{ padding: '10px' }}>{item.action}</td>
                                            <td style={{ padding: '10px' }}>{item.change_username}</td>
                                            <td style={{ padding: '10px' }}>{formatDateTimeText(item.time)}</td>
                                        </tr>
                                    ))}
                                </>
                            ):(
                                <tr>
                                    <td colSpan="8" style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '16px',fontStyle:'italic', padding:"1px", padding: '50px', height: '160px'  }}>
                                        No tracking data.
                                    </td>
                                </tr>
                            )}
                        
                        
                    </tbody>
                </table>
            </div>
        </Modal>
    );
};

export default BundleTrackingTableModal;
