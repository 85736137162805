import React, { useState } from 'react';
import SupportForm from '../features/support/SupportForm'; // Import your SupportForm component

const TopBar = ({ title, color, locationName, permissionCode }) => {
  const [showContact, setShowContact] = useState(false);

  const toggleContactInfo = () => {
    setShowContact(!showContact);
  };

  return (
    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      {/* Title Section */}
      <h2 style={{ marginTop: '27px', display: 'flex', fontSize: '40px', fontWeight: 'bold', color: color }}>
        {title}
      </h2>

      {/* Support Button */}
      <button className="support-btn" onClick={toggleContactInfo} style={{ backgroundColor: color, marginTop: '0px', marginRight: '-5px' }}>
        <h1 style={{ fontSize: '20px' }}>?</h1>
      </button>

      {/* Conditional Rendering of Support Form */}
      {showContact && (
        <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
      )}
    </div>
  );
};

export default TopBar;