import React, {useState, useEffect, createContext, useContext} from 'react';
import {onAuthStateChanged, signOut} from "firebase/auth";
import {useNavigate, useLocation} from 'react-router-dom';
import {auth} from "./Config";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [loginStatus, setLoginStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [referrer, setReferrer] = useState('/')
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            try {
                if (user && user.email && user.email.trim() !== '') {
                    localStorage.setItem('userEmail', user.email);
                    setLoginStatus(true);

                    // Start a logout timer upon successful login
                    const logoutTimer = setTimeout(() => {
                        signOut(auth); // Log the user out
                    }, 10800000); // 3 hours in milliseconds

                    // Cleanup timer on unmount or onAuthStateChanged change
                    return () => clearTimeout(logoutTimer);
                } else {
                    localStorage.clear();
                    setLoginStatus(false);
                }
            } finally {
                // Set loading to false regardless of the outcome
                setLoading(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [auth]);

    useState(()=>{
        if (location?.pathname && location?.pathname !== '/sign-in'){
            setReferrer(location.pathname)
        }
    },[location])

    useEffect(() => {
        if (loginStatus) {
            navigate(referrer);
        }
    }, [loginStatus]);

    return (
        <AuthContext.Provider value={{loginStatus, loading}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
