import React, {useState} from 'react';
import {signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import {auth} from "./Config";
import '../../assets/style/Login.css';
import logo from '../../assets/images/login-logo.png';
import '../../assets/style/index.css';
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    async function fetchAndStoreTimezone() {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get-timezone');
            if (!response.ok) {
                throw new Error('Failed to fetch timezone');
            }
            const data = await response.json();
            const timezone = data.timezone;

            localStorage.setItem('timeZone', timezone);
        } catch (error) {
            console.error('Error fetching timezone:', error);
            const defaultTimezone = 'America/New_York'; // Default to Eastern Time
            localStorage.setItem('timeZone', defaultTimezone);
        }
    }

    const handleResetPassword = async () => {
        if (!email) {
            alert('Please enter your email address.');
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            alert('Password reset email sent! Check your inbox.');
        } catch (error) {
            console.error('Error sending password reset email:', error);
            alert('Failed to send password reset email. Please try again.');
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        if (!captchaValue) {
            alert('Please verify you are not a robot.');
            return;
        }

        // Send the email, password, and captcha token to your server
        fetch(process.env.REACT_APP_BACKEND_URL + '/api/verifyRecaptcha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                password,
                recaptchaToken: captchaValue,
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Handle successful login here
                    signInWithEmailAndPassword(auth, email, password)
                        .then(async (userCredential) => {
                            const user = userCredential.user;

                            // Set email
                            localStorage.setItem('userEmail', email);

                            // Set timezone
                            fetchAndStoreTimezone();
                        })
                        .catch((err) => {
                            console.error(err.code, err.message);
                            window.location.reload();
                            alert("Invalid credentials. Try again.");
                            setCaptchaValue(null);
                        });
                } else {
                    // Handle failed reCAPTCHA verification here
                    alert('Failed reCAPTCHA verification. Please try again.');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    };

    return (
        <div className="login-container" style={{display: 'flex', flexDirection: 'column'}}>
            <img src={logo} alt="Logo" className="login-logo" style={{height: '70px', width: '400px', zIndex: 1, margin: '65px'}} />

            <form onSubmit={handleLogin} className="log-in-form">

                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    className="input-field"
                    style={{padding: '20px', margin: '15px', marginTop: "40px"}}
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="input-field"
                    style={{padding: '20px', margin: '15px'}}
                />
                <p onClick={handleResetPassword} style={{color: '#61C5CA', textDecoration: 'underline', cursor: 'pointer', marginTop: '-5px', marginLeft: '18px'}}>
                    Forgot Password?
                </p>

                <ReCAPTCHA
                    sitekey="6Lf_9VMpAAAAABYidtnmq78TWw_YTpb2sQ5IP0v0"
                    onChange={handleCaptchaChange}
                    style={{padding: '10px', margin: '5px'}}
                />
                <button type="submit" className="log-in-button">Login</button>
            </form>
        </div>

    );
};

export default Login;
