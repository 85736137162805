export const createAbortController = (abortControllerRef) => {
    abortControllerRef.current = new AbortController();
    return abortControllerRef.current;
};

export const getAbortController = (abortControllerRef) => {
    return abortControllerRef.current;
};

export const getAbortSignal = (abortControllerRef) => {
    if (abortControllerRef?.current){
        return abortControllerRef.current.signal;
    }
    return undefined
};

export const closeAbortController = (abortControllerRef) => {
    if (abortControllerRef.current) {
        abortControllerRef.current.abort();
    }
};