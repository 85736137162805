import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import InvoicePDF from '../utils/invoices/InvoicePDF'; // Import the InvoicePDF component
import PlatformInvoicePDF from '../utils/invoices/PlatformInvoicePDF'; // Import the InvoicePDF component

import ContentEditable from 'react-contenteditable';
import { formatDateText, newDate, formatDateLocationToUTCString } from '../utils/time_zones/TimeZones';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


const Invoices = ({ call }) => {
    const contentRef = useRef();
    const [locations, setLocations] = useState([]);
    const [bundlesData, setBundlesData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('VIO Annapolis');
    const [selectedLocationInvoice, setSelectedLocationInvoice] = useState('');
    const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [invoices, setInvoices] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentInvoice, setCurrentInvoice] = useState('');
    const [isEmailSending, setIsEmailSending] = useState(false);
    const [billingAddress, setBillingAddress] = useState({});
    const [isBillingAddressLoading, setIsBillingAddressLoading] = useState(false);
    const [invoicePdfData, setInvoicePdfData] = useState([]);
    const [pricingStyle, setPricingStyle] = useState('');

    const [isPlatformInvoiceModalOpen, setIsPlatformInvoiceModalOpen] = useState(false);
    const [platformInvoiceDetails, setPlatformInvoiceDetails] = useState({
        monthFee: 0,
        onboardingFee: 0,
        locationName: selectedLocation,
        billingAddress: billingAddress,
        ach_link: '',
        invoice_date: '',
        due_date: '',
        pdfBlob: null,
        invoice_id: '',
        medvelle_network_discounts: 0
    });

    const fetchfee = async (selectedLocation) => {
        // Replace with your actual fetching logic
        const fee = await fetchMonthlyFee(selectedLocation);

        setPlatformInvoiceDetails(details => ({ ...details, monthFee: fee.monthly_fee }));
        return fee
    };

    const fetchMaxInvoiceId = async () => {
        try {
            const response = await axios.get(`${call}/max-invoice-id`);
            if (response.status === 200) {
                const { maxInvoiceId } = response.data;
                return maxInvoiceId;
            } else {
                throw new Error('Failed to fetch the max invoice ID');
            }
        } catch (error) {
            console.error('Error fetching max invoice ID:', error);
            return null;
        }
    };    
    

    useEffect(() => {

        fetch(`${call}/get-custom-pricing`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: selectedLocation }),
          })
            .then(response => response.json())
            .then(data => {
              setPricingStyle(data.pricingStyle);
            })
            .catch(error => console.error('Error fetching processed orders:', error));
        
        fetchfee(selectedLocation);
    }, [selectedLocation]);


    const [isEOMEmailModalOpen, setIsEOMEmailModalOpen] = useState(false);
    const [eomEmailDetails, setEomEmailDetails] = useState({
        to: '',
        cc: '',
        subject: '',
        body: `
    <html>
    <body>
        <p>Dear,</p>

        <p>Attached, please find your End-of-Month Report, along with the corresponding COGS documentation.</p>

        <p>We appreciate your continued trust in Medvelle and value our partnership. Should you have any questions or need further information, please do not hesitate to reply to this email – we are here to assist you.</p>
             
        <p>Best regards,<br/>
        Medvelle Accounting Team</p>

        <p style="font-size: 12px; font-style: italic; margin-top: 50px;">
            NOTICE: This email and any attachments may contain confidential and proprietary information of Medvelle and/or its affiliates and are for the sole use of the intended recipient for the stated purpose. Any improper use or distribution is prohibited. If you are not the intended recipient, please notify the sender; do not review, copy or distribute; and promptly delete or destroy all transmitted information.
        </p>
    </body>
    </html>
    `,
        attachEOMReport: true,
        attachCOGSReport: false // Add this line for COGS report

    });

    // Function to handle EOM email modal opening
    const handleOpenEOMEmailModal = async () => {

        if (selectedLocation === ''){
            alert('Please select a client location')
            return
        }
        
        
        const { emails, user } = await fetchUserEmails(selectedLocation);

        setEomEmailDetails({
            ...eomEmailDetails,
            subject: `EOM Report for ${selectedLocation}`,
            to: emails,
            body: `
    <html>
    <body>
        <p>Dear ${user},</p>

        <p>Attached, please find your End-of-Month Report, along with the corresponding COGS documentation.</p>

        <p>We appreciate your continued trust in Medvelle and value our partnership. Should you have any questions or need further information, please do not hesitate to reply to this email – we are here to assist you.</p>
             
        <p>Best regards,<br/>
        Medvelle Accounting Team</p>

        <p style="font-size: 12px; font-style: italic; margin-top: 50px;">
            NOTICE: This email and any attachments may contain confidential and proprietary information of Medvelle and/or its affiliates and are for the sole use of the intended recipient for the stated purpose. Any improper use or distribution is prohibited. If you are not the intended recipient, please notify the sender; do not review, copy or distribute; and promptly delete or destroy all transmitted information.
        </p>
    </body>
    </html>
    `,
        });
        setIsEOMEmailModalOpen(true);
    };

    // Function to handle EOM email modal closing
    const handleCloseEOMEmailModal = () => {
        setIsEOMEmailModalOpen(false);
        setEomEmailDetails({
            to: '',
            cc: '',
            subject: '',
            body: `
    <html>
    <body>
        <p>Dear,</p>
 
        <p>Attached, please find your End-of-Month Report, along with the corresponding COGS documentation.</p>

        <p>We appreciate your continued trust in Medvelle and value our partnership. Should you have any questions or need further information, please do not hesitate to reply to this email – we are here to assist you.</p>
             
        <p>Best regards,<br/>
        Medvelle Accounting Team</p>

        <p style="font-size: 12px; font-style: italic; margin-top: 50px;">
            NOTICE: This email and any attachments may contain confidential and proprietary information of Medvelle and/or its affiliates and are for the sole use of the intended recipient for the stated purpose. Any improper use or distribution is prohibited. If you are not the intended recipient, please notify the sender; do not review, copy or distribute; and promptly delete or destroy all transmitted information.
        </p>
    </body>
    </html>
    `,
            attachEOMReport: true
        });
    };
// Function to generate EOM report Excel
const generateEOMReportExcel = async (locationName) => {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    console.log('Month: ', month);

    const response = await axios.get(`${call}/download-eom-report?locationName=${locationName}&UserName=${username}`, {
        params: {
            month,
            year,
            pricingStyle
        },
        responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    return blob;
};

const getPreviousMonthDates = async (locationName) => {

    const now = new Date();

    const startDateUsage = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const endDateUsage = new Date(now.getFullYear(), now.getMonth(), 1);
    endDateUsage.setDate(endDateUsage.getDate() - 1); // Last day of the month

    const startUsageDateStr = await formatDateLocationToUTCString(startDateUsage.toISOString().split('T')[0] + ' 00:00:00', locationName);
    const endUsageDateStr = await formatDateLocationToUTCString(endDateUsage.toISOString().split('T')[0] + ' 23:59:59', locationName);

    return { startDate: startUsageDateStr, endDate: endUsageDateStr, month: now.getMonth() };
};


    const generateAccrualCOGSReport = async (locationName) => {
        const { startDate, endDate, month } = await getPreviousMonthDates(locationName);

        const response = await axios.get(`${call}/download-accrual-cogs?locationName=${locationName}&UserName=${username}`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                pricingStyle: pricingStyle,
                isHistorical: 1,
                month: month
          },
              responseType: 'blob',
          });

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        return blob;
    };

    const generateProductUsageReport = async (locationName) => {
        const { startDate, endDate, month } = await getPreviousMonthDates(locationName);

        const response = await axios.get(`${call}/download-product-usage-report?locationName=${locationName}&UserName=${username}`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                pricingStyle: pricingStyle,
                isHistorical: 1,
                month: month
          },
              responseType: 'blob',
          });

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        return blob;
    };


    const handleSendEOMEmail = async (e) => {

       
        e.preventDefault();
        setIsEmailSending(true);
    
        // Generate Excel EOM report if selected
        let eomBlob, cogsBlob, puBlob;
        if (eomEmailDetails.attachEOMReport) {
            eomBlob = await generateEOMReportExcel(selectedLocation);
        }
        if (eomEmailDetails.attachCOGSReport) {
            cogsBlob = await generateAccrualCOGSReport(selectedLocation);
        }

        if (eomEmailDetails.attachProductUsageReport) {
            puBlob = await generateProductUsageReport(selectedLocation);
        }
    
        // Create form data
        const formData = new FormData();
        formData.append('to', eomEmailDetails.to);
        formData.append('cc', eomEmailDetails.cc);
        formData.append('subject', eomEmailDetails.subject);
        formData.append('body', eomEmailDetails.body);
        if (eomBlob) {
            formData.append('eomAttachment', eomBlob, `EOM_Report_${selectedLocation}.xlsx`);
        }
        if (cogsBlob) {
            formData.append('cogsAttachment', cogsBlob, `Accrual_COGS_Report_${selectedLocation}.xlsx`);
        }
        if (puBlob) {
            formData.append('puAttachment', puBlob, `Product_Usage_Report${selectedLocation}.xlsx`);
        }
    
        try {
            const response = await axios.post(`${call}/send-eom-email`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 200) {
                toast.success('Email sent successfully!');
            } else {
                toast.error('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email');
        }
    
        setIsEmailSending(false);
        setIsEOMEmailModalOpen(false);
    };
    


    const getTableOrder = () => {
        return `
        <div style="border: 1px solid black; width: 600px; box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1); margin-bottom: 20px;">
            <table style="width: 100%; border-collapse: collapse;">
                <thead style="background-color: #87D3D7;">
                    <tr style="font-size: 16px; font-weight: bold;">
                        <th colspan="4" style="padding: 10px; text-align: left; border-bottom: 2px solid black;">Payment Information</th>
                    </tr>
                    <tr style="font-size: 15px;">
                        <th style="background-color: #ebf4f5; font-weight 'bold'; border-bottom: 1px solid black; padding: 10px; text-align: left;">Bank Name</th>
                        <th style="background-color: #ebf4f5; font-weight 'bold'; border-bottom: 1px solid black; padding: 10px; text-align: left;">Account Name</th>
                        <th style="background-color: #ebf4f5; font-weight 'bold'; border-bottom: 1px solid black; padding: 10px; text-align: left;">Account Number</th>
                        <th style="background-color: #ebf4f5; font-weight 'bold'; border-bottom: 1px solid black; padding: 10px; text-align: left;">Routing Number</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="font-size: 13px; background-color: #ffffff;">
                        <td style="padding: 10px; text-align: left;">Bank of America</td>
                        <td style="padding: 10px; text-align: left;">Medvelle LLC</td>
                        <td style="padding: 10px; text-align: left;">483102561682</td>
                        <td style="padding: 10px; text-align: left;">021000322</td>
                    </tr>
                </tbody>
            </table>
        </div>
        `;
    };
    
    const fetchinvoicedata = async (invoice) => {
        try {
            const response = await axios.put(`${call}/get-invoice-pdf`, {
                invoice_id: invoice.invoice_id,
                username: username, // Assuming you have the username from context or state
                location_name: invoice.recipient,
                pricingStyle: pricingStyle
            });
    
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Failed to fetch invoice data');
            }
        } catch (error) {
            console.error('Error fetching invoice data:', error);
            return [];
        }
    };

    const fetchBillingAddress = async (invoice) => {
        try {
          const response = await axios.post(`${call}/get-billing-address`, {
            location_name: invoice.recipient,
          });
          const data = response.data;
          return data.length > 0 ? data[0] : {};
        } catch (error) {
          console.error('Error fetching billing address:', error);
          return {};
        }
      };


    const handleGenerateInvoice = async (invoice) => {
        setIsBillingAddressLoading(true);
    
        const billingAddress = await fetchBillingAddress(invoice);
        const invoicepdfdata = await fetchinvoicedata(invoice);
    
        setInvoicePdfData(invoicepdfdata);
        setBillingAddress(billingAddress);
        setCurrentInvoice(invoice.invoice_id);
        setIsBillingAddressLoading(false);
    };
    



    
    const [editedInvoice, setEditedInvoice] = useState({
        invoice_id: null,
        invoice_date: '',
        terms: '',
        payment_date: '',
        recipient: '',
        payment_amount: '',
        medvelle_network_discounts: ''
    });
    const [modalUploadOpen, setModalUploadOpen] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [invoiceToDelete, setInvoiceToDelete] = useState(null);
    const [username, setUsername] = useState('');
    const [ordersPerBundle, setOrdersPerBundle] = useState([]);
    const [expandedBundles, setExpandedBundles] = useState([]);
    const [clientsInvoices, setClientsInvoices] = useState([]);
    const [file, setFile] = useState('')
    const [fileName, setFileName] = useState("")
    const [sortPaymentDateDirection, setSortPaymentDateDirection] = useState('asc');

    // Gets unique category of products
    const invoicesCategories = [
        'Open',
        'Sent',
        'Paid',
        'Overdue',
        'Dispute'
    ];

    // Add state variables for email modal, DONT CHANGE THE TEXT ALIGNMENT OF THE BODY BECAUSE THAT CHANGES THE EMAIL ALIGNMENT TOO
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [emailDetails, setEmailDetails] = useState({
        to: '',
        cc: '',
        subject: '',
        body: `
    <html>
    <body>
        <p>Dear ,</p>

        <p>Attached, please find the latest Medvelle Invoice, along with the corresponding supporting documentation.</p>

        ${getTableOrder()}

        <p>We appreciate your continued trust in Medvelle and value our partnership. Should you have any questions or need further information, please do not hesitate to reply to this email – we are here to assist you.</p>
             
        <p>Best regards,<br/>
        Medvelle Accounting Team</p>

        <p style="font-size: 12px; font-style: italic; margin-top: 50px;">
            NOTICE: This email and any attachments may contain confidential and proprietary information of Medvelle and/or its affiliates and are for the sole use of the intended recipient for the stated purpose. Any improper use or distribution is prohibited. If you are not the intended recipient, please notify the sender; do not review, copy or distribute; and promptly delete or destroy all transmitted information.
        </p>
    </body>
    </html>
    `,
        attachInvoicePDF: true,
        attachBackupSheet: true
    });

    const fetchUserEmails = async (location_name) => {
        try {
            const response = await axios.post(`${call}/get-user-email`, { location_name });
            if (response.status === 200) {
                const emails = response.data.email;
                const user = response.data.first_name;
                return { emails, user }; // Return as an object
            } else {
                return '';
            }
        } catch (error) {
            console.error('Error fetching user emails:', error);
            return '';
        }
    };

    // Function to handle email modal opening
    const handleOpenEmailModal = async (invoice) => {


        setCurrentInvoice(invoice)
        const invoiceNumber = (invoice.invoice_id);
        const newSubject = `${invoice.recipient} <> Medvelle Invoice #${invoiceNumber}`;
        
        const {emails, user} = await fetchUserEmails(invoice.recipient);


        setEmailDetails({
            ...emailDetails,
            subject: newSubject, 
            to: emails, 
            body: `
    <html>
    <body>
        <p>Dear ${user},</p>

        <p>Attached, please find the latest Medvelle Invoice, along with the corresponding supporting documentation.</p>

        ${getTableOrder()}

        <p>We appreciate your continued trust in Medvelle and value our partnership. Should you have any questions or need further information, please do not hesitate to reply to this email – we are here to assist you.</p>
             
        <p>Best regards,<br/>
        Medvelle Accounting Team</p>

        <p style="font-size: 12px; font-style: italic; margin-top: 50px;">
            NOTICE: This email and any attachments may contain confidential and proprietary information of Medvelle and/or its affiliates and are for the sole use of the intended recipient for the stated purpose. Any improper use or distribution is prohibited. If you are not the intended recipient, please notify the sender; do not review, copy or distribute; and promptly delete or destroy all transmitted information.
        </p>
    </body>
    </html>
    `,
        });   
        setIsEmailModalOpen(true);
    };

    // Function to handle email modal closing
    const handleCloseEmailModal = () => {
        setIsEmailModalOpen(false);
        setEmailDetails(
            {to: '',
            cc: '',
            subject: '',
            body: `
    <html>
    <body>
        <p>Dear,</p>

        <p>Attached, please find the latest Medvelle Invoice, along with the corresponding supporting documentation.</p>

        ${getTableOrder()}

        <p>We appreciate your continued trust in Medvelle and value our partnership. Should you have any questions or need further information, please do not hesitate to reply to this email – we are here to assist you.</p>
             
        <p>Best regards,<br/>
        Medvelle Accounting Team</p>

        <p style="font-size: 12px; font-style: italic; margin-top: 50px;">
            NOTICE: This email and any attachments may contain confidential and proprietary information of Medvelle and/or its affiliates and are for the sole use of the intended recipient for the stated purpose. Any improper use or distribution is prohibited. If you are not the intended recipient, please notify the sender; do not review, copy or distribute; and promptly delete or destroy all transmitted information.
        </p>
    </body>
    </html>
    `,        
            attachInvoicePDF: true,
            attachBackupSheet: true}
        );
    };



    const generateInvoicePDF = async (invoice) => {
        const billingAddress = await fetchBillingAddress(invoice);
        const invoicepdfdata = await fetchinvoicedata(invoice);
    
        const blob = await pdf(
            <InvoicePDF 
                invoice={invoice} 
                bundles={bundlesData[invoice.invoice_id] || []} 
                ordersPerBundle={ordersPerBundle} 
                call={call} 
                locationName={invoice.recipient} 
                billingAddress={billingAddress}
                invoiceData={invoicepdfdata} 
                pricingStyle = {pricingStyle}
            />
        ).toBlob();
    
        return blob;
    };
    

      

    // Function to generate invoice Excel backup
    const generateInvoiceExcel = async (invoice) => {
        const response = await fetch(`${call}/download-invoice`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ invoice_id: invoice.invoice_id, username: username, location_name: invoice.recipient, pricingStyle: pricingStyle })
        });

        if (!response.ok) {
            throw new Error('Failed to download invoice');
        }

        const blob = await response.blob(); // Ensure response is converted to Blob
        return blob;
    };

    // Function to handle email sending
    const handleSendEmail = async (e) => {
        e.preventDefault();
        setIsEmailSending(true);
        
        await handleGenerateInvoice(currentInvoice);

           
        // Generate PDF and Excel backup using existing functions if selected
        let pdfBlob, excelBlob;
        if (emailDetails.attachInvoicePDF) {
            pdfBlob = await generateInvoicePDF(currentInvoice);
        }
        if (emailDetails.attachBackupSheet) {
            excelBlob = await generateInvoiceExcel(currentInvoice);
        }

        // Create form data
        const formData = new FormData();
        formData.append('to', emailDetails.to);
        formData.append('cc', emailDetails.cc);
        formData.append('subject', emailDetails.subject);
        formData.append('body', emailDetails.body);
        if (pdfBlob) {
            formData.append('pdfAttachment', pdfBlob, `invoice_${(currentInvoice.invoice_id)}_${currentInvoice.recipient}.pdf`);
        }
        if (excelBlob) {
            formData.append('excelAttachment', excelBlob, `invoice_${(currentInvoice.invoice_id)}_${currentInvoice.recipient}.xlsx`);
        }

        try {
            const response = await axios.post(`${call}/send-invoice-email`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 200) {
                toast.success('Email sent successfully!');
            } else {
                toast.error('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email');
        }

        setIsEmailSending(false);
        setIsEmailModalOpen(false);
    };


    useEffect(() => {
        fetchLocations();
        fetchInvoices();
        fetchBundles();
        fetchUsername();
        fetchOrdersPerBundle();
        fetchFiles();

        contentRef.current.style.marginLeft = '250px';
        contentRef.current.style.marginRight = '50px';
    }, [selectedLocation]);

    const handleBundleClick = (bundleId) => {
        if (expandedBundles.includes(bundleId)) {
          setExpandedBundles(expandedBundles.filter(id => id !== bundleId));
        } else {
          // If the bundle is not expanded, add it to the list of expanded bundles
          setExpandedBundles([...expandedBundles, bundleId]);
        }
      };

    const fetchLocations = () => {
        fetch(`${call}/locations`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setLocations(data);
            })
            .catch(error => console.error('Error fetching locations data:', error));
    };

    const fetchInvoices = () => {
        fetch(`${call}/fetchInvoices`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setInvoices(data);
            })
            .catch(error => console.error('Error fetching invoices data:', error));
    };

    const fetchOrdersPerBundle = () => {
        fetch(`${call}/orders-per-bundle`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {

                // Convert the object into an array of bundles with IDs
                const bundlesArray = Object.entries(data);

                let filteredBundles;
                if (selectedLocation !== '') {
                    // Filter bundles based on all orders having location_name = selectedLocation
                    filteredBundles = bundlesArray.filter(([bundleId, orders]) =>
                        orders.every(order => order.location_name === selectedLocation)
                    );
                } else {
                    // If selectedLocation is empty, keep all bundles
                    filteredBundles = bundlesArray;
                }

                // Convert back to an object with filtered bundles
                const filteredData = Object.fromEntries(filteredBundles);

                setOrdersPerBundle(filteredData);

            })
            .catch(error => console.error('Error fetching orders per bundle:', error));
    };

    // Function to fetch the username
    const fetchUsername = () => {
        // Fetch username using the API and the email from local storage
        const userEmail = localStorage.getItem('userEmail');
    
        if (userEmail) {
        fetch(`${call}/get-name?user_email=${userEmail}`)
            .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then(data => {
            // Store the username in the state variable
            setUsername(data.name);
            })
            .catch(error => console.error('Error fetching username:', error));
        }
    };

    async function fetchBundles() {
        try {
          // Make a GET request to fetch all bundles for all invoice_ids
          const response = await fetch(`${call}/get-bundles-invoice`);
        
          if (response.status === 404){
            console.log("No bundles found.");
            return;
          }
          
          // Parse the JSON response
          const data = await response.json();
          
          // Return the data
          setBundlesData(data['bundlesByInvoice']);
        } catch (error) {
          // Handle any errors that occurred during the fetch
          console.error('Fetch error:', error);
        }
      }
      
    const handleCreateInvoice = () => {
        setIsCreateInvoiceModalOpen(true);
    };

    const handleSubmitInvoice = async (e) => {

        e.preventDefault();

        // Validate start date and end date
        if (!startDate || !endDate) {
            alert('Please enter both start and end dates.');
            return;
        }

        if (selectedLocationInvoice == ''){
            alert('Please select a location.');
            return;
        }
        // Call the API to create invoices
        await fetch(`${call}/create_invoices_end_month`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                start_date: startDate,
                end_date: endDate,
                location_name: selectedLocationInvoice
            }),
        })
            .then(async (response) => {
                if (response.status == 500) {
                    toast.error('Server error.');
                }
                else if (response.status == 404){
                    toast.error('No bundles found during this time period.');
                }
                else if (response.status == 200){
                    toast.success('Invoice created successfully.')
                }
                setIsCreateInvoiceModalOpen(false);
                await fetchInvoices();
                await fetchBundles();
                return response.json();
            })

    };

    const handleSubmitInvoiceInMonth = async (e) => {

        e.preventDefault();

        // Validate start date and end date
        if (!startDate || !endDate) {
            alert('Please enter both start and end dates.');
            return;
        }

        if (selectedLocationInvoice == ''){
            alert('Please select a location.');
            return;
        }
        // Call the API to create invoices
        await fetch(`${call}/create_invoices_in_month`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                start_date: startDate,
                end_date: endDate,
                location_name: selectedLocationInvoice
            }),
        })
            .then(async (response) => {
                if (response.status == 500) {
                    toast.error('Server error.');
                }
                else if (response.status == 404){
                    toast.error('No bundles found during this time period.');
                }
                else if (response.status == 505){
                    toast.error('Invoice date not in pay periods dates.');
                }
                else if (response.status == 200){
                    toast.success('Invoice created successfully.')
                }
                setIsCreateInvoiceModalOpen(false);
                await fetchInvoices();
                await fetchBundles();
                return response.json();
            })

    };

    const handleEditInvoice = (invoice) => {
        setEditedInvoice(invoice);
        setIsEditModalOpen(true);
        setCurrentInvoice('');
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        // Call the update-invoices API with editedInvoice data
        await fetch(`${call}/update-invoices`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(editedInvoice),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to update invoice');
            }
            return response.json();
        })
        .then(data => {
            // Handle success
            console.log('Invoice updated successfully');
            setIsEditModalOpen(false);
        })
        .catch(error => {
            // Handle error
            console.error('Error updating invoice:', error);
        });

        await fetchInvoices();
    };

    // Add state to track expanded invoices
    let [expandedInvoices, setExpandedInvoices] = useState([]);

    // Function to handle clicking on an invoice
    const handleInvoiceClick = async (invoice) => {
        try {
            // Toggle the expanded state of the clicked invoice
            const invoiceId = invoice.invoice_id;
            const isExpanded = expandedInvoices.includes(invoiceId);
            setExpandedInvoices(isExpanded ? expandedInvoices.filter(id => id !== invoiceId) : [...expandedInvoices, invoiceId]);
    
            // Close all bundles inside the clicked invoice if it is being closed
            if (isExpanded) {
                const bundlesToClose = []; // Store bundle IDs to close
                bundlesData[invoice.invoice_id].forEach(bundle => {
                    if (expandedBundles.includes(bundle.order_code)) {
                        bundlesToClose.push(bundle.order_code);
                    }
                });
                setExpandedBundles(expandedBundles.filter(id => !bundlesToClose.includes(id)));
            }
        } catch (error) {
            console.error('Error handling invoice click:', error);
        }
    };
    

    // Function to handle clicking on the delete button
    const handleDeleteClick = (invoice) => {
        setInvoiceToDelete(invoice);

        setShowDeleteConfirmation(true);
    };

    // Function to confirm deletion and call delete API
    const confirmDelete = () => {
        expandedInvoices = expandedInvoices.filter(id => id !== invoiceToDelete.invoice_id);

        fetch(`${call}/delete_invoice/${invoiceToDelete.invoice_id}`, {
            method: 'DELETE'
        })
        .then(response => {
            if (response.ok) {
                // Handle success, maybe update state or UI
                toast.success('Invoice deleted successfully!');
                console.log('Invoice deleted successfully!');
            } else {
                // Handle error
                toast.error('Invoice could not be deleted.');
                console.error('Error deleting invoice.');
            }
        })
        .catch(error => {
            console.error('Error deleting invoice:', error);
        })
        .finally(() => {
            // Reset state
            setShowDeleteConfirmation(false);
            setInvoiceToDelete(null);
            fetchInvoices();
            fetchBundles();
        });
    };

    const filterInvoices = selectedLocation
    ? invoices.filter(item => item.recipient === selectedLocation)
    : invoices;

    const invoiceDownload = async (invoice) => {
        
        fetch(`${call}/download-invoice`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ invoice_id: invoice.invoice_id, username: username, location_name: invoice.recipient , pricingStyle: pricingStyle})
        })
        .then(response => {
        if (!response.ok) {
            throw new Error('Failed to download invoice');
        }
        return response.blob(); // Assuming the response is a binary blob
        })
        .then(blob => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and set its attributes
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice_${(invoice.invoice_id)}_${invoice.recipient}.xlsx`);

        // Append the link to the body and click it programmatically
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL object
        window.URL.revokeObjectURL(url);
        })
        .catch(error => {
        console.error('Error downloading invoice:', error);
        // Handle error
        });

    };

    const groupByProductCategory = (invoiceId) => {
        const bundles = bundlesData[invoiceId] || [];
        const groupedData = {};
    
        bundles.forEach(bundle => {
            const orders = ordersPerBundle[bundle.order_code] || [];
    
            orders.forEach(order => {
                if (!groupedData[order.product_cat]) {
                    groupedData[order.product_cat] = {
                        total_price: 0,
                        orders: [],
                    };
                }
                groupedData[order.product_cat].total_price += order.order_quantity * order.unit_price;
                groupedData[order.product_cat].orders.push(order);
            });
        });
    
        return groupedData;
    };
    
    

    async function postFile({ file }) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("filename", "Clients-invoices/" + fileName);

        const requestOptions = {
          method: 'POST',
          body: formData
        };
      
        try {
          const response = await fetch(`${call}/upload-files`, requestOptions);
          if (!response.ok) {
            throw new Error('Failed to upload image');
          }
          
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error uploading image:', error);
          throw error; // Propagate the error further
        }
      }
      

    const submitUploadInvoice = async event => {
        event.preventDefault();
        try {
            const result = await postFile({ file: file });

            // Check if the result contains a message indicating success
            if (result.message === 'File uploaded successfully') {
                // Show success toast
                toast.success('File uploaded successfully');
            } else {
                // Show error toast if the message is not as expected
                toast.error('Failed to upload file');
            }

            setModalUploadOpen(false);
            setFile('');
            fetchFiles();
        } catch (error) {
            console.error('Error uploading file:', error);
            // Show error toast for any other errors
            toast.error('Failed to upload file');
        }
    };

    const fileSelected = event => {
        let file = event.target.files[0]
        setFile(file)
    }

    const fetchFile = async (filename) => {
        try {
          const key = "Clients-invoices/" + filename; // Replace 'your-file-key' with the actual key
          const response = await fetch(`${call}/get-file`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ key }), // Include the key in the request body as JSON
          });
      
          if (!response.ok) {
            toast.error("Failed to fetch file");
            throw new Error('Failed to fetch file');
          }

          toast.success("File fetched successfully");
      
          // Trigger download when fetch is successful
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${key}.pdf`; // Set the download filename
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
      
          console.log('File downloaded successfully');
        } catch (error) {
          console.error('Error downloading file:', error);
        }
    };

    const fetchFiles = async () => {
        try {
            
            const response = await fetch(`${call}/get-aws-files`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ folder: "Clients-invoices/" }), // Include the key in the request body as JSON
            });
        
            const data = await response.json();

            setClientsInvoices(data.files);
        } catch (error) {
            console.error('Error fetching file names:', error);
        }
    };

    const checkFileExists = (fileName) => {
        return clientsInvoices.includes(fileName);
    };

    const sortPaymentsByDate = (a, b) => {
        const dateA = new Date(a.payment_date);
        const dateB = new Date(b.payment_date);
        return sortPaymentDateDirection === 'asc' ? dateA - dateB : dateB - dateA;
    };

    const handleEoMReport = async (location_name) => {

        const currentDate = new Date();
        let month = currentDate.getMonth(); // getMonth() is zero-based, so this gets the previous month
        let year = currentDate.getFullYear();
    
        console.log('Month: ', month);

        const response = await axios.get(`${call}/download-eom-report?locationName=${location_name}&UserName=${username}`, {
            params: {
              month,
              year,
              pricingStyle
          },
              responseType: 'blob',
          });
        
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `EOM_Report_${location_name}.xlsx`);
          document.body.appendChild(link);
          link.click();

    }

    const getCurrentMonthInvoiceDates = () => {
        const now = new Date();
        const invoiceDate = new Date(now.getFullYear(), now.getMonth(), 12);
        const dueDate = new Date(invoiceDate);
        dueDate.setDate(invoiceDate.getDate() + 15);
    
        const formatDate = (date) => {
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        };
    
        return {
            invoiceDate: formatDate(invoiceDate),
            dueDate: formatDate(dueDate)
        };
    };

    const generatePlatformInvoice = async () => {
        const { invoice_id, invoice_date, due_date, monthFee, onboardingFee, locationName, ach_link, medvelle_network_discounts } = platformInvoiceDetails;

        const total_price = parseFloat(monthFee) + parseFloat(onboardingFee);

        try {
            const response = await axios.post(`${call}/include-platform-invoice`, {
                invoice_id,
                invoice_date: newDate(invoice_date),
                terms: 7,
                payment_date: newDate(due_date),
                total_price: total_price,
                recipient: locationName,
                payment_amount: total_price,
                cc_link: '',
                ach_link,
                invoice_status: 'Open',
                medvelle_network_discounts
            });
    
            if (response.status === 200) {
            } 
        } catch (error) {
            console.error('Error including platform invoice:', error);
        }
    };
    

    const handleGeneratePlatformInvoice = async () => {
        const maxInvoiceId = await fetchMaxInvoiceId();
        const nextInvoiceId = maxInvoiceId ? maxInvoiceId + 3 : 1;
        const { invoiceDate, dueDate } = getCurrentMonthInvoiceDates();
    
        setPlatformInvoiceDetails({
            ...platformInvoiceDetails,
            invoice_id: nextInvoiceId,
            invoice_date: invoiceDate,
            due_date: dueDate
        });

        const { emails, user } = await fetchUserEmails(selectedLocation);
        const newSubject = `${selectedLocation} <> Medvelle Invoice #${nextInvoiceId}`;


        setPlatformEmailDetails({
            to: emails,
            cc: '',
            subject: newSubject,
            body: `
            <html>
            <body>
                <p>Dear ${user},</p>
        
                <p>Attached, please find the latest Medvelle Invoice, along with the corresponding supporting documentation.</p>
        
                ${getTableOrder()}
        
                <p>We appreciate your continued trust in Medvelle and value our partnership. Should you have any questions or need further information, please do not hesitate to reply to this email – we are here to assist you.</p>
                     
                <p>Best regards,<br/>
                Medvelle Accounting Team</p>
        
                <p style="font-size: 12px; font-style: italic; margin-top: 50px;">
                    NOTICE: This email and any attachments may contain confidential and proprietary information of Medvelle and/or its affiliates and are for the sole use of the intended recipient for the stated purpose. Any improper use or distribution is prohibited. If you are not the intended recipient, please notify the sender; do not review, copy or distribute; and promptly delete or destroy all transmitted information.
                </p>
            </body>
            </html>
        `,
            attachPlatformInvoicePDF: true
        });

        setIsPlatformInvoiceModalOpen(true);

    };
    

    const fetchPlatformBillingAddress = async (locationName) => {
        try {
          const response = await axios.post(`${call}/get-billing-address`, {
            location_name: locationName,
          });
          const data = response.data;
          return data.length > 0 ? data[0] : {};
        } catch (error) {
          console.error('Error fetching billing address:', error);
          return {};
        }
      };
    
    const fetchMonthlyFee = async (locationName) => {
    try {
        const response = await axios.post(`${call}/get-monthly-fee`, {
            location_name: locationName,
        });
        const data = response.data;
        return data.length > 0 ? data[0] : {};
    } catch (error) {
        console.error('Error fetching billing address:', error);
        return {};
    }
    };

    const generatePlatformInvoicePDF = async () => {
        const { monthFee, onboardingFee, locationName, invoice_date, due_date, ach_link, invoice_id } = platformInvoiceDetails;
        
    
        try {
            const blob = await pdf(
                <PlatformInvoicePDF
                    monthFee = {monthFee}
                    onboardingFee={onboardingFee}
                    locationName={locationName}
                    billingAddress = {await fetchPlatformBillingAddress(locationName)}
                    invoice_date = {invoice_date}
                    due_date = {due_date}
                    ach_link = {ach_link}
                    invoice_id = {invoice_id}
                />
            ).toBlob();
            
            setPlatformInvoiceDetails({
                ...platformInvoiceDetails,
                pdfBlob: blob
            });
        } catch (error) {
            console.error('Error generating Platform Invoice PDF:', error);
            toast.error('Failed to generate PDF');
        }


    };


    const handleClosePlatformInvoiceModal = () => {
        setIsPlatformInvoiceModalOpen(false);
        setPlatformInvoiceDetails({
            onboardingFee: '',
            locationName: selectedLocation,
            pdfBlob: null
        });
    };

    const [platformEmailDetails, setPlatformEmailDetails] = useState({
        to: '',
        cc: '',
        subject: ``,
        body: `
        <html>
        <body>
            <p>Dear ,</p>
    
            <p>Attached, please find the latest Medvelle Invoice, along with the corresponding supporting documentation.</p>
    
            ${getTableOrder()}
    
            <p>We appreciate your continued trust in Medvelle and value our partnership. Should you have any questions or need further information, please do not hesitate to reply to this email – we are here to assist you.</p>
                 
            <p>Best regards,<br/>
            Medvelle Accounting Team</p>
    
            <p style="font-size: 12px; font-style: italic; margin-top: 50px;">
                NOTICE: This email and any attachments may contain confidential and proprietary information of Medvelle and/or its affiliates and are for the sole use of the intended recipient for the stated purpose. Any improper use or distribution is prohibited. If you are not the intended recipient, please notify the sender; do not review, copy or distribute; and promptly delete or destroy all transmitted information.
            </p>
        </body>
        </html>
    `,
        attachPlatformInvoicePDF: true
    });
    
    // Function to handle sending the platform invoice email
    const handleSendPlatformInvoiceEmail = async (e) => {
        e.preventDefault();
        setIsEmailSending(true);
        const id = await fetchMaxInvoiceId();
        // Create form data
        const formData = new FormData();
        formData.append('to', platformEmailDetails.to);
        formData.append('cc', platformEmailDetails.cc);
        formData.append('subject', platformEmailDetails.subject);
        formData.append('body', platformEmailDetails.body);
        if (platformEmailDetails.attachPlatformInvoicePDF && platformInvoiceDetails.pdfBlob) {
            formData.append('platformInvoicePDF', platformInvoiceDetails.pdfBlob, `Invoice_${id+3}.pdf`);
        }
    
        try {
            const response = await axios.post(`${call}/send-platform-invoice-email`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 200) {
                toast.success('Email sent successfully!');
            } else {
                toast.error('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email');
        }

        await generatePlatformInvoice();

        setIsEmailSending(false);
        setIsPlatformInvoiceModalOpen(false);
    };
    

    
    const sortedFilteredInvoices = filterInvoices.sort(sortPaymentsByDate)

    
    return (
        <div ref={contentRef} style={{ textAlign: 'left'}}>
            <ToastContainer />
            <h1 style={{ marginTop: '27px',  fontSize: '40px', fontWeight: 'bold', color: '#87D3D7'}}>Invoices</h1>

            <select
                id="location"
                value={selectedLocation}
                onChange={(e) => {setSelectedLocation(e.target.value); setExpandedInvoices([]); setPlatformInvoiceDetails({ ...platformInvoiceDetails, locationName: e.target.value }) }}
                style={{ marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
                <option value="">All Locations</option>
                {locations.sort().map((item, index) => (
                    <option key={index} value={item.location_name}>{item.location_name}</option>
                ))}
            </select>
            <select
                value={sortPaymentDateDirection}
                onChange={(e) => setSortPaymentDateDirection(e.target.value)}
                style={{ padding: '10px', fontStyle: 'italic', width: '150px' }}
            >
                <option value="asc">Date: Ascending</option>
                <option value="desc">Date: Descending</option>
            </select>

            <br/>
            <br/>

            <h2>Invoice</h2>

            <button onClick={handleCreateInvoice}
                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
            >
                Create Invoice
            </button>

            <h2>Reports</h2>
            <button onClick={() => handleEoMReport(selectedLocation)} 
                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
            >
                EOM Report
            </button>

            <button onClick={handleOpenEOMEmailModal}
                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
            >
                Send EOM Report
            </button>

            <h2>Platform Invoice</h2>

            <button onClick={handleGeneratePlatformInvoice}
                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
            >
                Generate Platform Invoice
            </button>


            <br/>
            <br/>

            <div style={{ border: '2px solid #87D3D7', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%', height: '610px', overflow: 'auto' }}>
                <table style={{  
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0, 
                    position: 'relative'
                }}>
                    <thead style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                    }}>
                        <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}></th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center' }}>Invoice ID</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Invoice Date</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Terms</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Payment Date</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Recipient</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Total Products</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Total Shipping</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Total Taxes</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Total Discount</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Medvelle Network Discounts</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Total Price</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Payment Amount</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>CC link</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>ACH link</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Status</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Edit</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Delete</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Backup</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Upload</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Download</th>
                            <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Send Email</th>

                        </tr>
                    </thead>
                    <tbody>
                        { sortedFilteredInvoices.map((invoice, index) => (
                            <React.Fragment key={index}>
                                <tr style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td 
                                        onClick={() => handleInvoiceClick(invoice)} 
                                        style={{ textAlign: 'center', cursor: 'pointer' }}
                                    >
                                        <span>
                                            {expandedInvoices.includes(invoice.invoice_id) ? 
                                                <FontAwesomeIcon icon={faAngleUp} /> :
                                                <FontAwesomeIcon icon={faAngleDown} />
                                            }
                                        </span>
                                    </td>
                                    <td onClick={() => handleInvoiceClick(invoice)} style={{ padding: '10px', textAlign: 'center' }}>{invoice.invoice_id}</td>
                                    <td style={{ padding: '10px', cursor: 'pointer' }}>{formatDateText(invoice.invoice_date)}</td>
                                    <td style={{ padding: '10px' }}>{invoice.terms}</td>
                                    <td style={{ padding: '10px' }}>{formatDateText(invoice.payment_date)}</td>
                                    <td style={{ padding: '10px' }}>{invoice.recipient}</td>
                                    <td style={{ padding: '10px' }}>$ {invoice.total_price_orders.toLocaleString()}</td>
                                    <td style={{ padding: '10px' }}>$ {invoice.total_shipping.toLocaleString()}</td>
                                    <td style={{ padding: '10px' }}>$ {invoice.total_taxes.toLocaleString()}</td>
                                    <td style={{ padding: '10px' }}>$ {invoice.total_discount.toLocaleString()}</td>
                                    <td style={{ padding: '10px' }}>
                                        $ {invoice.medvelle_network_discounts ? invoice.medvelle_network_discounts.toLocaleString() : '0'}
                                    </td>
                                    <td style={{ padding: '10px' }}>$ {(invoice.total_price_orders + invoice.total_taxes + invoice.total_shipping -invoice.total_discount -invoice.medvelle_network_discounts).toLocaleString()}</td>
                                    <td style={{ padding: '10px' }}>$ {invoice.payment_amount.toLocaleString()}</td>
                                    <td style={{ padding: '10px' }}>{invoice.cc_link ? <a href = {invoice.cc_link} >Click</a> : "None"}</td>
                                    <td style={{ padding: '10px' }}>{invoice.ach_link ? <a href = {invoice.ach_link} >Click</a> : "None"}</td>
                                    <td style={{ padding: '10px' }}>{invoice.invoice_status}</td>
                                    <td style={{ padding: '10px' }}>
                                        <button onClick={() => handleEditInvoice(invoice)}
                                            style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                        >
                                            Edit
                                        </button>
                                    </td>
                                    <td style={{ padding: '10px' }}>
                                        <button 
                                            onClick={() => handleDeleteClick(invoice)}
                                            style={{ 
                                                backgroundColor: '#87D3D7', 
                                                color: '#fff', 
                                                border: 'none', 
                                                padding: '8px 12px', 
                                                cursor: 'pointer', 
                                                borderRadius: '8px',
                                                // Disable button if invoice status is not 'Open'
                                                pointerEvents: invoice.invoice_status !== 'Open' ? 'none' : 'auto',
                                                opacity: invoice.invoice_status !== 'Open' ? 0.5 : 1 // Optional: Dim the button when disabled
                                            }} 
                                            disabled={invoice.invoice_status !== 'Open'} // Optional: Alternative approach for disabling the button
                                        >
                                            Delete
                                        </button>

                                    </td>
                                    <td style={{ padding: '10px' }}>
                                        <button onClick={() => invoiceDownload(invoice)}
                                            style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                        >
                                            Backup
                                        </button>
                                    </td>
                                    <td style={{ padding: '10px' }}>
                                        <button onClick={() => {setFileName("invoice_id_" + invoice.invoice_id); setModalUploadOpen(true);}}
                                            style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                        >
                                            Upload
                                        </button>
                                    </td>
                                    <td style={{ padding: '10px' }}>
                                        {currentInvoice === invoice.invoice_id && !isBillingAddressLoading ? (
                                            <PDFDownloadLink
                                            document={
                                                <InvoicePDF 
                                                invoice={invoice} 
                                                bundles={bundlesData[invoice.invoice_id]} 
                                                ordersPerBundle={ordersPerBundle} 
                                                invoiceData = {invoicePdfData}
                                                call={call} 
                                                locationName={invoice.recipient} 
                                                billingAddress={billingAddress}
                                                pricingStyle = {pricingStyle}
                                                />
                                            }
                                            fileName={`invoice_${(invoice.invoice_id)}_${invoice.recipient}.pdf`}
                                            >
                                            Download PDF
                                            </PDFDownloadLink>
                                        ) : (
                                            <button
                                            onClick={() => handleGenerateInvoice(invoice)}
                                            style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                            disabled={isBillingAddressLoading}
                                            >
                                            {isBillingAddressLoading && currentInvoice === invoice.invoice_id ? 'Loading...' : 'Generate Invoice'}
                                            </button>
                                        )}
                                    </td>

                                    <td style={{ padding: '10px' }}>
                                        <button
                                            onClick={() => handleOpenEmailModal(invoice)}
                                            style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                        >
                                            Send Email
                                        </button>
                                    </td>


                                </tr>
                                {/* Render associated bundles if the invoice is expanded */}
                                {expandedInvoices.includes(invoice.invoice_id) && (
                                    <tr>
                                        <td colSpan="1">
                                        </td>
                                        <td colSpan="21">
                                        <div style={{ border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden' }}>
                                            <table style={{  
                                                
                                                width: '100%', 
                                                borderCollapse: 'separate', 
                                                borderSpacing: 0, 
                                                position: 'relative' // Ensure positioning context
                                            }}>
                                                <thead style={{
                                                position: 'sticky', // Stick the header
                                                top: 0, // Stick to the top of the table
                                                }}>
                                                <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}></th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Bundle ID</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Shipping</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Taxes</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Discount</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Total Price</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Tracking Number</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Bundle Date</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Due Date</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {bundlesData[invoice.invoice_id] && bundlesData[invoice.invoice_id].map((bundle, bundleIndex) => (
                                                <>
                                                    <tr key={bundleIndex} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: bundleIndex % 2 === 0 ? '#ebf4f5' : '#ffffff'  }}>
                                                        <td 
                                                            onClick={() => handleBundleClick(bundle.order_code)} 
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <span>
                                                                {expandedBundles.includes(bundle.order_code) ? 
                                                                    <FontAwesomeIcon icon={faAngleUp} /> :
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                }
                                                            </span>
                                                        </td>
                                                        <td onClick={() => handleBundleClick(bundle.order_code)} style={{ padding: '10px' }}>{bundle.order_code}</td>
                                                        <td style={{ padding: '10px' }}>$ {bundle.shipping.toLocaleString()}</td>
                                                        <td style={{ padding: '10px' }}>$ {bundle.taxes.toLocaleString()}</td>
                                                        <td style={{ padding: '10px' }}>$ {bundle.discount.toLocaleString()}</td>
                                                        <td style={{ padding: '10px' }}>$ {bundle.total_price.toLocaleString()}</td>
                                                        <td style={{ padding: '10px' }}>{bundle.tracking_number}</td>
                                                        <td style={{ padding: '10px' }}>{formatDateText(bundle.bundle_date)}</td>
                                                        <td style={{ padding: '10px' }}>{formatDateText(bundle.due_date)}</td>
                                                    </tr>
                                                    {expandedBundles.includes(bundle.order_code) &&  
                                                    <tr>
                                                    {/* Render order details */}
                                                    <td colSpan="1"></td>
                                                    <td colSpan="7">
                                                        <div style={{ border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden' }}>
                                                        <table style={{  
                                                            width: '100%', 
                                                            borderCollapse: 'separate', 
                                                            borderSpacing: 0, 
                                                            position: 'relative' // Ensure positioning context
                                                        }}>
                                                            <thead style={{
                                                            position: 'sticky', // Stick the header
                                                            top: 0, // Stick to the top of the table
                                                            }}>
                                                            <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Date</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Location Name</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Name</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Description</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Manufacturer Name</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Unit Price</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Quantity</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Price</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Internal Description</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Client Comment</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Comment</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Status</th>
                                                                <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Urgency</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {ordersPerBundle[bundle.order_code] && ordersPerBundle[bundle.order_code].map((order, orderIndex) => (
                                                            <tr key={orderIndex} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: orderIndex % 2 === 0 ? '#ebf4f5' : '#ffffff'  }}>
                                                                <td style={{ padding: '10px' }}>{formatDateText(order.order_date)}</td>
                                                                <td style={{ padding: '10px' }}>{order.location_name}</td>
                                                                <td style={{ padding: '10px' }}>{order.product_name}</td>
                                                                <td style={{ padding: '10px' }}>{order.product_description}</td>
                                                                <td style={{ padding: '10px' }}>{order.manufacturer_name}</td>
                                                                <td style={{ padding: '10px' }}>${order.unit_price.toLocaleString()}</td>
                                                                <td style={{ padding: '10px' }}>{order.order_quantity}</td>
                                                                <td style={{ padding: '10px' }}>${(order.order_quantity * order.unit_price).toLocaleString()}</td>
                                                                <td style={{ padding: '10px' }}>{order.internal_description}</td>
                                                                <td style={{ padding: '10px' }}>{order.client_comment}</td>
                                                                <td style={{ padding: '10px' }}>{order.order_comment}</td>
                                                                <td style={{ padding: '10px' }}>{order.status_cat}</td>
                                                                <td style={{ padding: '10px' }}>{order.order_urgency}</td>
                                                            </tr>))}
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </td>
                                                    </tr>
                                                    }
                                                </>
                                                ))}
                                                </tbody>
                                            </table>
                                            </div>
                                        </td>
                                    </tr>
                                   
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>

            <br/>
            <br/>
            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={isCreateInvoiceModalOpen}
                onRequestClose={() => setIsCreateInvoiceModalOpen(false)}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 2
                    },
                    content: {
                        width: '20%',
                        height: '70%',
                        textAlign: 'center'
                    }
                }}
            >
                <h3>Create Invoice In Month</h3>
                <div>
                    <form onSubmit={handleSubmitInvoiceInMonth}>
                        <div className="input-group">
                            <label>Start Date:</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label>End Date:</label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label>Select Location:</label>
                            <select
                                value={selectedLocationInvoice}
                                onChange={(e) => setSelectedLocationInvoice(e.target.value)}
                            >
                                <option value="">--Select Location--</option>
                                {locations.sort().map((location, index) => (
                                    <option key={index} value={location.location_name}>
                                        {location.location_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group">
                            <button type="submit"
                                style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Submit
                            </button>
                            <button onClick={() => setIsCreateInvoiceModalOpen(false)}
                                style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
                <h3>Create Invoice End of Month</h3>
                <div>
                    <form onSubmit={handleSubmitInvoice}>
                        <div className="input-group">
                            <label>Start Date:</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label>End Date:</label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label>Select Location:</label>
                            <select
                                value={selectedLocationInvoice}
                                onChange={(e) => setSelectedLocationInvoice(e.target.value)}
                            >
                                <option value="">--Select Location--</option>
                                {locations.sort().map((location, index) => (
                                    <option key={index} value={location.location_name}>
                                        {location.location_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group">
                            <button type="submit"
                                style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Submit
                            </button>
                            <button onClick={() => setIsCreateInvoiceModalOpen(false)}
                                style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>


            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={isEditModalOpen}
                onRequestClose={() => setIsEditModalOpen(false)}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 2
                    },
                    content: {
                        width: '30%',
                        height: '75%',
                    }
                }}
            >
                <h3>Edit Invoice</h3>
                <div>
                    <form onSubmit={handleEditSubmit}>
                        <div className="input-group">
                            <label>Invoice Date:</label>
                            <input
                                type="date"
                                value={editedInvoice.invoice_date.split('T')[0]}
                                onChange={(e) => setEditedInvoice({ ...editedInvoice, invoice_date: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <label>Terms:</label>
                            <input
                                type="number"
                                value={editedInvoice.terms}
                                onChange={(e) => setEditedInvoice({ ...editedInvoice, terms: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <label>Payment Date:</label>
                            <input
                                type="date"
                                value={editedInvoice.payment_date.split('T')[0]}
                                onChange={(e) => setEditedInvoice({ ...editedInvoice, payment_date: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <label>Recipient:</label>
                            <input
                                type="text"
                                value={editedInvoice.recipient}
                                onChange={(e) => setEditedInvoice({ ...editedInvoice, recipient: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <label>Payment Amount:</label>
                            <input
                                type="number"
                                value={editedInvoice.payment_amount}
                                onChange={(e) => setEditedInvoice({ ...editedInvoice, payment_amount: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <label>Medvelle Network Discounts:</label>
                            <input
                                type="number"
                                value={editedInvoice.medvelle_network_discounts}
                                onChange={(e) => setEditedInvoice({ ...editedInvoice, medvelle_network_discounts: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <label>Credit Card Link:</label>
                            <input
                                type="text"
                                value={editedInvoice.cc_link}
                                onChange={(e) => setEditedInvoice({ ...editedInvoice, cc_link: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <label>ACH Link:</label>
                            <input
                                type="text"
                                value={editedInvoice.ach_link}
                                onChange={(e) => setEditedInvoice({ ...editedInvoice, ach_link: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <label>Invoice Status:</label>
                            <select
                                value={editedInvoice.invoice_status}
                                onChange={(e) => setEditedInvoice({ ...editedInvoice, invoice_status: e.target.value })}
                            >
                                {invoicesCategories.sort().map((category, index) => (
                                    <option key={index} value={category}>
                                    {category.charAt(0).toUpperCase() + category.slice(1)} {/* Capitalize first letter */}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group">
                            <button type="submit"
                                style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Submit
                            </button>
                            <button onClick={() => setIsEditModalOpen(false)}
                                style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={showDeleteConfirmation}
                onRequestClose={() => setShowDeleteConfirmation(false)}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 2,
                    },
                    content: {
                        width: '20%',
                        height: '15%',
                        textAlign: 'center'
                    }
                }}
            >
                <p>Are you sure you want to delete invoice ID {(invoiceToDelete ? invoiceToDelete.invoice_id : 'Unknown')}?</p>
                <button onClick={confirmDelete}
                    style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                >
                    Yes
                </button>
                <button onClick={() => setShowDeleteConfirmation(false)}
                    style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                >
                    No
                </button>
            </Modal>

            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={modalUploadOpen}
                onRequestClose={() => {setModalUploadOpen(false); setFile('');} }
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 2,
                    },
                    content: {
                        width: '20%',
                        height: '30%',
                        textAlign: 'center'
                    }
                }}
            >
                <form onSubmit={submitUploadInvoice}>
                    <div style={{ marginTop: '-15px', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                        <h3>Upload File</h3>

                        <input
                            type="file"
                            accept="*"
                            onChange={fileSelected}
                            style={{ display: 'none' }} // Hide the actual file input
                            id="fileInput" // Assign an ID for the label to reference
                        />
                        <label htmlFor="fileInput" 
                            style={{ marginBottom: '30px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                        >
                            Choose File
                        </label>
                        {file && 
                            <>
                                <br/>
                                <br/>
                                <span>{file.name}</span>
                                <br/>
                                <br/>
                                <button 
                                    type="submit"
                                    style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                >
                                    Submit
                                </button>
                            </>
                        }
                    </div>
                    
                    
                </form>
            </Modal>

            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={isEmailModalOpen}
                onRequestClose={handleCloseEmailModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 2
                    },
                    content: {
                        width: '40%',
                        height: 'auto',
                        textAlign: 'left'
                    }
                }}
            >
                <h3>Send Email</h3>
                <div>
                    <form onSubmit={handleSendEmail}>
                        <div className="input-group">
                            <label>To:</label>
                            <input
                                type="text"
                                value={emailDetails.to}
                                onChange={(e) => setEmailDetails({ ...emailDetails, to: e.target.value })}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>Cc:</label>
                            <input
                                type="text"
                                value={emailDetails.cc}
                                onChange={(e) => setEmailDetails({ ...emailDetails, cc: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <label>Subject:</label>
                            <input
                                type="text"
                                value={emailDetails.subject}
                                onChange={(e) => setEmailDetails({ ...emailDetails, subject: e.target.value })}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>Body:</label>
                            {/* <textarea
                                value={emailDetails.body}
                                onChange={(e) => setEmailDetails({ ...emailDetails, body: e.target.value })}
                                style= {{height: '200px'}}
                                required
                            /> */}
                            <ContentEditable
                                html={emailDetails.body} // Use "html" prop instead of "value"
                                onChange={(e) => setEmailDetails({ ...emailDetails, body: e.target.value })}
                                style={{ 
                                    width: '100%', 
                                    maxHeight: '120px', 
                                    padding: '5px', 
                                    overflowY: 'auto',
                                    backgroundColor: '#fff', // Add white background color
                                    marginTop: '10px',
                                    marginBottom: '10px'
                                }}
                            />
                        </div>
                        <div className="input-group" style={{marginBottom: '15px'}} >
                            <label>Attachments:</label>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '600px' }}>
                                <label style={{ display: 'flex', alignItems: 'center', width: '200px'  }}>
                                    <input
                                        type="checkbox"
                                        checked={emailDetails.attachInvoicePDF}
                                        onChange={(e) => setEmailDetails({ ...emailDetails, attachInvoicePDF: e.target.checked })}
                                        style={{ marginRight: '5px', width: '15px', backgroundColor: '#333', border: '2px solid #333' }}
                                    />
                                    Invoice PDF
                                </label>
                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        checked={emailDetails.attachBackupSheet}
                                        onChange={(e) => setEmailDetails({ ...emailDetails, attachBackupSheet: e.target.checked })}
                                        style={{ marginRight: '5px', width: '15px', backgroundColor: '#333', border: '2px solid #333' }}
                                    />
                                    Backup Sheet
                                </label>
                            </div>
                        </div>

                        <div className="input-group">
                            <button type="submit"
                                style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Send
                            </button>
                            <button onClick={handleCloseEmailModal}
                                style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                    {isEmailSending && <p style={{ color: 'black', marginTop: '15px' }}>Your email is being sent...</p>}
                </div>
            </Modal>

            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={isEOMEmailModalOpen}
                onRequestClose={handleCloseEOMEmailModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 2
                    },
                    content: {
                        width: '40%',
                        height: 'auto',
                        textAlign: 'left'
                    }
                }}
            >
                <h3>Send EOM Report Email</h3>
                <div>
                    <form onSubmit={handleSendEOMEmail}>
                        <div className="input-group">
                            <label>To:</label>
                            <input
                                type="text"
                                value={eomEmailDetails.to}
                                onChange={(e) => setEomEmailDetails({ ...eomEmailDetails, to: e.target.value })}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>Cc:</label>
                            <input
                                type="text"
                                value={eomEmailDetails.cc}
                                onChange={(e) => setEomEmailDetails({ ...eomEmailDetails, cc: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <label>Subject:</label>
                            <input
                                type="text"
                                value={eomEmailDetails.subject}
                                onChange={(e) => setEomEmailDetails({ ...eomEmailDetails, subject: e.target.value })}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>Body:</label>
                            <ContentEditable
                                html={eomEmailDetails.body}
                                onChange={(e) => setEomEmailDetails({ ...eomEmailDetails, body: e.target.value })}
                                style={{
                                    width: '100%',
                                    maxHeight: '120px',
                                    padding: '5px',
                                    overflowY: 'auto',
                                    backgroundColor: '#fff',
                                    marginTop: '10px',
                                    marginBottom: '10px'
                                }}
                            />
                        </div>
                        <div className="input-group" style={{ marginBottom: '15px' }}>
                            <label>Attachments:</label>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '600px' }}>
                                <label style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
                                    <input
                                        type="checkbox"
                                        checked={eomEmailDetails.attachEOMReport}
                                        onChange={(e) => setEomEmailDetails({ ...eomEmailDetails, attachEOMReport: e.target.checked })}
                                        style={{ marginRight: '5px', width: '15px', backgroundColor: '#333', border: '2px solid #333' }}
                                    />
                                    EOM Report
                                </label>
                                <label style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
                                    <input
                                        type="checkbox"
                                        checked={eomEmailDetails.attachCOGSReport}
                                        onChange={(e) => setEomEmailDetails({ ...eomEmailDetails, attachCOGSReport: e.target.checked })}
                                        style={{ marginRight: '5px', width: '15px', backgroundColor: '#333', border: '2px solid #333' }}
                                    />
                                    Accrual COGS Report
                                </label>
                                <label style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
                                    <input
                                        type="checkbox"
                                        checked={eomEmailDetails.attachProductUsageReport}
                                        onChange={(e) => setEomEmailDetails({ ...eomEmailDetails, attachProductUsageReport: e.target.checked })}
                                        style={{ marginRight: '5px', width: '15px', backgroundColor: '#333', border: '2px solid #333' }}
                                    />
                                    Product Usage Report
                                </label>
                            </div>
                        </div>


                        <div className="input-group">
                            <button type="submit"
                                style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Send
                            </button>
                            <button onClick={handleCloseEOMEmailModal}
                                style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                    {isEmailSending && <p style={{ color: 'black', marginTop: '15px' }}>Your email is being sent...</p>}
                </div>
            </Modal>

            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={isPlatformInvoiceModalOpen}
                onRequestClose={handleClosePlatformInvoiceModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 2
                    },
                    content: {
                        width: '50%',
                        height: '75%',
                        overflow: 'auto'
                    }
                }}
            >
                <h3>Generate Platform Invoice</h3>
                <div>
                    <form onSubmit={(e) => { e.preventDefault(); generatePlatformInvoicePDF(); }}>
                        <div className="input-group">
                            <label>Month Fee:</label>
                            <input
                                type="number"
                                value={platformInvoiceDetails.monthFee}
                                onChange={(e) => setPlatformInvoiceDetails({ ...platformInvoiceDetails, monthFee: e.target.value })}
                                
                            />
                        </div>
                        <div className="input-group">
                            <label>Onboarding Fee:</label>
                            <input
                                type="number"
                                value={platformInvoiceDetails.onboardingFee}
                                onChange={(e) => setPlatformInvoiceDetails({ ...platformInvoiceDetails, onboardingFee: e.target.value })}
                                
                            />
                        </div>
                        <div className="input-group">
                            <label>Invoice Date:</label>
                            <input
                                type="text"
                                value={platformInvoiceDetails.invoice_date}
                                onChange={(e) => setPlatformInvoiceDetails({ ...platformInvoiceDetails, invoice_date: e.target.value })}
                                
                            />
                        </div>
                        <div className="input-group">
                            <label>Due Date:</label>
                            <input
                                type="text"
                                value={platformInvoiceDetails.due_date}
                                onChange={(e) => setPlatformInvoiceDetails({ ...platformInvoiceDetails, due_date: e.target.value })}
                                
                            />
                        </div>
                        <div className="input-group">
                            <label>ACH Link:</label>
                            <input
                                type="text"
                                value={platformInvoiceDetails.ach_link}
                                onChange={(e) => setPlatformInvoiceDetails({ ...platformInvoiceDetails, ach_link: e.target.value })}
                                
                            />
                        </div>
                        <div className="input-group">
                            <label>Invoice ID:</label>
                            <input
                                type="number"
                                value={platformInvoiceDetails.invoice_id}
                                onChange={(e) => setPlatformInvoiceDetails({ ...platformInvoiceDetails, invoice_id: e.target.value })}
                                
                            />
                        </div>
                        <div className="input-group">
                            <button type="submit"
                                style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Generate
                            </button>
                            <button onClick={() => setIsPlatformInvoiceModalOpen(false)}
                                style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
                {platformInvoiceDetails.pdfBlob && (
                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <iframe
                            src={URL.createObjectURL(platformInvoiceDetails.pdfBlob)}
                            width="100%"
                            height="400px" />

                        <h3>Send Email</h3>
                        <div>
                            <form onSubmit={handleSendPlatformInvoiceEmail}>
                                <div className="input-group">
                                    <label>To:</label>
                                    <input
                                        type="text"
                                        value={platformEmailDetails.to}
                                        onChange={(e) => setPlatformEmailDetails({ ...platformEmailDetails, to: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="input-group">
                                    <label>Cc:</label>
                                    <input
                                        type="text"
                                        value={platformEmailDetails.cc}
                                        onChange={(e) => setPlatformEmailDetails({ ...platformEmailDetails, cc: e.target.value })}
                                    />
                                </div>
                                <div className="input-group">
                                    <label>Subject:</label>
                                    <input
                                        type="text"
                                        value={platformEmailDetails.subject}
                                        onChange={(e) => setPlatformEmailDetails({ ...platformEmailDetails, subject: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="input-group">
                                    <label>Body:</label>
                                    <ContentEditable
                                        html={platformEmailDetails.body}
                                        onChange={(e) => setPlatformEmailDetails({ ...platformEmailDetails, body: e.target.value })}
                                        style={{
                                            width: '100%',
                                            maxHeight: '120px',
                                            padding: '5px',
                                            overflowY: 'auto',
                                            backgroundColor: '#fff',
                                            marginTop: '10px',
                                            marginBottom: '10px'
                                        }}
                                    />
                                </div>
                                <div className="input-group" style={{ marginBottom: '15px' }}>
                                    <label>Attachments:</label>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '600px' }}>
                                        <label style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
                                            <input
                                                type="checkbox"
                                                checked={platformEmailDetails.attachPlatformInvoicePDF}
                                                onChange={(e) => setPlatformEmailDetails({ ...platformEmailDetails, attachPlatformInvoicePDF: e.target.checked })}
                                                style={{ marginRight: '5px', width: '15px', backgroundColor: '#333', border: '2px solid #333' }}
                                            />
                                            Platform Invoice PDF
                                        </label>
                                    </div>
                                </div>

                                <div className="input-group">
                                    <button type="submit"
                                        style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                    >
                                        Send
                                    </button>
                                    <button onClick={handleClosePlatformInvoiceModal}
                                        style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                            {isEmailSending && <p style={{ color: 'black', marginTop: '15px' }}>Your email is being sent...</p>}
                        </div>
                    </div>
                )}
            </Modal>

            
        </div>
    );
}

export default Invoices;