import React, { useState, useEffect } from 'react';
import { replyToEmail, fetchEmailConversation } from '../../utils/notifications/notificationsUtils';
import loadingSpinner from '../../assets/images/loading.gif';
import EmailTrackingModal from './EmailTrackingModal';
import { toast } from 'react-toastify';
import ContentEditable from 'react-contenteditable';
import { fetchTicketsInfo, fetchSuppliers } from '../../utils/locations/locationsUtils';
import { formatDateToUTCString, formatDateToLocalString } from '../../utils/time_zones/TimeZones';
import { handleBundleSubmit } from '../../utils/procurement/procurementUtils';
import InvoiceBundleModal from './InvoiceBundleModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';

const ConfirmedFlow = ({ ticketInfo, setTicketInfo, onClose, origin, emailConversation, setEmailConversation, emailID, locationNameSelected, setTickets, username, isBackOrder }) => {
    const { body, from, sent_date, subject, message_id, to_recipients, cc_recipients } = emailConversation.lastMessage;
    const [replyMessage, setReplyMessage] = useState('');
    const [senderEmail, setSenderEmail] = useState('order@medvelle.com');
    const [recipientEmails, setRecipients] = useState(from === senderEmail ? to_recipients :[from]);
    const [ccEmails, setCCEmails] = useState(cc_recipients);
    const [isSendReplyLoading, setIsSendReplyLoading] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [supplier, setSupplier] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [option, setOption] = useState('');
    const [taxes, setTaxes] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [eta, setEta] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [dueDateOption, setDueDateOption] = useState('');
    const [comment, setComment] = useState('');
    const [isBundleInvoiceModalOpen, setBundleInvoiceModalOpen] = useState(false);
    const [invoiceExist, setInvoiceExist] = useState(false);

    useEffect(() => {
        const loadSuppliers = async () => {
            const supplier_data = await fetchSuppliers(ticketInfo.location_name, ticketInfo.bundle_supplier_id);
            setSupplier(supplier_data[0]);
        };
        loadSuppliers();
    }, [ticketInfo]);

    useEffect(() => {

        updateBundleInfo();
    
    }, [ticketInfo]);

    const updateBundleInfo = async () => {
        setShipping(ticketInfo.shipping);
        setTaxes(ticketInfo.taxes);
        setDiscount(ticketInfo.discount);
        setComment(ticketInfo.bundle_comment);
        setEta(ticketInfo.eta);
        setTrackingNumber(ticketInfo.tracking_number)
        setDueDate(ticketInfo.due_date)
    };

    const handleReply = async () => {
        try {
            setIsSendReplyLoading(true);
            await replyToEmail(senderEmail, message_id, replyMessage, recipientEmails, ccEmails, ticketInfo.bundle_id);
            setIsSendReplyLoading(false);
            toast.success('Reply sent successfully!');
            if (option !== 'Backorder'){
                await fetchTicketsInfo(setTickets, locationNameSelected);
                onClose();
            } else {
                await fetchEmailConversation(emailID, 'order@medvelle.com', setEmailConversation);
            }
        } catch (error) {
            toast.error('Failed to send reply:', error);
            console.error('Failed to send reply:', error);
            setIsSendReplyLoading(false);
        }
        setReplyMessage('');
    };


    const handleFormSubmit = async (status) => {
        await handleBundleSubmit(
            ticketInfo, status, dueDateOption, setTicketInfo, onClose,
            setIsLoading, ticketInfo.bundle_supplier_id, origin,
            {}, trackingNumber, invoiceExist,
            shipping, taxes, discount, comment, dueDate, username, eta
        );

        await fetchTicketsInfo(setTickets, locationNameSelected);
    };

    const parseEmails = (input) => {
        const emailArray = input.split(/,\s*/);
        return emailArray.map(email => email.trim());
    };

    const formatPhoneNumber = (phoneNumber) => {
        try {
            if (phoneNumber === '' || !phoneNumber) {
                return '';
            }
            const phoneString = phoneNumber.toString();
            const areaCode = phoneString.slice(0, 3);
            const centralOfficeCode = phoneString.slice(3, 6);
            const lineNumber = phoneString.slice(6, 10);
            return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
        } catch (error) {
            return phoneNumber;
        }
    };

    const getdetailsWindow = (origin) => (

        <div>
            <div>
                <p><strong>Customer Service Phone Number:</strong> {supplier && formatPhoneNumber(supplier.cust_service_phone)} | <strong>Customer Service Email:</strong> {supplier && supplier.cust_service_email}</p>
            </div>
            <div>
                <p><strong>Platform website:</strong> {supplier && supplier.website} | <strong>Username:</strong> {supplier && supplier.username} | <strong>Password:</strong> {supplier && supplier.password}</p>
            </div>
            <div>
                <p><strong>Rep Name:</strong> {supplier && (supplier.rep_first_name + ' ' + supplier.rep_last_name)} | <strong>Rep Email:</strong> {supplier && supplier.rep_email} | <strong>Rep Phone Number:</strong> {supplier && formatPhoneNumber(supplier.rep_phone)}</p>
            </div>
            <p><strong>Subtotal:</strong> ${ticketInfo.total_price.toFixed(2)}</p>
            <div style={{ display: 'flex', marginBottom: '20px' }}>
                <div style={{marginRight: '10px'}}>
                    <label>Taxes:</label>
                    <input type="number" value={taxes} onChange={(e) => setTaxes(e.target.value)} style={{ padding: '5px', marginBottom: '5px' }} />
                </div>
                <div style={{marginRight: '10px'}}>
                    <label>Shipping:</label>
                    <input type="number" value={shipping} onChange={(e) => setShipping(e.target.value)} style={{ padding: '5px', marginBottom: '5px' }} />
                </div>
                <div style={{marginRight: '10px'}}>
                    <label>Discount:</label>
                    <input type="number" value={discount} onChange={(e) => setDiscount(e.target.value)} style={{ padding: '5px', marginBottom: '5px' }} />
                </div>
            </div>
            <p><strong>Total:</strong> ${(ticketInfo.total_price + parseFloat(taxes) + parseFloat(shipping) - parseFloat(discount)).toFixed(2)}</p>
            <div style={{ display: 'flex'}}>
                <div style={{marginRight: '10px'}}>
                    <label>Tracking Number:</label>
                    <input type="text" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} style={{ padding: '10px', marginBottom: '10px' }} />
                </div>
                <div style={{ marginRight: '10px' }}>
                    <label>ETA:</label>
                    <input 
                        type="datetime-local" 
                        value={eta && formatDateToLocalString(eta)} 
                        onChange={(event) => {
                            setEta(formatDateToUTCString(event.target.value));
                        }}
                        placeholder="YYYY-MM-DDTHH:MM"
                        style={{ padding: '10px', marginBottom: '10px' }} 
                    />
                </div>
            </div>
            <div className="input-group">
                <strong>Comment:</strong>
                <br/>
                <textarea value={comment} onChange={(e) => setComment(e.target.value)} style={{ padding: '10px', marginBottom: '10px', width: '100%', boxSizing: 'border-box' }} />
            </div>
            <div className="input-group">
                <strong>Due Date:</strong>
                <br/>
                <select
                    id="dueDateOption"
                    value={dueDateOption}
                    onChange={(e) => setDueDateOption(e.target.value)}
                    style={{width: '50%'}}
                >
                    <option value="">Select Option</option>
                    <option value="immediate">Immediate</option>
                    <option value="later">Later</option>
                </select>
                {dueDateOption === 'later' && (
                    <div>
                        <label htmlFor="dueDate">Due Date:</label>
                        <input
                            type="date"
                            id="dueDate"
                            value={dueDate}
                            onChange={(event) => {
                                const formattedDate = event.target.value.replace(/[^0-9-]/g, '');
                                setDueDate(formattedDate);
                            }}
                            placeholder="YYYY-MM-DD"
                        />
                    </div>
                )}
            </div>
            {!invoiceExist && (
                <div className="input-group">
                    <strong>Invoice:</strong>
                    <br/>
                    <button
                        className='button-style'
                        onClick={() => {
                            setBundleInvoiceModalOpen(true);
                        }}
                    >
                        Upload Invoice
                    </button>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', marginTop: '20px' }}>
                
                { isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <img
                            src={loadingSpinner}
                            alt="loading-spinner"
                            style={{
                                height: '20px',
                                marginRight: '10px',
                                padding: '10px'
                            }}
                        />
                    </div>
                ) : (
                    <button 
                        onClick={() => handleFormSubmit(option)}
                        className='button-style'
                    >
                        Move to {option}
                    </button>
                )}
            </div>
        </div>
    );

    const EmailPhoneButtons = () => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px', gap: '10px' }}>
                <button 
                    onClick={() => setOption('Follow-Up')} 
                    className="button-style"
                >
                    Follow up
                </button>
                { !isBackOrder &&
                    <>
                        { isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                <img
                                    src={loadingSpinner}
                                    alt="loading-spinner"
                                    style={{
                                        height: '20px',
                                        marginRight: '10px',
                                        padding: '10px'
                                    }}
                                />
                            </div>
                        ) : (
                            <button 
                                onClick={() => { setOption('Backorder'); }}
                                className='button-style'
                            >
                                Move to Backorder
                            </button>
                        )}
                    </>
                }   
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px', gap: '10px' }}>
                <button 
                    onClick={() => { setOption('Shipped'); }} 
                    className="button-style"
                >
                    Move to Shipped
                </button>
                <button 
                    onClick={() => { setOption('Delivered'); }} 
                    className="button-style"
                >
                    Move to Delivered
                </button>
            </div>
        </div>
    );

    const getEmailComponent = () => (
        <div className="email-flow" style={{width: '100%'}}>
            <div>
                <p><strong>Customer Service Phone Number:</strong> {supplier && formatPhoneNumber(supplier.cust_service_phone)} | <strong>Customer Service Email:</strong> {supplier && supplier.cust_service_email}</p>
            </div>
            <div>
                <p><strong>Platform website:</strong> {supplier && supplier.website} | <strong>Username:</strong> {supplier && supplier.username} | <strong>Password:</strong> {supplier && supplier.password}</p>
            </div>
            <div>
                <p><strong>Rep Name:</strong> {supplier && (supplier.rep_first_name + ' ' + supplier.rep_last_name)} | <strong>Rep Email:</strong> {supplier && supplier.rep_email} | <strong>Rep Phone Number:</strong> {supplier && formatPhoneNumber(supplier.rep_phone)}</p>
            </div>

            <div>
                <strong>Subject:</strong> {subject}
                <br />
                <strong>From:</strong> {from}
                <br />
                <strong>To:</strong> {to_recipients.join(', ')}
                <br />
                <strong>CC:</strong> {cc_recipients.join(', ')}
                <br />
                <strong>Last Message Date:</strong> { sent_date ? new Date(sent_date).toLocaleString() : '' }
            </div>

            <div
                style={{
                    border: '2px solid #87D3D7',
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '80px',
                    overflowY: 'auto',
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                    backgroundColor: 'white',
                }}
            >
                <div
                    className="email-body"
                    dangerouslySetInnerHTML={{ __html: body }}
                    style={{
                        marginTop: '20px',
                        maxHeight: '100%'
                    }}
                />
            </div>

            <div style={{ marginTop: '10px'}}>
                <label htmlFor="senderEmail">Sender Email:</label>
                <br/>
                <input
                    type="email"
                    id="senderEmail"
                    value={senderEmail}
                    onChange={(e) => setSenderEmail(parseEmails(e.target.value))}
                    style={{ width: '50%' }}
                />
                <br/>
                <label htmlFor="recipientEmails">Recipient Email(s):</label>
                <br/>
                <input
                    type="email"
                    id="recipientEmails"
                    value={recipientEmails}
                    onChange={(e) => setRecipients(parseEmails(e.target.value))}
                    style={{ width: '50%' }}
                />
                <br/>
                <label htmlFor="ccEmails">CC Email(s):</label>
                <br/>
                <input
                    type="email"
                    id="ccEmails"
                    value={ccEmails}
                    onChange={(e) => setCCEmails(parseEmails(e.target.value))}
                    style={{ width: '50%' }}
                />
                <br/>
                <label htmlFor="reply" style={{ marginRight: '10px' }}>Reply:</label>
                <br/>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <ContentEditable
                        html={replyMessage}
                        onChange={(e) => setReplyMessage(e.target.value)}
                        style={{
                            width: '100%',
                            height: '100px',
                            padding: '5px',
                            overflowY: 'auto',
                            backgroundColor: '#fff',
                            marginRight: '10px',
                            marginBottom: '10px'
                        }}
                    />
                    {(isSendReplyLoading || isLoading) ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                            <img
                                src={loadingSpinner}
                                alt="loading-spinner"
                                style={{
                                    height: '20px',
                                    marginRight: '10px',
                                    padding: '10px'
                                }}
                            />
                        </div>
                    ) : (
                        <button
                            className="button-style"
                            onClick={handleReply}
                            style={{ marginRight: '10px' }}
                        >
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    )}
                    <button
                        className="button-style"
                        onClick={() => setIsEmailModalOpen(true)}
                        style={{ cursor: 'pointer' }}
                    >
                        <FontAwesomeIcon icon={faAngleDoubleDown} />
                    </button>
                </div>
            </div>
        </div>
    );

    const getBackorderComponent = () => {
        return (
            <div>
                <br/>
                <br/>
                {getEmailComponent()}
                <div style={{ marginRight: '10px' }}>
                    <label>ETA:</label>
                    <input 
                        type="datetime-local" 
                        value={eta && formatDateToLocalString(eta)} 
                        onChange={(event) => {
                            setEta(formatDateToUTCString(event.target.value));
                        }}
                        placeholder="YYYY-MM-DDTHH:MM"
                        style={{ padding: '10px', marginBottom: '10px' }} 
                    />
                </div>
                { isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <img
                            src={loadingSpinner}
                            alt="loading-spinner"
                            style={{
                                height: '20px',
                                marginRight: '10px',
                                padding: '10px'
                            }}
                        />
                    </div>
                ) : (
                    <button 
                        onClick={() => handleFormSubmit('Backorder')}
                        className='button-style'
                        style={{marginBottom: '10px'}}
                    >
                        Move to Backorder
                    </button>
                )}
            </div>
        );
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '70vh',
            padding: '20px', 
            overflow: 'auto'
        }}>          
            { option === '' && 
                <EmailPhoneButtons />
            }
            
            { option === 'Follow-Up' &&
                getEmailComponent()
            }

            { option === 'Backorder' &&
                getBackorderComponent()
            }

            { (option === 'Shipped' || option === 'Delivered') &&
                getdetailsWindow(origin)
            }

            { option !== '' && 
                <div>
                    <button 
                        onClick={() => { setOption(''); }} 
                        className="button-style"
                    >
                    Back
                    </button>
                </div>
            }
            
            { ticketInfo &&
                <InvoiceBundleModal
                    isOpen={isBundleInvoiceModalOpen}
                    onClose={() => setBundleInvoiceModalOpen(false)}
                    editedBundle={ticketInfo}
                    setInvoiceExist={setInvoiceExist}
                />
            }

            <EmailTrackingModal
                isOpen={isEmailModalOpen}
                onClose={() => setIsEmailModalOpen(false)}
                emailConversation={emailConversation}
                setEmailConversation={setEmailConversation}
                emailID={emailID}
                bundleID={ticketInfo.bundle_id}
                setTickets={setTickets}
                locationNameSelected={locationNameSelected}
            />
            
        </div>
    );
};

export default ConfirmedFlow;
