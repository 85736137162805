import React, { useState, useEffect } from 'react';
import InfoPopup from '../../components/InfoPopup';
import { toast, ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import {fetchManagerReviewStatus, updateManagerReviewStatus} from '../../utils/locations/locationsUtils';
import {fetchCheckDeliveries, updateDeliveryReviewStatus} from '../../utils/locations/locationsUtils';


const OrderSettings = ({locationName}) => {

    const [budget, setBudget] = useState('');
    const [isManagerReviewed, setIsManagerReviewed] = useState(false)
    const [isCheckDeliveries, setIsCheckDeliveries] = useState(false)

    const fetchBudget = (setBudget) => fetch(`${process.env.REACT_APP_BACKEND_URL}/get-budget`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName }),
        })
        .then(response => response.json())
        .then(data => {
            setBudget(data.budget);
        })
        .catch(error => console.error('Error fetching budget:', error));

    useEffect(() => {

        fetchBudget(setBudget);
        fetchManagerReviewStatus({locationName, setIsManagerReviewed})
        fetchCheckDeliveries({locationName, setIsCheckDeliveries})
    }, [locationName]);


    const handleSetBudget = async () => {
        if (budget <= 0){
            alert("The budget has to be strictly positive.");
            return;
        }

        fetch(`${process.env.REACT_APP_BACKEND_URL}/set-budget`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName, budget: budget }),
        })

        toast.success('Monthly budget updated successfully');
    };

    const toggleRecommendationReview = async () => {
        const newReviewSetting = !isManagerReviewed
        const success = await updateManagerReviewStatus({locationName, isManagerReviewed: newReviewSetting})
        if(success) {
          setIsManagerReviewed(newReviewSetting)
        }
      }
    
    const toggleDeliveryCheck = async () => {
    const newDeliverySetting = !isCheckDeliveries
    const success = await updateDeliveryReviewStatus({locationName, isCheckDeliveries: newDeliverySetting})
    if(success) {
        setIsCheckDeliveries(newDeliverySetting)
    }
    }


    return(
        <div>

            <div>
                <h1 style={{ marginTop: '25px', display: 'flex', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7' }}>Monthly Ordering Budget</h1>

                $<input
                    type="number"
                    style={{width: '150px', marginLeft: '2px'}}
                    value={budget}
                    onChange={e => setBudget(e.target.value)}
                    placeholder = 'Enter Monthy Budget'
                    min="0"
                />

                <button 
                    onClick={() => handleSetBudget()} 
                    style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px', marginLeft: '10px' }}
                >
                    Submit
                </button>
            </div>

            <div>
          <h1 style={{marginTop: '25px', display: 'flex', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7'}}>Review Order Recommendation</h1>
          <div style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}>
            <span style={{marginRight: '10px'}}>No</span>
            <label className="switch">
              <input type="checkbox" checked={isManagerReviewed} onChange={toggleRecommendationReview} />
              <span className="slider round"></span>
            </label>
            <span style={{marginLeft: '10px'}}>Yes</span>
          </div>
          </div>

          <div>
          <h1 style={{marginTop: '25px', display: 'flex', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7'}}>Order Delivery Checks</h1>
          <div style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}>
            <span style={{marginRight: '10px'}}>No</span>
            <label className="switch">
              <input type="checkbox" checked={isCheckDeliveries} onChange={toggleDeliveryCheck} />
              <span className="slider round"></span>
            </label>
            <span style={{marginLeft: '10px'}}>Yes</span>
          </div>
          </div>

        </div>

    );
}

export default OrderSettings;
