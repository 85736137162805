import React from 'react';

const Slider = ({ value, min, max, marks, onChange, onChangeCommitted, isDisabled }) => {
  const sliderWidth = 600; // width of the slider in pixels
  const textExtension = 100;
  const widthProductCount = 12;


  // Calculate the percentage for each mark based on its position in the array
  const calculateMarkPosition = (index) => {
    return (index / (marks.length - 1)) * (sliderWidth - widthProductCount); // Calculate the exact position for each mark
  };

  // Handle the commit event when the mouse is released
  const handleMouseUp = () => {
    if (onChangeCommitted) {
      onChangeCommitted({ target: { value } }); // Call onChangeCommitted with the current value
    }
  };

  // Handle the commit event for touch devices
  const handleTouchEnd = () => {
    if (onChangeCommitted) {
      onChangeCommitted({ target: { value } }); // Call onChangeCommitted with the current value
    }
  };

  return (
    <div>
      {/* Input Slider */}
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        onMouseUp={handleMouseUp} // Trigger onChangeCommitted on mouse up
        onTouchEnd={handleTouchEnd} // Trigger onChangeCommitted on touch end
        style={{
          width: `${sliderWidth}px`,
          accentColor: '#C37C64',
          background: `linear-gradient(to right, #F49C7D 0%, #F49C7D ${(value * 100) / max}%, #eaeaea ${(value * 100) / max}%, #eaeaea 100%)`,
          borderRadius: '10px',
          height: '10px'
        }}
      />

      {/* Marks */}
      <div style={{ position: 'relative', width: `${sliderWidth + textExtension}px`, height: '30px' }}>
        {marks.map((mark, index) => (
          <span
            key={index}
            style={{
              position: 'absolute',
              left: `${calculateMarkPosition(index)}px`, // Position the mark at the correct point on the slider
              color: 'black',
              fontSize: '12px'
            }}
          >
            {mark}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Slider;