import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const ModalHeader = ({description, title, showExit = false, subheader, onClose = () => { }}) => {
    return (
        <div style={{flex: '0 1 auto', padding: '20px', borderBottom: '1px solid #ccc'}}>
            <div style={{display: 'flex'}}>
                <div>
                    {description && (
                        <>
                            <span style={{color: 'gray', fontSize: '20px'}}>{description}</span>
                            <br />
                        </>
                    )}
                    <div>
                        <b style={{color: 'black', fontSize: '25px', }}>
                            {title}
                        </b>
                    </div>
                    {subheader && (
                        <>
                            <span style={{}}>{subheader}</span>
                            <br />
                        </>
                    )}
                </div>

                {showExit && (
                    <div style={{marginLeft: 'auto'}}>
                        <button className="action-button" onClick={onClose}> <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></button>
                    </div>

                )}
            </div>


        </div>
    );
};

export default ModalHeader;