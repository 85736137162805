import React from 'react';
import Modal from 'react-modal';
import ModalHeader from '../../components/ModalHeader.js';
import ReturnModal from './ReturnModal'; // Import ReturnModal
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import { formatDateText } from '../../utils/time_zones/TimeZones';

const DisputeStatusModal = ({ isOpen, onClose, orderDetails}) => {

  // Function to convert business days to client-friendly urgency level
const convertUrgency = (urgency) => {
  switch (urgency) {
    case "1 business day":
      return "Save My Sanity, Fast!";
    case "2-3 business days":
      return "Just Need It Soon-ish";
    case "3-5 business days":
      return "I Can Wait (Kinda)";
    case "5+ business days":
      return "Whenever You Get a Chance!";
    default:
      return "N/A"; // Default if urgency is not recognized
  }
};

  return (
    <Modal
      overlayClassName="white-modal-overlay"
      className="white-modal"
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          width: '75vw',
          height: '72vh',
          display: 'flex',
          alignItems: 'center', 
          justifyContent: 'center',
          border: '2px solid #87D3D7'
        },

      }}
    >
            
      <div style={{width: '100%', height: '100%'}}>

      <div>

      <h1 style={{ color: '#87D3D7', marginBottom: '-10px', textAlign: 'center', }}>
        Medvelle Dispute Resolution Assistant
      </h1>
      <button className="support-button"
                    style={{
                        marginLeft: 'auto', // Push the button to the far right
                        fontSize: 'medium',
                        display: 'flex',
                        marginTop:'-40px',
                        marginRight:'10px',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        height: '2.5rem',
                        width: '2.5rem'


                    }} onClick={onClose}>
                    <FontAwesomeIcon style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignContent: 'center',
                        alignItems: 'center'
                    }} icon={faTimes} />
                </button>
        </div>


      <h2 style={{ color: 'black', fontSize: '30px', textAlign: 'center', marginBottom: '20px', color: 'black', marginTop: '70px' }}>Medvelle Team is processing your request!</h2>

      <div 
        style={{ 
            width: '30vw', 
            height: '30vh', 
            marginTop: '3vh',
            marginLeft: '18.5em', 
            border: '2px solid #87D3D7',
            borderRadius: '10px', 
            display: 'flex', 
            flexDirection: 'column', // Stack items vertically
            alignItems: 'flex-start', // Align items to the start of the container
            justifyContent: 'center',
            padding: '20px'
        }}
        >
        <div style={{ marginBottom: '10px', fontSize: '20px' }}>Product Name: {orderDetails.product_name}</div>
        <div style={{ marginBottom: '10px', fontSize: '20px' }}>Dispute Initiated By: {orderDetails.first_name}</div>
        <div style={{ marginBottom: '10px', fontSize: '20px' }}>
            Request Date: {orderDetails.order_date ? formatDateText(orderDetails.order_date) : 'N/A'}
        </div>
        <div style={{ marginBottom: '10px', fontSize: '20px' }}>Urgency: {convertUrgency(orderDetails.order_urgency)}</div>
        <div style={{  fontSize: '20px' }}>Additional Notes Given:
         <div>{orderDetails.return_additional_details}</div></div>

        </div>


      </div>
    </Modal>
  );
};

export default DisputeStatusModal;
