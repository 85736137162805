// LineGraph.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const LineGraph = ({data, title, xAxisLabel, yAxisLabel, lineColor, width, height, yPrefix = '' }) => {
    // Round values to one decimal place
    const roundedData = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [key, Math.round(value * 10) / 10])
    );

    const chartData = {
        labels: Object.keys(roundedData),
        datasets: [
            {
                label: yAxisLabel,
                data: Object.values(roundedData),
                borderColor: lineColor,
                backgroundColor: lineColor,
                fill: false,
                tension: 0.2,
                pointRadius: 0
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: title,
                font: {
                    size: 18,
                    weight: 'bold'
                },
                color: 'gray'
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                border:{dash: [4, 4]},
                title: {
                    display: true,
                    text: xAxisLabel,
                    font: {
                        weight: 'bold'
                    },
                    color: 'gray'
                },
                ticks: {
                    font: {
                        weight: 'bold'
                    },
                    color: 'gray'
                },
                grid: {
                    color: 'lightgray'
                }
            },
            y: {
                border:{dash: [4, 4]},
                title: {
                    display: true,
                    text: yAxisLabel,
                    font: {
                        weight: 'bold'
                    },
                    color: 'gray'
                },
                ticks: {
                    beginAtZero: true,
                    font: {
                        weight: 'bold'
                    },
                    color: 'gray',
                    callback: function (value) {
                        return yPrefix + value;
                    },
                },
                grid: {
                    color: 'lightgray' // Set y-axis grid line color to gray
                }
            },
        },
    };

    return <Line data={chartData} options={options} width={width} height={height} />;
};

export default LineGraph;
