import { toast } from 'react-toastify';

export const fetchSpotcheckSettings = async (setSpotcheckSettings, locationName, setIsDefaultSettings, defaultSettings) => {
    let spotcheckSettings;
    
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get-spotcheck-settings', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({locationName})
        });
        if (!response.ok) throw new Error('Failed to fetch spotcheck settings');
        spotcheckSettings = await response.json();

        spotcheckSettings = JSON.parse(spotcheckSettings);

    } catch (error) {
        console.error('Error fetching spotcheck settings:', error);
        spotcheckSettings = defaultSettings;
    }

    const areEqual = Object.keys(spotcheckSettings).length === Object.keys(defaultSettings).length &&
                 Object.keys(spotcheckSettings).every(key => spotcheckSettings[key] === defaultSettings[key]);

    setIsDefaultSettings(areEqual);

    setSpotcheckSettings(spotcheckSettings);
};

export const updateSpotcheckSettings = async (settings, locationName) => {
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/update-spotcheck-settings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({settings, locationName}),
        });
        if (!response.ok) throw new Error('Failed to update spotcheck settings');
        console.log('Settings updated successfully');
    } catch (error) {
        console.error('Error updating spotcheck settings:', error);
    }
};

export const saveIdealWeeks = async (locationName, idealWeeks) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/update-ideal-weeks`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ location_name: locationName, ideal_weeks: idealWeeks }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.message === 'Ideal weeks updated successfully') {
        toast.success('Ideal weeks updated successfully');
      } else {
        toast.error('Failed to update ideal weeks');
      }
    })
    .catch(error => console.error('Error updating ideal weeks:', error));
};

export const fetchIdealWeeks = async (locationName, setIdealWeeks) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-ideal-weeks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setIdealWeeks(JSON.parse(data['ideal_weeks']));
    } catch (error) {
        console.error('Error fetching ideal weeks:', error);
    }
};

export const fetchConfidenceInterval = async (locationName, setInventoryLevel) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-confidence-interval`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setInventoryLevel(data.confidence_interval);
    } catch (error) {
        console.error('Error fetching confidence interval:', error);
    }
};

export const fetchCustomWeeks = async (locationName, setCustomToggle) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-custom-weeks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setCustomToggle(data.custom_weeks === 1);
    } catch (error) {
        console.error('Error fetching custom weeks:', error);
    }
};

// Define the fetch function for updating inventory level
export const updateInventoryLevelFetch = async (locationName, newValue) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-confidence-interval`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName, new_value: newValue }),
    });
    return response.json();
};

// Define the fetch function for updating ideal weeks
export const updateIdealWeeksFetch = async (locationName, updatedIdealWeeks) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-ideal-weeks`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName, ideal_weeks: updatedIdealWeeks }),
    });
    return response.json();
};

export const updateCustomWeeks = async (locationName, customWeeksValue) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-custom-weeks`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName, custom_weeks: customWeeksValue }),
        });
        return response.ok;
    } catch (error) {
        console.error('Error updating custom weeks:', error);
        return false;
    }
};