import React from 'react';

const ProgressBar = ({ steps, currentStep, totalSteps }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '0 10px',
              }}
            >
              <div
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: index < currentStep ? '#F49C7D' : '#e0e0e0',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: index < currentStep ? '#fff' : '#000',
                  fontWeight: 'bold',
                }}
              >
                {index + 1}
              </div>
            </div>
            {index < totalSteps - 1 && (
              <div
                style={{
                  width: '90px',
                  height: '4px',
                  backgroundColor: index < currentStep - 1 ? '#F49C7D' : '#e0e0e0',
                  marginLeft: '-10px',
                  marginRight: '-10px',
                }}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'top' }}>
        {steps.map((step, index) => (
          <p
            key={index}
            style={{
              width: '100px',
              margin: '10px',
              fontSize: '12px',
              fontWeight: currentStep === index + 1 ? 'bold' : 'normal',
            }}
          >
            {step.title}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
