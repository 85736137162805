export const fetchUnmappedProducts = async (call, selectedLocation, callback) => {
    try {
        const response = await fetch(`${call}/get-unmapped-products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location_name: selectedLocation })
        });

        if (response.ok) {
            const data = await response.json();
            callback(data);
            return data
        } else {
            console.error('Failed to fetch unmapped products:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

// Retrieves services without a product mapping.
export const fetchUnmappedServices = async (call, selectedLocation, callback) => {
    try {
        const response = await fetch(`${call}/get-unmapped-services`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location_name: selectedLocation })
        });

        if (response.ok) {
            const data = await response.json();
            callback(data);
            return data
        } else {
            console.error('Failed to fetch unmapped services:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

// Retrieves unknown services from tracking error appointments table.
export const fetchUnknownServices = async (call, selectedLocation, timeFrame, callback) => {
    try {
        const response = await fetch(`${call}/get-unknown-services`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location_name: selectedLocation, time_frame: timeFrame})
        });

        if (response.ok) {
            const data = await response.json();
            callback(data);
            return data
        } else {
            console.error('Failed to fetch unknown services:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const fetchDiscontinuedProducts = async (call, selectedLocation, callback) => {
    try {
        const response = await fetch(`${call}/get-discontinued-products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location_name: selectedLocation })
        });

        if (response.ok) {
            const data = await response.json();
            callback(data);
            return data
        } else {
            console.error('Failed to fetch discontinued products:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

// Retrieves mapping outliers outliers.
export const fetchMappingOutliers= async (call, selectedLocation, callback) => {
    try {
        const response = await fetch(`${call}/get-mapping-spotcheck`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location_name: selectedLocation})
        });

        if (response.ok) {
            const data = await response.json();
            callback(data);
            return data
        } else {
            console.error('Failed to fetch unknown services:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

// Fetch suppliers data
export const fetchSuppliersData = async (call, callback) => {
    try {
        const response = await fetch(`${call}/get-all-suppliers`);
        if (!response.ok) {
            throw new Error('Failed to fetch suppliers data');
        }
        const data = await response.json();

        callback(data);
        return data
    } catch (error) {
        console.error('Error fetching suppliers data:', error);
    }
};

// Function to fetch service mapping info from API
export const fetchServiceMappingInfo = async (call, selectedLocation, serviceId, callback) => {
    try {
        const response = await fetch(`${call}/get-service-mapping-info`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ serviceId, locationName: selectedLocation })
        });
        const data = await response.json();
        callback(data);
        return data
    } catch (error) {
        console.error('Error fetching service mapping info:', error);
    }
};

// Function to fetch service mapping info from API
export const fetchServiceInfo = async (call, service_name, price, callback) => {
    try {
        const response = await fetch(`${call}/services`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ service_name })
        });
        const data = await response.json();
        if (data.length > 0){
            const aggregated_data = data.reduce((result, obj) => {
                for (const [key, value] of Object.entries(obj)) {
                // Check if result[key] is empty or undefined before assigning the new value
                if ((result[key] === undefined || result[key] === '') && value !== '') {
                    result[key] = value;
                }
                }
                return result;
            }, {});
            if (price !== 0){
                aggregated_data.service_price = price
            }
            callback(aggregated_data);
        }
        else {
            callback({service_name: service_name, price:price});
        }
        return data;
    } catch (error) {
        console.error('Error fetching service info:', error);
    }
};

// Function to fetch service mapping info from API
export const fetchServiceMappingProductInfo = async (call, selectedLocation, productId, callback) => {
    try {
        const response = await fetch(`${call}/get-product-mapping-info`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                productId: productId,
                locationName: selectedLocation
            })
        });
        const data = await response.json();
        callback(data) 
        return data
        
    } catch (error) {
        console.error('Error fetching service mapping info:', error);
    }
};

// Gets all locations
export const fetchLocations = (call, callback) => {
    fetch(`${call}/locations`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            callback(data);
        })
        .catch(error => console.error('Error fetching locations data:', error));
};

export const fetchLastProductId = async (call, callback) => {
    try {

        // Make the PUT request to the server
        const response = await fetch(`${call}/last-product-id`);

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch last product ID');
        }

        // Parse the JSON response
        const data = await response.json();

        callback(data["lastProductId"]);

        // Return the services data
        return data;
    } catch (error) {
        console.error('Error fetching services data:', error);
        return null; // Return null in case of error
    }
};

export const fetchLastServiceId = async (call, callback) => {
    try {

        // Make the PUT request to the server
        const response = await fetch(`${call}/last-service-id`);

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch last service ID');
        }

        // Parse the JSON response
        const data = await response.json();

        callback(data["lastServiceId"]);

        // Return the services data
        return data;
    } catch (error) {
        console.error('Error fetching last service ID:', error);
        return null; // Return null in case of error
    }
};

export const fetchServicesData = async (call, selectedLocation, callback) => {
    try {
        // Define the data to be sent in the request body
        const requestData = {
            location_name: selectedLocation 
        };

        // Make the PUT request to the server
        const response = await fetch(`${call}/services`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch services data');
        }

        // Parse the JSON response
        const data = await response.json();
        callback(data);

        // Return the services data
        return data;
    } catch (error) {
        console.error('Error fetching services data:', error);
        return null; // Return null in case of error
    }
};

// Gets web product info.
export const fetchWebProducts = async (call, callback) => {
    try {
        const response = await fetch(`${call}/get-web-products`); // Assuming your backend server is running on the same host
        if (!response.ok) {
            throw new Error('Failed to fetch web products');
        }
        const data = await response.json();

        callback(data);

        return data;
    } catch (error) {
        console.error('Error fetching web products:', error);
        return [];
    }
};

// Gets all products
export const fetchProducts = async (call, callback) => {
    try {
        const response = await fetch(`${call}/get-products`); // Assuming your backend server is running on the same host
        if (!response.ok) {
            throw new Error('Failed to fetch products');
        }
        const data = await response.json();

        callback(data);

        return data;
    } catch (error) {
        console.error('Error fetching web products:', error);
        return [];
    }
};

// Define a function to fetch inventory data
export const fetchInventory = async (call, selectedLocation, callback) => {
    try {
        // Fetch data from the server
        const response = await fetch(`${call}/get-inventory`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ locationName: selectedLocation })
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch inventory data');
        }

        // Parse the JSON response
        const data = await response.json();

        callback(data);

        // Return the inventory data
        return data;
    } catch (error) {
        console.error('Error fetching inventory data:', error);
        return null; // Return null in case of error
    }
};

// /**
//  * Retrieves products and mappings for the selected service at all locations.
//  * @returns {Object[]} - The products and mappings for the selected service across all locations.
//  */
export const fetchCloneProductsData = async (call, service_name ,callback) => {
    try {
        // Define the data to be sent in the request body
        const requestData = {
            service_name: service_name
        }

        // Make the PUT request to the server
        const response = await fetch(`${call}/get-service-products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });
        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch services data');
        }

        // Parse the JSON response
        const data = await response.json();
        callback(data)

        return data;
    } catch (error) {
        console.error('Error fetching services data:', error);
        
        return null; // Return null in case of error
    }
};

/**
 * Retrieves services and mappings for the selected product at all locations.
 * @returns {Object[]} - The services and mappings for the selected product across all locations.
 */
export const fetchCloneServicesData = async (call, product_id, callback) => {
    try {
        // Define the data to be sent in the request body
        const requestData = {
            product_id: product_id
        };

        // Make the PUT request to the server
        const response = await fetch(`${call}/get-product-services`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch services data');
        }

        // Parse the JSON response
        const data = await response.json();
        callback(data)
        return data;
    } catch (error) {
        console.error('Error fetching services data:', error);
        return null; // Return null in case of error
    }
};

export const getPrice = async (call, product_id, location_name) => {
    try {
        const requestBody = JSON.stringify({ product_id, location_name });
        const response = await fetch(`${call}/get-price-product`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to update services mapping');
        }

        const data = await response.json();

        return data['priceInfo'];
    } catch (error) {
        console.error('Error updating services mapping:', error);
        return null;
    }
};

export const getInventoryWeb = async (call, product_id, location_name) => {
    try {
        const requestBody = JSON.stringify({ product_id, location_name });
        const response = await fetch(`${call}/get-inventory-product`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to get inventory product');
        }

        const data = await response.json();

        return data['inventoryInfo'];
    } catch (error) {
        console.error('Error getting inventory product:', error);
        return null;
    }
};