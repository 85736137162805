import React from "react";
import './assets/style/index.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './pages/authentication/Signin';
import ForgotPasswordPage from './pages/authentication/ForgotPassword';
import App from './App';
import { AuthProvider, useAuth } from "./context/AuthProvider";

const PrivateRoute = ({ children }) => {
  const {loginStatus, loading} = useAuth();
  if (!loading){
    return loginStatus ? children : <Navigate to="/sign-in" />;
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <App />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
