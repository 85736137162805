import React, { useState, useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { fetchRecentBundleUpdates } from '../../utils/procurement/procurementUtils';
import { formatDateTimeText } from '../../utils/time_zones/TimeZones';

const BundleTrackingTablePreview = ({ bundleID, ticketInfo }) => {
    const [recentBundleUpdates, setRecentBundleUpdates] = useState([]);

    useEffect(() => {
        fetchRecentBundleUpdates(setRecentBundleUpdates, bundleID);
    }, [ticketInfo]);

    return (
        <div style={{ height: '100px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px', backgroundColor: '#f9f9f9' }}>
            {recentBundleUpdates && recentBundleUpdates.length > 0 ? (
                <VerticalTimeline>
                    {recentBundleUpdates.map((item, index) => (
                        <VerticalTimelineElement
                            key={index}
                            contentStyle={{ background: '#f0f0f0', color: '#000', fontSize: '10px' }}
                            contentArrowStyle={{ borderRight: '7px solid  #f0f0f0' }}
                            date={<div style={{ fontSize: '10px', textAlign: (index %2 === 0 ? 'right' : 'left')}}>{formatDateTimeText(item.time)}</div>}
                            iconStyle={{ background: '#F49C7D', color: '#000' }}
                        >
                            <p style={{ textAlign: 'left', marginTop: '5px', fontSize: '10px' }}>
                                {item.action} - {item.location_name}
                            </p>
                            <p style={{ textAlign: 'left', marginTop: '5px', fontSize: '10px' }}>
                                Product: {item.product_name}
                            </p>
                            <p style={{ textAlign: 'left', marginTop: '5px', fontSize: '10px' }}>
                                Changed by: {item.change_username}
                            </p>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
            ) : (
                <div style={{ textAlign: 'center', fontStyle: 'italic', padding: '50px', height: '160px' }}>
                    No tracking data.
                </div>
            )}
        </div>
    );
};

export default BundleTrackingTablePreview;
