import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import ModalHeader from './ModalHeader';
import {toast} from 'react-toastify';
import Modal from 'react-modal';

const Credentials = ({username, password, unlocked, isUnlockable, isEditable=false, orientation = 'row', onSave=()=>{}}) => {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [newCredentials, setNewCredentials] = useState({
        username: '',
        password: '',
    })
    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });
    useEffect(() => {
        setCredentials({
            username: isUnlockable ? username : 'username',
            password: isUnlockable ? password : 'password',
        });
        setNewCredentials({
            username: isUnlockable ? username : 'username',
            password: isUnlockable ? password : 'password',
        });
    },
        [username, password]);

    return (
        <div style={{
            display: 'flex',
            width:'100%',
            flexDirection: orientation,
            gap: '20px',
            borderRadius: '8px',
            marginLeft:'20px',
        }}>
            <div style={{width: 'auto', marginRight: '20px'}}>
                <label style={{color: 'grey', width: '100%'}}>Username</label>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        padding: '10px',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                        backgroundColor: '#f5f5f5',
                        marginTop: '4px'
                    }}
                >
                    <input
                        type="text"
                        value={isEditable ? newCredentials.username : credentials.username}
                        onChange={(e) => setNewCredentials({...credentials, username: e.target.value})}
                        style={{
                            width: '100%',
                            border: 'none',
                            outline: 'none',
                            color: unlocked ? '#000' : '#aaa',
                            filter: unlocked ? 'none' : 'blur(4px)',
                            backgroundColor: 'transparent',
                            userSelect: unlocked ? 'auto' : 'none',
                            transition: 'filter 0.3s ease'
                        }}
                        disabled={!isEditable} // Disable input if not unlocked
                    />
                </div>
            </div>

            <div style={{width: 'auto', marginRight: '20px'}}>
                <label style={{color: 'grey',width:'100%'}}>Password</label>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        padding: '10px',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                        backgroundColor: '#f5f5f5',
                        marginTop:'4px'
                    }}
                >
                    <input
                        type="text"
                        value={isEditable ? newCredentials.password : credentials.password}
                        onChange={(e) => setNewCredentials({...credentials, password: e.target.value})}
                        style={{
                            width: '100%',
                            border: 'none',
                            outline: 'none',
                            color: unlocked ? '#000' : '#aaa',
                            filter: unlocked ? 'none' : 'blur(4px)',
                            backgroundColor: 'transparent',
                            userSelect: unlocked ? 'auto' : 'none',
                            transition: 'filter 0.3s ease'
                        }}
                        disabled={!isEditable} // Disable input if not unlocked
                    />
                </div>
                
            </div>
            <div
                style={{
                    position: 'relative', // Set position relative for tooltip positioning
                    cursor: 'pointer',
                    // marginLeft: '8px',
                    alignContent: 'flex-end',
                    marginBottom: '10px',
                    display: !isEditable ? 'none' : ''

                }}

                onClick={()=>setIsConfirmationOpen(true)}
            >
                <FontAwesomeIcon
                    icon={faSave} // Toggle between lock and unlock icons
                    size="lg"
                    className={'icon-hover'}
                />
            </div>

            <Modal
                isOpen={isConfirmationOpen}
                onRequestClose={()=>setIsConfirmationOpen(false)}
                className="white-modal"
                overlayClassName="white-modal-overlay"
                style={{content: {width: '30vw', height: 'auto'}}}
            >
                <ModalHeader
                    title={'Confirm New Credentials'}
                    // onClose={() => setIsConfirmationOpen(false)}
                    // showExit={true}
                />
                {/* Modal header with download and close buttons */}
                <div style={{
                    margin: '20px',
                }}>
                    <>
                        <div>Are you sure you want to update your credentials? This action cannot be undone.</div>
                        <div style={{display:'flex', gap:'8px', marginTop:'20px'}}>
                            <button className='action-button' onClick={ async ()=>{
                                const result = await onSave(newCredentials.username, newCredentials.password);
                                if (!result) {
                                    toast.error('Failed to update.')
                                    return
                                }
                                toast.success('Credentials updated!')
                                setIsConfirmationOpen(false)
                            }}>Update</button>
                            <button className='decline-button' style={{fontSize:'small'}} onClick={()=>setIsConfirmationOpen(false)}>Cancel</button>

                        </div>
                    </>
                </div>

            </Modal>
        </div>
    );
};

export default Credentials;
