import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import tick from '../assets/images/green-tick.png';
import cross from '../assets/images/red-cross.png';
import { formatDateText } from '../utils/time_zones/TimeZones';

const System = ({ locationName, call }) => {

    const contentRef = useRef();
    const [loopStatus, setLoopStatus] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [sortOrderDate, setSortOrderDate] = useState('desc'); // Default sort order is descending
    const [errorCounts, setErrorCounts] = useState([]);
    const [selectedService, setSelectedService] = useState('');
    const [flagsData, setFlagsData] = useState([]);
    const [serviceTrackingInfo, setServiceTrackingInfo] = useState([]);
    const [productsWithoutPrice, setProductsWithoutPrice] = useState([]);
    const [zeroPriceProducts, setZeroPriceProducts] = useState([]);
    const [productsQC, setProductsQC] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
    const [emailsSent, setEmailsSent] = useState([]);
    const [spotcheckStats, setSpotcheckStats] = useState([]);
    const [recommendationStats, setRecommendationStats] = useState([]);
    const [productsSpotcheck, setProductsSpotcheck] = useState([]);
    const [locations, setLocations] = useState([]); // Updated to store locations data

    useEffect(() => {
        fetchLocations();
        fetchLoopStatus();
        // fetchErrorCounts();
        fetchTrackingData();
        // fetchServiceTrackingInfo();
        fetchProductsNoPrice();
        fetchZeroPriceProducts();
        fetchProductsQC();
        fetchOrdersWithSpecificStatus();
        fetchEmailsSentPerDay();
        fetchSpotcheckStats();
        fetchRecommendationStats();
        fetchProductsSpotcheck();

        contentRef.current.style.marginLeft = '220px';
    }, []);

    const fetchLocations = () => {
        // Fetch locations data from the /locations API
        fetch(`${call}/locations`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            setLocations(data); // Update the locations state with the fetched data
          })
          .catch(error => console.error('Error fetching locations data:', error));
      };

    const fetchProductsSpotcheck = async () => {
        try {
            const response = await fetch(`${call}/products_with_old_spotcheck`);
            const data = await response.json();
            setProductsSpotcheck(data);
        } catch (error) {
            console.error('Error fetching old spotchecks:', error);
        }
    };

    const fetchRecommendationStats = async () => {
        try {
            const response = await fetch(`${call}/recommendation_status_percentage`);
            const data = await response.json();
            setRecommendationStats(data);
        } catch (error) {
            console.error('Error fetching recommendations stats:', error);
        }
    };

    const fetchSpotcheckStats = async() => {
        try {
            const response = await fetch(`${call}/spotcheck_stats`);
            const data = await response.json();
            setSpotcheckStats(data);
        } catch (error) {
            console.error('Error fetching spotcheck stats:', error);
        }
    };

    const fetchEmailsSentPerDay = async () => {
        try {
            const response = await fetch(`${call}/emails_sent_per_day`);
            const data = await response.json();
            setEmailsSent(data);
        } catch (error) {
            console.error('Error fetching emails sent per day:', error);
        }
    };

    const fetchOrdersWithSpecificStatus = async () => {
        try {
            const response = await fetch(`${call}/orders_with_specific_status`);
            const data = await response.json();
            setOrders(data);
        } catch (error) {
            console.error('Error fetching orders with specific status:', error);
            toast.error('Failed to fetch orders with specific status');
        }
    };

    const fetchProductsQC = async () => {
        try {
            const response = await fetch(`${call}/products_with_zero_conversion`);
            const data = await response.json();
            setProductsQC(data);
        } catch (error) {
            console.error('Error fetching products:', error);
            toast.error('Failed to fetch products');
        }
    };

    const fetchLoopStatus = async () => {
        try {
            const response = await fetch(`${call}/full_loop_tracking`);
            const data = await response.json();
            setLoopStatus(data);
        } catch (error) {
            console.error('Error fetching loop status:', error);
            toast.error('Failed to fetch loop status');
        }
    };

    const fetchErrorCounts = async () => {
        try {
            const response = await fetch(`${call}/error_counts_by_service`);
            const data = await response.json();
            setErrorCounts(data);
        } catch (error) {
            console.error('Error fetching error counts by service:', error);
        }
    };


    const fetchTrackingData = async () => {
        try {
            const response = await fetch(`${call}/tracking_data`);
            const data = await response.json();
            setFlagsData(data);
        } catch (error) {
            console.error('Error fetching tracking data:', error);
            toast.error('Failed to fetch tracking data');
        }
    };

    const fetchServiceTrackingInfo = async () => {
        try {
            const response = await fetch(`${call}/service_mapping_tracking_info`);
            const data = await response.json();
            setServiceTrackingInfo(data);
        } catch (error) {
            console.error('Error fetching service tracking info:', error);
            toast.error('Failed to fetch service tracking info');
        }
    };

    const fetchProductsNoPrice = async () => {
        try {
            const response = await fetch(`${call}/product_without_price`);
            const data = await response.json();
            setProductsWithoutPrice(data);
        } catch (error) {
            console.error('Error fetching products without price:', error);
            toast.error('Failed to fetch products without price');
        }
    };

    const fetchZeroPriceProducts = async () => {
        try {
            const response = await fetch(`${call}/products_with_zero_price`);
            const data = await response.json();
            setZeroPriceProducts(data);
        } catch (error) {
            console.error('Error fetching zero price products:', error);
        }
    };

    const filteredSpotcheckStats = selectedLocation
    ? spotcheckStats.filter(item => item.location_name === selectedLocation)
    : spotcheckStats;

    const filteredProductsSpotcheck = selectedLocation
    ? productsSpotcheck.filter(item => item.location_name === selectedLocation)
    : productsSpotcheck;

    const sortedFilteredProductsSpotcheck = filteredProductsSpotcheck.sort((a, b) => {
        const dateA = new Date(a.spotcheck_date);
        const dateB = new Date(b.spotcheck_date);
        return sortOrderDate === 'asc' ? dateA - dateB : dateB - dateA;
    });

    const filteredRecommendationStats = selectedLocation
    ? recommendationStats.filter(item => item.location_name === selectedLocation)
    : recommendationStats;

    const filteredLoopStatus = selectedLocation
    ? loopStatus.filter(item => item.location_name === selectedLocation)
    : loopStatus;

    // Sort the loop status based on date
    const sortedLoopStatus = filteredLoopStatus.sort((a, b) => {
        const dateA = new Date(a.date_time);
        const dateB = new Date(b.date_time);
        return sortOrderDate === 'asc' ? dateA - dateB : dateB - dateA;
    });

    const filteredOrders = selectedLocation
    ? orders.filter(item => item.location_name === selectedLocation)
    : orders;

    const filteredStatusOrders = selectedOrderStatus
    ? filteredOrders.filter(item => item.status_cat === selectedOrderStatus)
    : filteredOrders;

    const filteredServiceTrackingInfo = selectedLocation
    ? serviceTrackingInfo.filter(item => item.location_name === selectedLocation)
    : serviceTrackingInfo;

    // Sort the loop status based on date
    const sortedFilteredServiceTrackingInfo = filteredServiceTrackingInfo.sort((a, b) => {
        const dateA = new Date(a.last_date);
        const dateB = new Date(b.last_date);
        return sortOrderDate === 'asc' ? dateA - dateB : dateB - dateA;
    });


    const filteredEmailsSent = selectedLocation
    ? emailsSent.filter(item => item.location_name === selectedLocation)
    : emailsSent;

    // Sort the loop status based on date
    const sortedFilteredEmailsSent = filteredEmailsSent.sort((a, b) => {
        const dateA = new Date(a.sent_date);
        const dateB = new Date(b.sent_date);
        return sortOrderDate === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    const filterErrorCounts = selectedLocation
    ? errorCounts.filter(item => item.location_name === selectedLocation && !/^\d+$/.test(item.service_name))
    : errorCounts.filter(item => !/^\d+$/.test(item.service_name));

    const filteredErrorsServices = filterErrorCounts.filter(error => {
        if (!selectedService) return true; // If no service is selected, show all errors
        return error.service_name === selectedService;
    });

    // Sort the errors based on last update date
    const sortedErrorsDate = filteredErrorsServices.sort((a, b) => {
        const dateA = new Date(a.last_update);
        const dateB = new Date(b.last_update);
        return sortOrderDate === 'asc' ? dateA - dateB : dateB - dateA;
    });

    const filteredFlagsData = selectedLocation
    ? flagsData.filter(item => item.location_name === selectedLocation)
    : flagsData;

    const sortedFilteredFlagsData = filteredFlagsData.sort((a, b) => {
        const dateA = new Date(a.date_time);
        const dateB = new Date(b.date_time);
        return sortOrderDate === 'asc' ? dateA - dateB : dateB - dateA;
    });

    const [isBackupLoading, setIsBackupLoading] = useState(false);
    const [isExcelBackupLoading, setIsExcelBackupLoading] = useState(false);
    const [isCSVBackupLoading, setIsCSVBackupLoading] = useState(false);
    const [isSnapshotBackupLoading, setIsSnapshotBackupLoading] = useState(false);

    const handleSpotCheck = () => {
        console.log(locationName)
    
        fetch(`${call}/update-spotcheck-status`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({locationName}),
        });
      }
    
    const handleBackupDatabase = async () => {

        // Set loading state
        setIsBackupLoading(true);
    
        // Make a POST request to the /backup route when the button is clicked
        await fetch(`${call}/backup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ run_backup: true }),
        })
          .then((response) => {

            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((result) => {
            // Check if the backup was successful
            if (result.message === 'Backup initiated successfully.') {
              // Display a success toast notification
              toast.success('Database backup successful!', {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              // Display an error toast notification
              toast.error('Database backup failed!', {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            console.log('Backup Database Result:', result);
          })
          .catch((error) => {
            // Display an error toast notification for network errors
            toast.error('Error during database backup', {
              position: toast.POSITION.TOP_CENTER,
            });
            console.error('Error during database backup:', error);
          })
          .finally(() => {
            // Reset loading state to enable the button
            setIsBackupLoading(false);
        });

    };

    const handleExcelBackupDatabase = async () => {
        // Set loading state
        setIsExcelBackupLoading(true);
    
        // Make a POST request to the /backup route when the button is clicked
        await fetch(`${call}/backup-to-excel`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((result) => {
            // Check if the backup was successful
            if (result.message === 'Backup initiated successfully.') {
              // Display a success toast notification
              toast.success('Database backup successful!', {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              // Display an error toast notification
              toast.error('Database backup failed!', {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            console.log('Backup Database Result:', result);
          })
          .catch((error) => {
            // Display an error toast notification for network errors
            toast.error('Error during database backup', {
              position: toast.POSITION.TOP_CENTER,
            });
            console.error('Error during database backup:', error);
          })
          .finally(() => {
            // Reset loading state to enable the button
            setIsExcelBackupLoading(false);
        });
    };

    const handleCSVBackupDatabase = async () => {
        // Set loading state
        setIsCSVBackupLoading(true);
    
        // Make a POST request to the /backup route when the button is clicked
        await fetch(`${call}/backup-to-csv`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((result) => {
            // Check if the backup was successful
            if (result.message === 'Backup initiated successfully.') {
              // Display a success toast notification
              toast.success('Database backup successful!', {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              // Display an error toast notification
              toast.error('Database backup failed!', {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            console.log('Backup Database Result:', result);
          })
          .catch((error) => {
            // Display an error toast notification for network errors
            toast.error('Error during database backup', {
              position: toast.POSITION.TOP_CENTER,
            });
            console.error('Error during database backup:', error);
          })
          .finally(() => {
            // Reset loading state to enable the button
            setIsCSVBackupLoading(false);
        });
    };

    const handleSnapshotBackupDatabase = async () => {
        // Set loading state
        setIsSnapshotBackupLoading(true);
    
        // Make a POST request to the /backup route when the button is clicked
        await fetch(`${call}/backup-to-snapshot`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((result) => {
            // Check if the backup was successful
            if (result.message === 'Backup initiated successfully.') {
              // Display a success toast notification
              toast.success('Database backup successful!', {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              // Display an error toast notification
              toast.error('Database backup failed!', {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            console.log('Backup Database Result:', result);
          })
          .catch((error) => {
            // Display an error toast notification for network errors
            toast.error('Error during database backup', {
              position: toast.POSITION.TOP_CENTER,
            });
            console.error('Error during database backup:', error);
          })
          .finally(() => {
            // Reset loading state to enable the button
            setIsSnapshotBackupLoading(false);
        });
    };

    return (
        <div>
            <ToastContainer />
            <div ref={contentRef} style={{ textAlign: 'left', margin: '20px' }}>
                
            <h1 style={{ marginTop: '27px',  fontSize: '40px', fontWeight: 'bold', color: '#87D3D7'}}>System Dashboard</h1>

            <button onClick = {handleSpotCheck}
                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
            >
                Update SpotCheck
            </button>

            <button
                onClick={handleBackupDatabase}
                disabled={isBackupLoading}
                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
            >
                {isBackupLoading ? 'Backing up...' : 'Backup Database'}
            </button>

            <button
                onClick={handleExcelBackupDatabase}
                disabled={isExcelBackupLoading}
                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
            >
                {isExcelBackupLoading ? 'Excel Backing up...' : 'Excel Backup Database'}
            </button>

            <button
                onClick={handleSnapshotBackupDatabase}
                disabled={isSnapshotBackupLoading}
                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
            >
                {isSnapshotBackupLoading ? 'Snapshot Backing up...' : 'Snapshot Backup Database'}
            </button>

            <button
                onClick={handleCSVBackupDatabase}
                disabled={isCSVBackupLoading}
                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
            >
                {isCSVBackupLoading ? 'CSV Backing up...' : 'CSV Backup Database'}
            </button>

            <br/>
            <br/>

            <select id="location" value={selectedLocation} 
                onChange={(e) => setSelectedLocation(e.target.value)}
                style={{padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
                >
                    <option value="">All Locations</option>
                    {locations.sort().map((item, index) => (
                        <option key={index} value={item.location_name}>{item.location_name}</option>
                    ))}
            </select>

            <select value={sortOrderDate} 
                onChange={(e) => setSortOrderDate(e.target.value)}
                style={{marginLeft: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
            </select>


                <br/>
                <br/>
                
                <h3>Full Loop Tracking</h3>

                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', // Set a fixed height
                    minHeight: '210px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' // Ensure positioning context
                    }}>
                        <thead style={{
                            position: 'sticky', // Stick the header
                            top: 0, // Stick to the top of the table
                            backgroundColor: '#ffffff', // Background to cover underlying rows
                        }}>
                            <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Location</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Date</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Snapshot Backup</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>CSV Backup</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Backup</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Update Shipped Orders</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Flags & Scoring</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Invoices</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Mapping</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Zenoti Pull</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Service Update</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Notifications</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Recommendations</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>SpotCheck</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Outlook</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Mapping Errors</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedLoopStatus.map((item, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{item.location_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{formatDateText(item.date_time)}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.snapshot_backup_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.csv_backup_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.backup_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.shipped_update_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.flags_and_scoring_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.invoice_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.mapping_update_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.zenoti_pull_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.update_service_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.notifications_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.recommendations_push_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.spotcheck_push_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.outlook_subscriptions_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'center' }}>
                                        <img src={item.spotcheck_mapping_status ? tick : cross} alt="status" style={{ height: '20px', width: '20px', margin: 'auto' }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <h3>Errors Appointments</h3>
                
                <select value={selectedService} 
                    onChange={(e) => setSelectedService(e.target.value)}
                    style={{marginTop: '-16px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
                >
                    <option value="">All Services</option>
                    {/* Populate the select options with unique service names */}
                    {Array.from(new Set(filterErrorCounts.map(error => error.service_name))).map((service, index) => (
                        <option key={index} value={service}>{service}</option>
                    ))}
                </select>

                <br/>
                <br/>


                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', // Set a fixed height
                    minHeight: '210px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' 
                    }}>
                        <thead style={{
                            position: 'sticky', 
                            top: 0, 
                            backgroundColor: '#ffffff',
                            fontSize: '15px',
                        }}>
                            <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Location</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Last Update</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Service Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Error Message</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Error Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedErrorsDate.map((error, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{error.location_name}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{formatDateText(error.last_update)}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{error.service_name}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{error.error_insert_message}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{error.error_count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <h3>Flags</h3>

                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', // Set a fixed height
                    minHeight: '210px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' 
                    }}>
                        <thead style={{
                            position: 'sticky', 
                            top: 0, 
                            backgroundColor: '#ffffff',
                            fontSize: '15px',
                        }}>
                            <tr style={{ borderBottom: '2px solid black' }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Location</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Date Time</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Origin</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product ID</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Quantity Before</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Quantity After</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Delta</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedFilteredFlagsData.map((item, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{item.location_name}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{formatDateText(item.date_time)}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{item.origin}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{item.product_name}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{item.product_id}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{parseFloat(item.quantity_before).toFixed(4)}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{parseFloat(item.quantity_after).toFixed(4)}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{parseFloat(item.delta).toFixed(4)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <h3>Service Mapping</h3>

                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', // Set a fixed height
                    minHeight: '210px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' 
                    }}>
                        <thead style={{
                            position: 'sticky', 
                            top: 0, 
                            backgroundColor: '#ffffff',
                            fontSize: '15px',
                        }}>
                            <tr style={{ borderBottom: '2px solid black' }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Location Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Service Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Service ID</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product ID</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Quantity Before</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Quantity After</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Delta Quantity</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Delta Dates</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Latest Update</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedFilteredServiceTrackingInfo.map((item, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{item.location_name}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{item.service_name}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{item.service_id}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{item.product_name}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{item.product_id}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{parseFloat(item.usage_quantity_before).toFixed(4)}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{parseFloat(item.usage_quantity_after).toFixed(4)}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{parseFloat(item.delta).toFixed(4)}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{item.delta_date}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{formatDateText(item.last_date)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <h3>Products without Price</h3>
                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', // Set a fixed height
                    minHeight: '210px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#ffffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' // Ensure positioning context
                    }}>
                        <thead style={{
                            position: 'sticky', // Stick the header
                            top: 0, // Stick to the top of the table
                            backgroundColor: '#ffffff', // Background to cover underlying rows
                        }}>
                            <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product ID</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Description</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Manufacturer</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productsWithoutPrice.map((product, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{product.product_id}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{product.product_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{product.product_description}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{product.manufacturer_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{product.product_cat}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <h3>Products with Zero Price</h3>
                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px',
                    minHeight: '210px',
                    overflowY: 'auto',
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative'
                    }}>
                        <thead style={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: '#ffffff',
                        }}>
                            <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000' }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product ID</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Description</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Manufacturer</th>
                            </tr>
                        </thead>
                        <tbody>
                            {zeroPriceProducts.map((product, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{product.product_id}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{product.product_name}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{product.product_description}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{product.manufacturer_name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <h3>Products with Quantity Conversion Factor of 0</h3>
                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', 
                    minHeight: '210px', 
                    overflowY: 'auto', 
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' 
                    }}>
                        <thead style={{
                            position: 'sticky', 
                            top: 0, 
                            backgroundColor: '#ffffff',
                            fontSize: '15px',
                        }}>
                            <tr style={{ borderBottom: '2px solid black' }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product ID</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product Description</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Manufacturer Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Quantity Conversion</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productsQC.map((product, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{product.product_id}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{product.product_name}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{product.product_description}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{product.manufacturer_name}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{product.quantity_conversion}</td>
                                    <td style={{ padding: '5px', textAlign: 'left' }}>{product.product_cat}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <h3>Remaining Orders</h3>
                

                <select id="location" value={selectedOrderStatus} 
                    onChange={(e) => setSelectedOrderStatus(e.target.value)}
                    style={{marginTop: '-16px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
                    >
                        <option value="">All Status</option>
                        {Array.from(new Set(filteredOrders.map(item => item.status_cat))).map(status_cat => (
                            <option key={status_cat} value={status_cat}>{status_cat}</option>
                        ))}
                </select>
                <br/>
                <br/>
                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', // Set a fixed height
                    minHeight: '210px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' // Ensure positioning context
                    }}>
                        <thead style={{
                            position: 'sticky', // Stick the header
                            top: 0, // Stick to the top of the table
                            backgroundColor: '#ffffff', // Background to cover underlying rows
                        }}>
                            <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Location Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Supplier Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Manufacturer Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Order Quantity</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Unit Price</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Status Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredStatusOrders.map((order, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{order.location_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{order.product_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{order.supplier_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{order.manufacturer_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{order.order_quantity}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{order.unit_price}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{order.status_cat}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <h3>Notifications sent</h3>
                
                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', // Set a fixed height
                    minHeight: '210px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' // Ensure positioning context
                    }}>
                        <thead style={{
                            position: 'sticky', // Stick the header
                            top: 0, // Stick to the top of the table
                            backgroundColor: '#ffffff', // Background to cover underlying rows
                        }}>
                            <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Location</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Average Number per User</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedFilteredEmailsSent.map((email, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{email.location_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{email.emails_per_user}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left'  }}>{formatDateText(email.sent_date)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <h3>SpotCheck Accuracy</h3>

                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', // Set a fixed height
                    minHeight: '210px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' // Ensure positioning context
                    }}>
                        <thead style={{
                            position: 'sticky', // Stick the header
                            top: 0, // Stick to the top of the table
                            backgroundColor: '#ffffff', // Background to cover underlying rows
                        }}>
                            <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Location Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Spotcheck Flag Percentage</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Spotcheck Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredSpotcheckStats.map((stats, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left' }}>{stats.location_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left' }}>{(stats.spotcheck_flag_percentage * 100).toFixed(2)}%</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left' }}>{(stats.spotcheck_percentage * 100).toFixed(2)}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <h3>Recommendations Stats</h3>

                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', // Set a fixed height
                    minHeight: '210px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' // Ensure positioning context
                    }}>
                        <thead style={{
                            position: 'sticky', // Stick the header
                            top: 0, // Stick to the top of the table
                            backgroundColor: '#ffffff', // Background to cover underlying rows
                        }}>
                            <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Location Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Acceptance Rate</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Declined Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRecommendationStats.map((stats, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left' }}>{stats.location_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left' }}>{(stats.recommend_to_open * 100).toFixed(2)}%</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left' }}>{(stats.recommend_to_declined * 100).toFixed(2)}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <h3>Old Spotchecks</h3>

                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '210px', // Set a fixed height
                    minHeight: '210px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{  
                        backgroundColor: '#fffff', 
                        width: '100%', 
                        borderCollapse: 'separate', 
                        borderSpacing: 0, 
                        position: 'relative' // Ensure positioning context
                    }}>
                        <thead style={{
                            position: 'sticky', // Stick the header
                            top: 0, // Stick to the top of the table
                            backgroundColor: '#ffffff', // Background to cover underlying rows
                        }}>
                            <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Location Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product ID</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Product Name</th>
                                <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'left' }}>Last Spotcheck</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedFilteredProductsSpotcheck.map((stats, index) => (
                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left' }}>{stats.location_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left' }}>{stats.product_id}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left' }}>{stats.product_name}</td>
                                    <td className='table-row' style={{ padding: '5px', textAlign: 'left' }}>{formatDateText(stats.spotcheck_date)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default System;
