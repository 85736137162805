import React from 'react';
import Modal from 'react-modal';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
    return (
        <Modal
            overlayClassName="modal-overlay"
            className="modal-content"
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2,
                },
                content: {
                    width: '20%',
                    height: '15%',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'rgba(251, 251, 251)', // Set the background color to white
                    
                    textAlign: 'center'
                }
            }}
        >
            <p>{message}</p>
            <button 
                onClick={() => {
                    onConfirm(true);
                    onClose();
                }}
                className='support-button'

                style={{ 
                    fontSize: 'small'
                }}
            >
                Yes
            </button>
            <button 
                onClick={() => {
                    onConfirm(false);
                    onClose();
                }}
                className='support-button'
                style={{ 
                    marginLeft: '10px', 
                    fontSize: 'small'
                }}
            >
                No
            </button>
        </Modal>
    );
};

export default ConfirmationModal;