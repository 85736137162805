import Modal from 'react-modal';

const RetailModal = ({isOpen, setIsOpen, onClose, retailTitle, retailMessage, }) => {

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    };

    return (
        <Modal className="white-modal" overlayClassName="white-modal-overlay" isOpen={isOpen}
            onRequestClose={handleClose}
        >
            <div style={{
                display: 'flex', // Use flexbox
                justifyContent: 'center', // Center horizontally in the flex container
                width: '100%' // Take up the full width to allow centering
            }}>
                <h2 style={{textAlign: 'center', marginTop: '0px', marginBottom: '0px', padding: '20px'}}>{retailTitle}</h2>
            </div>
            <div style={{flex: '0 1 auto', borderBottom: '1px solid #ccc'}} />

            <div style={{
                textAlign: 'center',
                padding: '20px'
            }}>
                <h3 style={{textAlign: 'center', fontWeight: 'normal', margin: '20px'}}>{retailMessage}</h3>
            </div>

            <div style={{
                display: 'flex', // Use flexbox
                justifyContent: 'center', // Center horizontally in the flex container
                width: '100%',
                marginBottom: '20px'
            }}>
                <button onClick={handleClose} className='support-button' >
                    <span style={{color: 'white', fontWeight: 'normal'}}>Close</span>
                </button>
            </div>
        </Modal>
    );
};

export default RetailModal