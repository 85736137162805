// Formats the error description based on error category.
export const formatErrorDescription = (item, errorType) => {
    let description = ''
    if (errorType === 'Unmapped Product'){
        description = `${item.product_name} (product_id ${item.product_id}) ${item.product_description ? ` - ${item.product_description}` : ''} in ${item.product_cat} cateogry from manufacturer ${item.manufacturer_name} is not mapped to a service.`
    }
    else if (errorType === 'Unmapped Service') {
        description = `${item.service_name} (service_id ${item.service_id}) in ${item.service_cat} category is not mapped to a product.`
    }
    else if (errorType === 'Outlier') {
        description = `${item.product_name} (product_id ${item.product_id}) for ${item.service_name} (service_id ${item.service_id}) with usage quantity ${item.usage_quantity} ${item.origin !== null ? item.origin.split(':')[1] : ''}.`
    }
    else if (errorType === 'Unknown Service') {
        description = `${item.error_insert_message}  ${item.service_exists ? 'Found at other locations.' : ''} (${item.occurrences} times)`

    }
    else if (errorType === 'Discontinued Product') {
        description = `${item.product_name} (product_id ${item.product_id}) ${item.product_description ? ` - ${item.product_description}` : ''} is discontinued and was last ordered on ${item.recent_order.split('T')[0]}.`
    }
    else if (errorType === 'Manual Order') {
        description = `${item.product_name} (product_id ${item.product_id}) ${item.product_description ? ` - ${item.product_description}` : ''} was ${item.origin !== null ? item.origin.split(':')[1] :''}.`
    }
    return description
};

export const getTagStyle = (category) => {
    let color = 'grey';
    switch (category) {
        case 'Error':
            color = '#EB4F33'; 
            break;
        case 'Alert':
            color = 'orange';
            break;
        case 'Anomaly':
            color = '#EBC300';
            break;
        default:
            color = 'grey'
            break;
    }
    return { 
            backgroundColor: color,
            color: 'white', 
            padding: '5px 10px', 
            borderRadius: '25px', 
            display: 'inline-flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            width: '100px',
            height: '25px',
            textAlign: 'center',
            lineHeight: '30px'
    };
};

export const getPercentageChange = (value, data, id) => {
    return data[id] ? ((value / (data[id].total / data[id].count) - 1) * 100).toFixed(2) : null
}