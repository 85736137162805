import Fuse from 'fuse.js';

export const fuzzySearch = (list, query, fields, threshold) => {
    if (!query) return list;
    if (!threshold) {
        threshold = .3;
    }
    const options = {
        includeScore: true,
        shouldSort: true,
        findAllMatches: true,
        threshold: threshold,
        keys: fields,
        ignoreLocation: true,
    };
    const fuse = new Fuse(list, options);
    const result = fuse.search(query);
    return result.map(item => item.item);
};