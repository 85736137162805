import React, {useState } from 'react';
import { getTagStyle } from '../../utils/mapping/Format';

export const ErrorTable = ({errors, timeframeCallback}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedErrorCategory, setSelectedErrorCategory] = useState('');
    const [selectedErrorSubCategory, setSelectedErrorSubCategory] = useState('');
    const [selectedTimeframe, setSelectedTimeframe] = useState('');
    const [errorOccurrences, setErrorOccurrences] = useState(0);
    const [existingServiceCheckbox, setExistingServiceCheckbox] = useState(false);


    const filteredErrors = errors.filter(
        error => 
            (selectedErrorCategory ? error.category === selectedErrorCategory : true) &&(selectedErrorSubCategory ? error.sub_category === selectedErrorSubCategory : true) 
    ).filter(
        error => error.sub_category === 'Unknown Service' ? (error.occurrences >= errorOccurrences) && (existingServiceCheckbox ? error.service_exists : true) : true
    )
    .filter(
        error => `${error.category} ${error.sub_category} ${error.description}`.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const errorCategories = Array.from(new Set(errors.map(error => error.category)));

    const errorSubCategories = Array.from(new Set(errors.filter(error => (selectedErrorCategory ? error.category === selectedErrorCategory : true)).map(error => error.sub_category)));

    const disableTrackingOptions = !((selectedErrorSubCategory === 'Unknown Service') || (selectedErrorCategory === 'Error' && (selectedErrorSubCategory === 'Unknown Service')) || (!selectedErrorCategory && !selectedErrorSubCategory))
    
    const handleTimeframeChange = (e) => {
        const value = e.target.value;
        setSelectedTimeframe(value);
        timeframeCallback(value);
    };
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row',
                        alignItems: 'center',
                        }}>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '25%'}}
                />
                <select id="selectError" value={selectedErrorCategory} onChange={e => setSelectedErrorCategory(e.target.value)} // jumpy
                    style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '20%'}}
                >
                    <option value="">All Categories</option>
                    
                        {errorCategories.sort().map(cat => (
                        <option key={cat} value={cat}>
                            {cat}
                        </option>
                    ))}
                </select>
                <select id="selectSubError" value={selectedErrorSubCategory} onChange={e => setSelectedErrorSubCategory(e.target.value)} // jumpy
                    style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '20%'}}
                >
                    <option value="">All Subcategories</option>
                    
                        {errorSubCategories.sort().map(subcat => (
                        <option key={subcat} value={subcat}>
                            {subcat}
                        </option>
                    ))}
                </select>
                <select id="selectTime" value={selectedTimeframe} onChange={handleTimeframeChange}
                    style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '15%'}}
                    disabled={disableTrackingOptions}
                >          
                        <option value="">All Tracking Errors</option>

                        {[{key:'1 WEEK', value: 'Last Week'},{key:'1 MONTH', value: 'Last Month'}, {key:'3 MONTH', value: 'Last 3 Months'}, {key:'1 YEAR', value: 'Last Year'}].map((option, index) => (
                        <option key={index} value={option.key}>
                            {option.value}
                        </option>
                    ))}
                </select>
                <label  htmlFor="errorOccurrences" style={{ width: '10%', color: disableTrackingOptions ? 'gray' : 'black' }}>Tracking Error Occurrences</label>
                <input disabled={disableTrackingOptions} style={{marginRight: '20px', width: '5%'}} type="number" step="5" id="errorOccurrences" name="errorOccurrences" value={errorOccurrences} onChange={(e) => setErrorOccurrences(e.target.value)} required />
                <label htmlFor="existingServiceCheckbox" style={{ width: '10%', color: disableTrackingOptions ? 'gray' : 'black' }}>Show Existing Only</label> 
                <input
                    type="checkbox"
                    id="existingServiceCheckbox"
                    checked={existingServiceCheckbox}
                    onChange={() => {setExistingServiceCheckbox(!existingServiceCheckbox)}}
                    disabled={disableTrackingOptions}
                />
            </div>
                
            <br/>
            <br/>
            <div style={{ 
                border: '2px solid #87D3D7', 
                display: 'flex', 
                justifyContent: 'left', 
                flexDirection: 'column', 
                width: '100%', 
                maxHeight: '610px', // Set a fixed height
                overflowY: 'auto', // Enable vertical scrolling
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <table style={{  
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0, 
                    position: 'relative' // Ensure positioning context
                }}>
                    <thead style={{
                        position: 'sticky', // Stick the header
                        top: 0, // Stick to the top of the table
                        zIndex: 1 // Ensure it stays above the tbody rows
                    }}>
                        <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', width: '5%'  }}>Category</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%'  }}>Subcategories</th>
                            <th style={{  borderBottom:'2px solid black', padding: '10px', width: '70%' }}>Description</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>Resolve</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredErrors.map((item, index) => (
                            <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                                <td style={{ padding: '10px', }}>                        
                                    <span style={getTagStyle(item.category)}>{item.category}</span>
                                </td>
                                <td style={{padding: '10px'  }}>{item.sub_category}</td>
                                <td style={{padding: '10px'  }}>{item.description}</td>
                                
                                <td style={{ padding: '10px' , textAlign: 'center'}}>
                                    <button 
                                        onClick={async () =>{
                                            setSearchTerm('');
                                            item.callBack(item)
                                        }}
                                        style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                    >
                                        Resolve
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>    
        </>           
    );
}
