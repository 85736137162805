
export const declineOrder = async (selectedOrderId, declineReason, username) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/decline-order/${ selectedOrderId }`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({isAccepted: false, declineReason, username}),
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve orders to check.');
        }
        return true;
    } catch (error) {
        console.error('Error fetching orders to check:', error.message);
        return false;
    }
};

export const acceptOrderRecommendation = async ({orderId, orderQuantity, orderComment, username, orderUrgency, locationName, selectedProduct}) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/accept-order/${ orderId }`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username, orderQuantity, old_order_quantity: selectedProduct.order_quantity, orderComment, username, orderUrgency, product_id: selectedProduct.product_id, manufacturer_name: selectedProduct.manufacturer_name, locationName}),
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve orders to check.');
        }
        return true;
    } catch (error) {
        console.error('Error fetching orders to check:', error.message);
        return false;
    }
};

export const checkCurrentOrders = async (locationName, productId) => {

    // First, check the order status
    const statusResponse = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/check-order-status`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({location_name: locationName, product_id: productId}),
    });

    if (!statusResponse.ok) {
        const errorText = await statusResponse.text();
        console.error(`API request error: ${ errorText }`);
        return false;
    }

    const statusResult = await statusResponse.json();
    if (statusResult.exists) {
        const confirmMessage = `There is already an order in ${ statusResult.status_cat } status. Are you sure you want to place the order?`;
        const userConfirmed = window.confirm(confirmMessage);

        if (!userConfirmed) {
            return false;
        }
    }

    return true;
};

export const setManagerReview = async ({orderId, isReviewed, signal}) => {
    try { 
        // First, check the order status
        const statusResponse = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/update-order-review`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({order_id: orderId, is_reviewed: isReviewed}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!statusResponse.ok) {
            const errorText = await statusResponse.text();
            console.error(`API request error: ${ errorText }`);
            return false;
        }

        return true;

    } catch (error) {
        // Log any error that occurs during the fetch
        if (error.name !== 'AbortError') {
            console.error('Error fetching check deliveries:', error);
        }
        return false;  // Return null in case of an error
    }
}

export const updateOrderRecommendation = async ({orderId, orderQuantity, orderUrgency, orderComment, signal}) => {
    try {
        const requestData = {
            order_id: orderId,
            order_quantity: orderQuantity,
            order_urgency: orderUrgency,
            order_comment: orderComment
        }
        // First, check the order status
        const statusResponse = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/update-order-recommendation`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!statusResponse.ok) {
            const errorText = await statusResponse.text();
            console.error(`API request error: ${ errorText }`);
            return false;
        }

        return true;

    } catch (error) {
        // Log any error that occurs during the fetch
        if (error.name !== 'AbortError') {
            console.error('Error fetching check deliveries:', error);
        }
        return false;  // Return null in case of an error
    }
}

export const updateOrderDetails = async (originalOrder, updatedOrder, username) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/edit-order`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                orderId: originalOrder.order_id,
                orderquantity: updatedOrder.order_quantity,
                orderurgency: updatedOrder.order_urgency,
                ordercomment: updatedOrder.client_comment,
                orderdata: originalOrder,
                username: username
            }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log('Order updated successfully:', data);
            return data;
        } else {
            console.error('Failed to update order');
            return null;
        }
    } catch (error) {
        console.error('Error updating order:', error);
        return null;
    }
};