import React from 'react';

const NoActionFlow = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '70vh',
            padding: '20px', 
            overflow: 'auto'
        }}>          
            <p>
                No Action / Flow can be performed. Please break the orders needed in a new bundle.
            </p>
        </div>
    );
};

export default NoActionFlow;
