import React from 'react';
import OpenFlow from './OpenFlow';
import PlacedFlow from './PlacedFlow';
import ConfirmedFlow from './ConfirmedFlow';
import ShippedFlow from './ShippedFlow';
import InReviewFlow from './InReviewFlow';
import NoActionFlow from './NoActionFlow';

import loadingSpinner from '../../assets/images/loading.gif';

const OperationFlow = ({ status, locationName, ticketInfo, setTicketInfo, username, setTickets, onClose, emailConversation, setEmailConversation, emailID, locationNameSelected }) => {

    let emailConversationProcessed = emailConversation;

    if (ticketInfo.order_option !== 'email'){
        emailConversationProcessed = {
            lastMessage: {
              body: "",
              from: "",
              sent_date: null,
              subject: "",
              message_id: "",
              to_recipients: [],
              cc_recipients: []
            }
          };
    }

    function isInReviewOrderProcessed(orders) {

        for (const order of orders) {

          if (order.product_cat === 'web') {
            return false;
          }
        }
        return true;
      }

    // Function to render the correct component based on the status
    const renderFlowComponent = () => {

        if (ticketInfo.bundle_id <= 0){
            return <NoActionFlow />
        }

        switch (status) {
            case 'In Review':
                if (isInReviewOrderProcessed(ticketInfo.orders)){
                    return <OpenFlow locationName={locationName} ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} username={username} setTickets={setTickets} onClose={onClose} locationNameSelected={locationNameSelected} />;
                } else {
                    return <InReviewFlow />;
                }
            case 'Open':
                return <OpenFlow locationName={locationName} ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} username={username} setTickets={setTickets} onClose={onClose} locationNameSelected={locationNameSelected} />;
            case 'Placed':
                return (
                <>
                    {!emailConversationProcessed ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',  // Center horizontally
                                alignItems: 'center',      // Center vertically
                                flexDirection: 'column',   // Stack items vertically
                                height: '70%'
                            }}
                        >
                            <img
                                src={loadingSpinner}
                                alt="loading-spinner"
                                style={{
                                    height: '20px',
                                    marginBottom: '10px'  // Space below the spinner if needed
                                }}
                            />
                        </div>
                    ) : (
                        <>
                            {(emailConversationProcessed.error && ticketInfo.order_option === 'email') ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',  // Center horizontally
                                        alignItems: 'center',      // Center vertically
                                        flexDirection: 'column',   // Stack items vertically
                                        height: '70%'
                                    }}
                                >
                                    No email found.
                                </div>
                            ) : (
                                <PlacedFlow ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} onClose={onClose} origin={ticketInfo.order_option} emailConversation={emailConversationProcessed} setEmailConversation={setEmailConversation} emailID={emailID} locationNameSelected={locationNameSelected} setTickets={setTickets} username={username} />
                            )}
                        </>
                    )}
                </>
                );
            case 'Confirmed':
                return (
                    <>
                        {!emailConversationProcessed ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',  // Center horizontally
                                    alignItems: 'center',      // Center vertically
                                    flexDirection: 'column',   // Stack items vertically
                                    height: '70%'
                                }}
                            >
                                <img
                                    src={loadingSpinner}
                                    alt="loading-spinner"
                                    style={{
                                        height: '20px',
                                        marginBottom: '10px'  // Space below the spinner if needed
                                    }}
                                />
                            </div>
                        ) : (
                            <>
                                {(emailConversationProcessed.error && ticketInfo.order_option === 'email') ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',  // Center horizontally
                                            alignItems: 'center',      // Center vertically
                                            flexDirection: 'column',   // Stack items vertically
                                            height: '70%'
                                        }}
                                    >
                                        No email found.
                                    </div>
                                ) : (
                                    <ConfirmedFlow ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} onClose={onClose} origin={ticketInfo.order_option} emailConversation={emailConversationProcessed} setEmailConversation={setEmailConversation} emailID={emailID} locationNameSelected={locationNameSelected} setTickets={setTickets} username={username} isBackOrder={false} />
                                )}
                            </>
                        )}
                    </>);
            case 'Backorder':
                return (
                    <>
                        {!emailConversationProcessed ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',  // Center horizontally
                                    alignItems: 'center',      // Center vertically
                                    flexDirection: 'column',   // Stack items vertically
                                    height: '70%'
                                }}
                            >
                                <img
                                    src={loadingSpinner}
                                    alt="loading-spinner"
                                    style={{
                                        height: '20px',
                                        marginBottom: '10px'  // Space below the spinner if needed
                                    }}
                                />
                            </div>
                        ) : (
                            <>
                                {(emailConversationProcessed.error && ticketInfo.order_option === 'email') ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',  // Center horizontally
                                            alignItems: 'center',      // Center vertically
                                            flexDirection: 'column',   // Stack items vertically
                                            height: '70%'
                                        }}
                                    >
                                        No email found.
                                    </div>
                                ) : (
                                    <ConfirmedFlow ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} onClose={onClose} origin={ticketInfo.order_option} emailConversation={emailConversationProcessed} setEmailConversation={setEmailConversation} emailID={emailID} locationNameSelected={locationNameSelected} setTickets={setTickets} username={username} isBackOrder={true} />
                                )}
                            </>
                        )}
                    </>);
            case 'Shipped':
                return <ShippedFlow ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} onClose={onClose} origin={ticketInfo.order_option} locationNameSelected={locationNameSelected} setTickets={setTickets} username={username} />;
            default:
                return <div>Unknown Status</div>;
        }
    };

    return (
        <div>
            {renderFlowComponent()}
        </div>
    );
};

export default OperationFlow;