import React, {useState } from 'react';

export const ProductTable = ({products, onClick, onlySearch=false}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [selectedProductCategory, setSelectedProductCategory] = useState('');
    const uniqueManufacturers = Array.from(new Set(products.map(item => item.manufacturer_name)));
    const uniqueProductCategories = Array.from(new Set(products.map(item => item.product_cat)));

    const filteredProducts = products.filter(
        item =>
        item.product_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedProductCategory === '' || item.product_cat.toLowerCase() === selectedProductCategory.toLowerCase()) &&
        (selectedManufacturer === '' || item.manufacturer_name.toLowerCase() === selectedManufacturer.toLowerCase())    );  
    return (
        <>
            <div>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px'}}
                />
                {onlySearch ?  <></> :
                    <>
                        <select
                            value={selectedProductCategory}
                            onChange={(e) => setSelectedProductCategory(e.target.value)}

                            style={{ marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}

                            >
                            <option value="">All Categories</option>
                            {uniqueProductCategories.sort().map(product_cat => (
                                <option key={product_cat} value={product_cat}>
                                {product_cat}
                                </option>
                            ))}
                        </select>
                        <select
                            value={selectedManufacturer}
                            onChange={(e) => setSelectedManufacturer(e.target.value)}
                            style={{ padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
                            >
                            <option value="">All Manufacturers</option>
                            {uniqueManufacturers.sort().map(manufacturer => (
                                <option key={manufacturer} value={manufacturer}>
                                {manufacturer}
                                </option>
                            ))}
                        </select>
                    </>
                }
            </div>
            <br/>
            <br/>
            <div style={{ 
                border: '2px solid #87D3D7', 
                display: 'flex', 
                justifyContent: 'left', 
                flexDirection: 'column', 
                width: '100%', 
                maxHeight: '610px', // Set a fixed height
                overflowY: 'auto', // Enable vertical scrolling
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <table style={{  
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0, 
                    position: 'relative' // Ensure positioning context
                }}>
                    <thead style={{
                        position: 'sticky', // Stick the header
                        top: 0, // Stick to the top of the table
                        zIndex: 1 // Ensure it stays above the tbody rows
                    }}>
                        <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%'  }}>Product ID</th>
                            <th style={{  borderBottom:'2px solid black', padding: '10px', width: '20%' }}>Product Name</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%', textAlign: 'center' }}>Description</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>Manufacturer</th>
                            <th style={{  borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center'}}>Category</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>Add</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProducts.map((product, index) => (
                            <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                                <td style={{ padding: '10px', }}>{product.product_id}</td>
                                <td style={{  padding: '10px', }}>{product.product_name}</td>
                                <td style={{padding: '10px' ,textAlign: 'center' }}>{product.product_description}</td>
                                <td style={{ padding: '10px', textAlign: 'center'}}>{product.manufacturer_name}</td>
                                <td style={{  padding: '10px', textAlign: 'center'}}>{product.product_cat}</td>
                                <td style={{ padding: '10px' , textAlign: 'center'}}>
                                    <button 
                                        onClick={async () => onClick(product)}
                                        style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                    >
                                        Add
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
