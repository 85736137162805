// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";

import {EmailAuthProvider, getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAqHwtk-EH6tTZZ-TR3OHphXmmfZu_N7-Q",
    authDomain: "medvelle-e3552.firebaseapp.com",
    projectId: "medvelle-e3552",
    storageBucket: "medvelle-e3552.appspot.com",
    messagingSenderId: "493886758585",
    appId: "1:493886758585:web:1c3f400f194fbc3b6c8568",
    measurementId: "G-LJGX2BLD4C"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {auth};
