import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

/**
 * Sorts an array of objects based on the specified attribute.
 * @param {Array} array - The array of objects to sort.
 * @param {string} attribute - The attribute name to sort by.
 * @param {boolean} ascending - Whether to sort in ascending order (default is true).
 * @returns {Array} - The sorted array.
 */
export const sortArrayByAttribute = (array, attribute, ascending = true) => {
    // If no attribute is specified, return original array.
    if (!attribute) {
        return array;
    }
    return array.sort((a, b) => {
        let valueA;
        let valueB;

        if (attribute === 'inventory_quantity') {
            valueA = a.quantity_conversion !== 0
                ? a.inventory_quantity / a.quantity_conversion
                : a.inventory_quantity;
            valueB = b.quantity_conversion !== 0
                ? b.inventory_quantity / b.quantity_conversion
                : b.inventory_quantity;
        }
        else if (attribute === 'total') {
            valueA = a.unit_price * a.order_quantity;
            valueB = b.unit_price * b.order_quantity;
        }
        else {
            valueA = a[attribute];
            valueB = b[attribute];
        }


        // Handle numerical sorting
        if (typeof valueA === 'number' && typeof valueB === 'number') {
            return !ascending ? valueB - valueA : valueA - valueB;
        }

        // Handle string sorting
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            if (valueA < valueB) return ascending ? -1 : 1;
            if (valueA > valueB) return ascending ? 1 : -1;
            return 0;
        }

        // Default case (e.g., mixed types or undefined values)
        return 0;
    });
};

/**
 * Handles sorting logic when a column header is clicked.
 *
 * @param {string} attribute - The attribute name of the column that was clicked.
 * @param {string} sortAttribute - The current attribute that is being used to sort.
 * @param {function} setSortAttribute - Function to update the current sort attribute.
 * @param {boolean} sortAsc - The current sorting order (true for ascending, false for descending).
 * @param {function} setSortAsc - Function to update the sorting order.
 */
export const handleSortChange = (attribute, sortAttribute, setSortAttribute, sortAsc, setSortAsc) => {
    // New column clicked
    if (attribute !== sortAttribute) {
        setSortAttribute(attribute);
        setSortAsc(true);
        return;
    }

    // If descending, remove column sort
    if (!sortAsc) {
        setSortAttribute('');
        setSortAsc(true);
    } else {
        setSortAsc(false);
    }
};

/**
 * Returns the appropriate arrow icon based on the sorting state.
 *
 * @param {string} attribute - The attribute name of the column.
 * @param {string} sortAttribute - The current attribute that is being used to sort.
 * @param {boolean} sortAsc - The current sorting order (true for ascending, false for descending).
 * @returns {JSX.Element|string} - The arrow icon JSX element or an empty string if no sort is applied.
 */
export const getArrow = (attribute, sortAttribute, sortAsc) => {
    if (attribute === sortAttribute) {
        return sortAsc ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />;
    }
    return '';
};