import React, { useState, useEffect } from 'react';
import InfoPopup from '../../components/InfoPopup';
import { toast, ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import {fetchManagerReviewStatus, updateManagerReviewStatus} from '../../utils/locations/locationsUtils';

const ProfileSettings = ({locationName}) => {

    const [users, setUsers] = useState([]);
    const [orderThresholds, setOrderThresholds] = useState({});
    const [budget, setBudget] = useState('');
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [userExists, setUserExists] = useState(false);
    const [isManagerReviewed, setIsManagerReviewed] = useState(false)
    const user_email = localStorage.getItem('userEmail')
    const [newUser, setNewUser] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        phone: '',
        permission: '25', // Default to 'Staff'
    });
    const [deleteUser, setDeleteUser] = useState({});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const resetNewUserState = () => {
        setIsAddUserModalOpen(false);
        setNewUser({
          firstName: '',
          lastName: '',
          userName: '',
          email: '',
          phone: '',
          permission: '25',
        });
        
        setCurrentStep(1);
        setUserExists(false);
    };
  
    const steps = [
        { number: 1, title: "Enter User Email" },
        { number: 2, title: "User Exists" },
        { number: 3, title: "Enter User Details" },
        { number: 4, title: "Select Permission" }
    ];
    
    const [currentStep, setCurrentStep] = useState(1);
    
    const handleNextStep = (next = 1) => {
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + next);
        }
    };

    const submitEmail = async () => {

        // Check if the user is already in the system
        if (users.some(user => user.user_email === newUser.email)) {
            alert('This user is already in your system');
            return;
        }

        if (!newUser.email.includes('@')) {
            alert('Please enter a valid email address with "@"');
            return;
        }

        
        // Check if the user is marked as deleted
        const deletedResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/is-user-deleted`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: newUser.email }),
        });

        const deletedData = await deletedResponse.json();
        if (deletedData.isDeleted) {
            setIsAddUserModalOpen(true)
            handleNextStep(3);
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-email`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: newUser.email, location_name: locationName }),
        });
        
        const data = await response.json();
        if (data.exists) {
            setUserExists(true);
            setCurrentStep(2); // Proceed to step 2 (User Exists)
        } else {

            setUserExists(false);
            handleNextStep(2); // Proceed to step 3 (Enter User Details)
        }
    };
  
    const sendEmails = async (userEmail, firstName) => {
  
          // Step 1: Generate a random 8-letter password with capital letters and numbers
          const password = uuidv4().slice(0, 8).replace(/[^A-Z0-9]/gi, (char) => {
            const random = Math.random();
            return random < 0.5 ? char.toUpperCase() : Math.floor(random * 10);
          });
      
          try {
            // Step 2: Send the user email and password to Firebase
            const firebaseResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-user-firebase`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: userEmail,
                password,
              }),
            });
      
            if (!firebaseResponse.ok) {
              throw new Error('Failed to create user in Firebase');
            }
      
            // Step 3: Get the password reset link from the backend
            const resetLinkResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/generate-reset-link`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: userEmail }),
            });
      
            const { resetLink } = await resetLinkResponse.json();
            if (!resetLinkResponse.ok) {
              throw new Error('Failed to generate password reset link');
            }
  
            const text = `
  Dear ${firstName},
  
  Please find the login information for your Medvelle Account below:
    · Email: ${userEmail}
    · Temporary Password: ${password}
    · URL: https://app.medvelle.ai/
  
  Please use this link (${resetLink}) to reset your password.
  
  Please let us know if you have any trouble accessing the platform. We are always available to help troubleshoot and look forward to your comments and feedback.
  
  Best,
  
  Medvelle Team
            `;
      
            // Step 4: Send email via Outlook
            const outlookResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/send-outlook-email`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: userEmail,
                subject: 'Medvelle Account Information',
                body: text
              }),
            });
          
            if (!outlookResponse.ok) {
              throw new Error('Failed to send email');
            }
        } catch (error) {
            console.error(`Error sending email to ${userEmail}:`, error);
        }
        
    };

    const handleAddUser = () => {
        setIsAddUserModalOpen(true);
    };

    const handleAddUserChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prevState => ({ ...prevState, [name]: value.trim() }));
    };

    const submitExistingUser = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/add-existing-user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_email: newUser.email ,permission_code: newUser.permission, location_name: locationName }),
        });
      
        if (response.ok) {
          toast.success('User added successfully');
          resetNewUserState();
          fetch(`${process.env.REACT_APP_BACKEND_URL}/get-users`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName }),
          })
          .then(response => response.json())
          .then(data => {
            setUsers(data);
            const newThresholds = data.reduce((acc, user) => {
              acc[user.username] = user.order_approval_limit;
              return acc;
            }, {});
            setOrderThresholds(newThresholds);
            
          })
          .catch(error => console.error('Error fetching users:', error));
        } else {
          // toast.error('Failed to add user');
        }
    };
  
    const submitNewUser = async () => {
        const { userName, firstName, lastName, email, phone, permission } = newUser;
  
  
      
        // Check if all fields are filled
        if (!firstName || !lastName || !email || !phone || !permission || !userName) {
          alert('Please fill all fields before submitting.');
          return;
        }
  
      
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/add-user`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newUser, location_name: locationName }),
          });
      
          const responseData = await response.json();
      
          if (response.ok) {
            toast.success('User added successfully');
  
            sendEmails(email, firstName);
  
            resetNewUserState();
            const usersResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-users`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ location_name: locationName }),
            });
      
            const usersData = await usersResponse.json();
            setUsers(usersData);
            const newThresholds = usersData.reduce((acc, user) => {
              acc[user.username] = user.order_approval_limit;
              return acc;
            }, {});
            setOrderThresholds(newThresholds);
          } else {
            if (responseData.message === 'Username exists') {
              toast.error('Username already exists');
            } else {
              toast.error('Failed to add user');
            }
          }
        } catch (error) {
          console.error('Error adding user:', error);
          toast.error('An error occurred while adding the user');
        }
    };

    const fetchUsers = async (locationName, setUsers) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-users`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName }),
          });
          const data = await response.json();
          setUsers(data);
          const newThresholds = data.reduce((acc, user) => {
            acc[user.username] = user.order_approval_limit;
            return acc;
          }, {});
          setOrderThresholds(newThresholds);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
    };

    const handlePermissionChange = async (username, email, location_name, newPermission) => {

        if(email === user_email){
          alert('You can not change your own permission level, please contact Medvelle support for this change.')
          return;
        }
  
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-permission-level`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: username, location_name: location_name, permission_code: newPermission }),
        })
  
        if (response.ok) {
          toast.success('Permission level updated successfully')
          fetch(`${process.env.REACT_APP_BACKEND_URL}/get-users`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName }),
          })
          .then(response => response.json())
          .then(data => {
            setUsers(data);
          })
          .catch(error => console.error('Error fetching users:', error)); 
        }
  
    };
  
    const handleSetBudget = async () => {
        if (budget <= 0){
            alert("The budget has to be strictly positive.");
            return;
        }

        fetch(`${process.env.REACT_APP_BACKEND_URL}/set-budget`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName, budget: budget }),
        })

        toast.success('Monthly budget updated successfully');
    };

    const handleDeleteUser = (user) => {
        setDeleteUser(user);
        setIsDeleteModalOpen(true);
    };

    const handleGoBack = async () => {
        setIsDeleteModalOpen(false);
    };

    const confirmDelete = async () => {
        if(deleteUser.user_email === user_email){
        alert('You can not remove yourself from your platform, please contact Medvelle support for help.')
        return;
        }

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/remove-user`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({user: deleteUser}),
        });

        if (response.ok) {
        toast.success('User removed successfully')
        setIsDeleteModalOpen(false)
        fetch(`${process.env.REACT_APP_BACKEND_URL}/get-users`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName }),
        })
        .then(response => response.json())
        .then(data => {
            setUsers(data);
        })
        .catch(error => console.error('Error fetching users:', error)); 
        }
    };

    const handleInput = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };

    const handleUpdateOrderThreshold = async (username, location_name, newThreshold) => {
        if (newThreshold < 0) {
        toast.error('Please set a valid threshold');
        return;
        }

        try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-order-threshold`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, location_name, order_approval_limit: newThreshold }),
        });

        const data = await response.json();
        if (response.ok) {
            toast.success('Order Approval Threshold updated successfully');
            // Update local users state if necessary or refetch user data
        } else {
            throw new Error(data.message || 'Failed to update threshold');
        }
        } catch (error) {
        console.error('Error updating order threshold:', error);
        toast.error('An error occurred while updating the threshold');
        }
    };

    const fetchBudget = (setBudget) => fetch(`${process.env.REACT_APP_BACKEND_URL}/get-budget`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName }),
        })
        .then(response => response.json())
        .then(data => {
            setBudget(data.budget);
        })
        .catch(error => console.error('Error fetching budget:', error));

    useEffect(() => {

        fetchBudget(setBudget);
        fetchUsers(locationName, setUsers);
        fetchManagerReviewStatus({locationName, setIsManagerReviewed})

    }, [locationName]);

    const toggleRecommendationReview = async () => {
      const newReviewSetting = !isManagerReviewed
      const success = await updateManagerReviewStatus({locationName, isManagerReviewed: newReviewSetting})
      if(success) {
        setIsManagerReviewed(newReviewSetting)
      }
    }

    const filteredUsers = users.filter(user => !String(user.permission_code).startsWith('1'));

    const userPermissionLevels = [
      <p key="owner"><strong>Owner:</strong> Full platform access.</p>,
      <p key="manager"><strong>Manager:</strong> Reduced platform access without the ability to submit orders.</p>,
      <p key="staff"><strong>Staff:</strong> Ability to request orders and view the orders page.</p>,
      <p key="restricted-staff"><strong>Restricted Staff:</strong> Ability to request orders and view the orders page without pricing information.</p>,
      <p key="reporting"><strong>Reporting:</strong> Ability to view and generate reports.</p>,
      <p key="frontdesk"><strong>Frontdesk:</strong> Only access to SpotChecks (inventory counting).</p>
  ];
  
  const orderApprovalRules = [
      <p key="order-threshold">1. Order approval threshold restricts managers from accepting order recommendations that are valued above this threshold.</p>,
      <p key="order-approval">2. Orders placed by the manager valued above the threshold need to be approved by the owner.</p>
  ];

    return (
        <div>
            <div>
                <h1 style={{ marginTop: '-5px', display: 'flex', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7' }}>Manage Users</h1>

                <div style={{ 
                    border: '2px solid #87D3D7', 
                    display: 'flex', 
                    justifyContent: 'left', 
                    flexDirection: 'column', 
                    width: '100%', 
                    maxHeight: '350px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 20px 20px rgba(0, 0, 0, 0.1)',
                }}>

                <table style={{  
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0, 
                    position: 'relative' // Ensure positioning context
                }}>
                
                    <thead style={{
                      position: 'sticky', // Stick the header
                      top: 0, // Stick to the top of the table
                      zIndex: 0 // Ensure it stays above the tbody rows
                    }}>

                    <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>

                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%'  }}>Name</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%'  }}>Username</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '20%' }}>Email</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>
                        <div style={{display: 'flex', marginLeft: '38px' }}>
                            Permission Level
                            <InfoPopup title="User Permission Levels:" content={userPermissionLevels} />
                        </div>
                        </th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '20%',textAlign: 'center' }}>Status</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '20%', textAlign: 'center' }}>
                        <div style={{display: 'flex', marginLeft: '1px' }}>
                            Order Approval Threshold
                            <InfoPopup title="Order Approval Threshold:" content={orderApprovalRules} />                
                        </div>
                        </th>                  
                        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '20%', textAlign: 'center' }}>Remove User</th>

                    </tr>

                    </thead>

                    <tbody>
                    {filteredUsers.map((user,index) => (
                        <tr key={user.username} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >

                        <td style={{padding: '10px', }}>{user.first_name} {user.last_name}</td>
                        <td style={{padding: '10px', }}>{user.username}</td>
                        <td style={{padding: '10px', }}>{user.user_email}</td>
                        <td style={{ padding: '10px', textAlign: 'center' }}>
                            <select
                                value={user.permission_code}
                                onChange={(e) => handlePermissionChange(user.username, user.user_email, user.location_name, e.target.value)}
                                style={{  width: '150px', marginLeft: '10px' }}
                            >
                            <option value="21">Owner</option>
                            <option value="22">Manager</option>
                            <option value="25">Staff</option>
                            <option value="26">Restricted Staff</option>
                            <option value="24">Reporting</option>
                            <option value="23">Frontdesk</option>                        
                            </select>
                        </td>
                        <td style={{padding: '10px', textAlign: 'center'}}>{user.status}</td>
                        <td style={{padding: '10px', textAlign: 'left'}}>
                            {[22].includes(user.permission_code) ? (
                                <div style={{ display: 'flex', alignItems: 'left' }}>
                                <input
                                    type="number"
                                    value={orderThresholds[user.username]}
                                    onChange={(e) => setOrderThresholds(prev => ({ ...prev, [user.username]: e.target.value }))}
                                    style={{ width: '80px', marginRight: '10px'}}
                                />
                                <button
                                    onClick={() => handleUpdateOrderThreshold(user.username, user.location_name, orderThresholds[user.username] || user.order_approval_limit)}
                                    style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '5px 8px', cursor: 'pointer', borderRadius: '4px' }}
                                >Update</button>
                                </div>
                            ) : (
                                ''
                            )}
                        </td>

                        <td style={{ padding: '10px', textAlign: 'center'}}>    
                            <button onClick={() => handleDeleteUser(user)} style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>Remove</button>
                        </td>
                        
                        </tr>
                    ))}
                    </tbody>
                </table>
                </div>

                <button onClick={() => handleAddUser()} style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px', marginTop: '15px' }}>Add User</button>

            </div>
            
            <ToastContainer />
            
            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={isAddUserModalOpen}
                onRequestClose={() => resetNewUserState()}
                style={{
                    content: {
                    height: currentStep === 1 ? '220px' : 'auto', 
                    background: 'white',
                    border: '3px solid #87D3D7'
                    },
                }}
            >
                <h2 style={{marginTop: '-10px'}}>{steps[currentStep-1].title}</h2>
                {currentStep === 1 && (
                    <div className="input-group" style={{marginTop: '20px' }}>
                        <h3>Email</h3>
                        <input type="email" name="email" value={newUser.email} onChange={handleAddUserChange} />
                        <button 
                        type="button" 
                        onClick={submitEmail} 
                        style={{ border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px', position: 'absolute', bottom: 0, right: 0, margin: '20px', cursor: 'pointer', disabled: !newUser.email}}
                        disabled={!newUser.email}
                        >
                        Next
                        </button>
                    </div>
                )}

                {currentStep === 2 && (
                    <div className="input-group">
                        <p>This user already exists. Do you want to add this user to {locationName}?</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <button 
                            type="button" 
                            onClick={resetNewUserState} 
                            style={{ width: '20%', border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px', marginTop: '20px', cursor: 'pointer' }}
                        >
                            Cancel
                        </button>
                        <button 
                            type="button" 
                            onClick={() => handleNextStep(2)} 
                            style={{ width: '20%', border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px', marginTop: '20px', cursor: 'pointer' }}
                        >
                            Add
                        </button>
                        </div>
                    </div>

                )}

                {currentStep === 3 && !userExists && (
                    <form>
                        <div className="input-group" style={{marginBottom: '-10px'}}>
                        <h3>First Name</h3>
                        <input type="text" name="firstName" value={newUser.firstName} onChange={handleAddUserChange} />
                        </div>
                        <div className="input-group" style={{marginBottom: '-10px'}}>
                        <h3>Last Name</h3>
                        <input type="text" name="lastName" value={newUser.lastName} onChange={handleAddUserChange} />
                        </div>
                        <div className="input-group" style={{marginBottom: '-10px'}}>
                        <h3>Username</h3>
                        <input type="text" name="userName" value={newUser.userName} onChange={handleAddUserChange} />
                        </div>
                        <div className="input-group" style={{marginBottom: '-10px'}}>
                        <h3>Phone</h3>
                        <input
                            type="tel"
                            name="phone"
                            value={newUser.phone}
                            pattern="[0-9]*"
                            onChange={handleAddUserChange}
                            onInput={handleInput}
                        />            
                        </div>
                        <div className="input-group" style={{marginBottom: '-10px'}}>
                        <h3>Permission Level</h3>
                        <select name="permission" value={newUser.permission} onChange={handleAddUserChange}>
                            <option value="21">Owner</option>
                            <option value="22">Manager</option>
                            <option value="25">Staff</option>
                            <option value="26">Restricted Staff</option>
                            <option value="24">Reporting</option>
                            <option value="23">Frontdesk</option>
                        </select>
                        </div>
                        <br/>
                        <br/>
                        <button 
                        type="button" 
                        onClick={submitNewUser} 
                        style={{ 
                            width: '100px', marginRight: '29.5px', border: '3px solid #31bac1', borderRadius: '5px', color: 'white', backgroundColor: '#31bac1', padding: '5px', position: 'absolute', bottom: 0, right: 0, margin: '20px', cursor: 'pointer',
                            
                        }}

                        >
                        Submit
                        </button>
                    </form>
                )}

                {currentStep === 4 && (
                    <div className="input-group">
                        <h3>Permission Level</h3>
                        <select name="permission" value={newUser.permission} onChange={handleAddUserChange}>
                        <option value="21">Owner</option>
                        <option value="22">Manager</option>
                        <option value="25">Staff</option>
                        <option value="26">Restricted Staff</option>
                        <option value="24">Reporting</option>
                        <option value="23">Frontdesk</option>
                        </select>
                        <button 
                        type="button" 
                        onClick={submitExistingUser}
                        style={{ 
                            marginLeft: '305px' , width: '100px',border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px',  marginTop: '20px', cursor: 'pointer'
                        }}
                        disabled={!newUser.permission}
                        >
                        Submit
                        </button>
                    </div>
                )}

            </Modal>

            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={isDeleteModalOpen}
                onRequestClose={() => {
                    setIsDeleteModalOpen(false);
                }}
                style={{
                    content: {
                    height: '200px',
                    },
                    overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2,
                    },
                }}
            >
                <h2 style={{textAlign: 'center'}}>Attention</h2>
                
                <h3 style={{textAlign: 'center'}}>Are you sure you want to remove this user?</h3>
                <button onClick={handleGoBack} type="button" style={{marginLeft: '92px' , width: '100px',border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px', marginTop: '20px',  cursor: 'pointer'}}>Go Back</button>

                <button onClick={confirmDelete} type="button" style={{marginLeft: '20px' ,border: '3px solid #F49C7D', color: 'white', backgroundColor: '#F49C7D', padding: '5px', marginTop: '20px', cursor: 'pointer'}}>Confirm Remove</button>

            </Modal>

        </div>
    );
};

export default ProfileSettings;