import React, { useState, useEffect } from 'react';
import InfoPopup from '../../components/InfoPopup';
import { toast, ToastContainer } from 'react-toastify';
import { 
    saveIdealWeeks, 
    fetchIdealWeeks, 
    fetchConfidenceInterval, 
    fetchCustomWeeks, 
    updateInventoryLevelFetch, 
    updateIdealWeeksFetch, 
    updateCustomWeeks 
} from '../../utils/settings/settingsUtils'; // Adjust the path as necessary

const InventorySettings = ({locationName}) => {
    const [inventoryLevel, setInventoryLevel] = useState('');
    const [customToggle, setCustomToggle] = useState(false); 

    const [idealWeeks, setIdealWeeks] = useState({});

    let productCategories = [
        'Retail', 'Injectables', 'Non-Medical Consumables', 
        'Office Supplies', 'Medication', 'Medical Consumables', 
        'Wellness Consumables', 'Back Bar', 'Hydrafacial', 
        'Cosmetics', 'Geneo', 'Equipment', 'Threads'
    ];

    if (locationName.startsWith('HS')){
        productCategories = [
            'Non-Medical Consumables', 'Medical Consumables', 'Professional Products', 
            'Retail', 'Facial Retail', 'Candles', 
            'Testers', 'Body products', 'Employee Supplies', 
            'Supplies Massage', 'Others', 'Office Supplies'
        ];
    }
    
    let normalIdealWeeks = {
        "Retail": 8,
        "Injectables": 4,
        "Non-Medical Consumables": 8,
        "Office Supplies": 8,
        "Medication": 6,
        "Medical Consumables": 6,
        "Wellness Consumables": 6,
        "Back Bar": 8,
        "Hydrafacial": 8,
        "Cosmetics": 8,
        "Geneo": 8,
        "Equipment": 8,
        "Threads": 6
    };

    if (locationName.startsWith('HS')){
        normalIdealWeeks = {
            "Non-Medical Consumables": 6,
            "Medical Consumables": 8,
            "Professional Products": 5,
            "Retail": 8,
            "Facial Retail": 8,
            "Candles": 6,
            "Testers": 5,
            "Body products": 8,
            "Employee Supplies": 7,
            "Supplies Massage": 4,
            "Others": 5,
            "Office Supplies": 6
        }
    };

    useEffect(() => {

        // Call loadData with the desired locationName
        loadData(locationName);

    }, [locationName]);
    
    // Example usage
    const loadData = async (locationName) => {
        await fetchIdealWeeks(locationName, setIdealWeeks);
        await fetchConfidenceInterval(locationName, setInventoryLevel);
        await fetchCustomWeeks(locationName, setCustomToggle);
    };

    const toggleCustom = async () => {
        const newCustomToggle = !customToggle;
        setCustomToggle(newCustomToggle);
        
        const customWeeksValue = newCustomToggle ? 1 : 0;
    
        if (!newCustomToggle) {
            try {
                let adjustment = 0;
                let updatedIdealWeeks = {};
    
                if (inventoryLevel === 0.9) {
                    adjustment = 1; // Conservative
                } else if (inventoryLevel === 0.7) {
                    adjustment = -1; // Aggressive
                } else if (inventoryLevel === 0.8) {
                    updatedIdealWeeks = getUpdatedIdealWeeks(locationName);
                }
    
                if (inventoryLevel !== 0.8) {
                    updatedIdealWeeks = Object.fromEntries(
                        Object.entries(normalIdealWeeks).map(([category, weeks]) => [
                            category,
                            Math.max(0, Math.round(parseInt(weeks) + adjustment))
                        ])
                    );
                }
                setIdealWeeks(updatedIdealWeeks);
    
                // Call the API to update the ideal weeks
                await updateIdealWeeksFetch(locationName, updatedIdealWeeks);
            } catch (error) {
                console.error('Error fetching confidence interval:', error);
            }
        }
    
        // Call the API to update the custom weeks column
        const customWeeksResponse = await updateCustomWeeks(locationName, customWeeksValue);
        if (customWeeksResponse) {
            toast.success('Custom weeks setting updated successfully');
        } else {
            toast.error('Failed to update custom weeks setting');
        }
    
        // Call the API to update the inventory level if toggled back to default
        if (!newCustomToggle) {
            const inventoryLevelResponse = await updateInventoryLevelFetch(locationName, inventoryLevel);
            if (inventoryLevelResponse) {
                // Optionally handle success or failure
            }
        }
    };

    const getUpdatedIdealWeeks = (locationName) => {
        if (locationName.startsWith('HS')) {
            return {
                "Non-Medical Consumables": 6,
                "Medical Consumables": 8,
                "Professional Products": 5,
                "Retail": 8,
                "Facial Retail": 8,
                "Candles": 6,
                "Testers": 5,
                "Body products": 8,
                "Employee Supplies": 7,
                "Supplies Massage": 4,
                "Others": 5,
                "Office Supplies": 6
            };
        }
    
        return {
            "Retail": 8,
            "Injectables": 4,
            "Non-Medical Consumables": 8,
            "Office Supplies": 8,
            "Medication": 6,
            "Medical Consumables": 6,
            "Wellness Consumables": 6,
            "Back Bar": 8,
            "Hydrafacial": 8,
            "Cosmetics": 8,
            "Geneo": 8,
            "Equipment": 8,
            "Threads": 6
        };
    };

    const handleInventoryLevelChange = async (e) => {
        const newValue = parseFloat(e.target.value);
        setInventoryLevel(newValue);
    
        // Skip updating ideal weeks if customToggle is true
        if (!customToggle) {
            let adjustment = 0;
            let updatedIdealWeeks = {};
    
            if (newValue === 0.9) {
                adjustment = 1; // Conservative
            } else if (newValue === 0.7) {
                adjustment = -1; // Aggressive
            } else if (newValue === 0.8) {
                updatedIdealWeeks = {
                    "Retail": 8,
                    "Injectables": 4,
                    "Non-Medical Consumables": 8,
                    "Office Supplies": 8,
                    "Medication": 6,
                    "Medical Consumables": 6,
                    "Wellness Consumables": 6,
                    "Back Bar": 8,
                    "Hydrafacial": 8,
                    "Cosmetics": 8,
                    "Geneo": 8,
                    "Equipment": 8,
                    "Threads": 6
                };
    
                if (locationName.startsWith('HS')) {
                    updatedIdealWeeks = {
                        "Non-Medical Consumables": 6,
                        "Medical Consumables": 8,
                        "Professional Products": 5,
                        "Retail": 8,
                        "Facial Retail": 8,
                        "Candles": 6,
                        "Testers": 5,
                        "Body products": 8,
                        "Employee Supplies": 7,
                        "Supplies Massage": 4,
                        "Others": 5,
                        "Office Supplies": 6
                    };
                }
            }
    
            if (newValue !== 0.8) {
                updatedIdealWeeks = Object.fromEntries(
                    Object.entries(normalIdealWeeks).map(([category, weeks]) => [
                        category,
                        Math.max(0, Math.round(parseInt(weeks) + adjustment))
                    ])
                );
            }
    
            setIdealWeeks(updatedIdealWeeks);
    
            // Call the API to update the ideal weeks
            await updateIdealWeeksFetch(locationName, updatedIdealWeeks);
        }

        // Call the API to update the inventory level
        try {
            const data = await updateInventoryLevelFetch(locationName, newValue);
            if (data.message === 'Confidence interval updated successfully') {
                toast.success('Inventory level updated successfully');
            } else {
                // Handle error if necessary
                // toast.error('Failed to update inventory level');
            }
        } catch (error) {
            console.error('Error updating inventory level:', error);
        }
    };
  
    const inventoryRiskLevelsContent = [
        <p key="aggressive"><strong>Aggressive:</strong> Opt for lower inventory levels by accepting a moderate risk of stockouts. Ideal for steady-state locations that want to minimize their inventory on-hand.</p>,
        <p key="normal"><strong>Normal:</strong> Maintain moderate inventory levels with a low risk of stockouts. Suitable for most locations that want to balance their inventory on-hand and product availability.</p>,
        <p key="conservative"><strong>Conservative:</strong> Keep higher inventory levels to eliminate the risk of stockouts. Best for fast-growing locations that want to maximize product availability.</p>
    ];

    const replenishmentInfoContent = [
        <p key="definition">
            “Ideal Replenishment Weeks” are defined for each product category to specify target replenishment levels. It is important to note that the actual average weeks of inventory on hand may be lower due to continuous consumption of stock.
        </p>,
        <p key="example">
            For example, setting the parameter to 6 weeks means that each new order placed for products within that category is designed to replenish your stock to cover at least 6 weeks of projected usage, based on forecasted demand.
        </p>,
        <p key="custom-weeks">
            If desired, you can create Custom Weeks for your location to ensure target replenishment levels are optimally adjusted to meet your operational needs for each product category.
        </p>
    ];    

    return (
        <div>
            <h1 style={{ marginTop: '35px', display: 'flex', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7' }}>Inventory Settings</h1>
        
            <div style={{display: 'flex', alignItems: 'center'}}>
                <h1 style={{ marginTop: '15px', display: 'flex', fontSize: '20px', fontWeight: 'bold', color: '#87D3D7' }}>Inventory Management Style</h1>
                <InfoPopup title="Medvelle’s AI-driven Recommendation System can be customized to your specific inventory needs:" content={inventoryRiskLevelsContent} />
            </div>
        
            <div style={{display: 'flex', flexDirection: 'column', marginBottom: '30px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={{fontWeight: 'bold'}}>Your current inventory management style is: </p>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '600px', marginBottom: '5px'}}>
                <span>Aggressive</span>
                <span style={{marginRight: '-19px'}}>Normal</span>
                <span>Conservative</span>
            </div>
            <input
                type="range"
                min="0.7"
                max="0.9"
                step="0.1"
                value={inventoryLevel}
                onChange={handleInventoryLevelChange} 
                style={{ 
                    width: '600px',
                    accentColor: '#C37C64', 
                    background: 'linear-gradient(to right, #F49C7D 0%, #F49C7D ' + ((inventoryLevel - 0.6) * 100 / 0.3) + '%, #eaeaea ' + ((inventoryLevel - 0.6) * 100 / 0.3) + '%, #eaeaea 100%)',
                    borderRadius: '10px',
                    height: '10px',
                    marginTop: '10px'
                }} 
                list="tickmarks-inventory"
            />
            </div>
            
            <div style={{display: 'flex', alignItems: 'center'}}>
                <h1 style={{ marginTop: '20px', display: 'flex', fontSize: '20px', fontWeight: 'bold', color: '#87D3D7',  }}>Ideal Replenishment Weeks</h1>
                <InfoPopup title="Ideal Replenishment Weeks:" content={replenishmentInfoContent} />
            </div>

            <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '10px' }}>Medvelle Default Weeks</span>
                <label className="switch">
                    <input type="checkbox" checked={customToggle} onChange={toggleCustom} />
                    <span className="slider round"></span>
                </label>
                <span style={{ marginLeft: '10px' }}>Custom Weeks</span>
            </div>

            <div style={{ 
            border: '2px solid #87D3D7', 
            display: 'flex', 
            justifyContent: 'left', 
            flexDirection: 'column', 
            width: '100%', 
            maxHeight: '350px', // Set a fixed height
            overflowY: 'auto', // Enable vertical scrolling
            boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            marginTop: '20px',
            }}>
            <table style={{  
                width: '100%', 
                borderCollapse: 'separate', 
                borderSpacing: 0, 
                position: 'relative' // Ensure positioning context
            }}>
                <thead style={{
                position: 'sticky', // Stick the header
                top: 0, // Stick to the top of the table
                zIndex: 0 // Ensure it stays above the tbody rows
                }}>
                <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                    <th style={{ borderBottom:'2px solid black', padding: '10px', width: '50%' }}>Product Category</th>
                    <th style={{ borderBottom:'2px solid black', padding: '10px', width: '50%', textAlign: 'left'}}><span style={{marginLeft: '50px'}}>Ideal Weeks</span></th>
                </tr>
                </thead>
                <tbody>
                {productCategories.map((category, index) => (
                    <tr key={category} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                    <td style={{ padding: '15px' }}>{category} </td>
                    <td style={{ padding: '15px' }}>
                        <input 
                        type="range" 
                        min="0" 
                        max="10" 
                        value={idealWeeks[category]} 
                        disabled={!customToggle}
                        onChange={(e) => setIdealWeeks(prevState => ({ ...prevState, [category]: parseInt(e.target.value) }))} 

                        style={{ 
                            width: '150px', 
                            accentColor: '#C37C64', 
                            background: 'linear-gradient(to right, #F49C7D 0%, #F49C7D ' + (idealWeeks[category] * 10) + '%, #eaeaea ' + (idealWeeks[category] * 10) + '%, #eaeaea 100%)',
                            borderRadius: '10px',
                            height: '10px',
                            cursor: !customToggle ? 'not-allowed' : 'pointer', // Indicate disabled state
                        }} 
                        list={`tickmarks-${category}`}
                        />
                        <datalist id={`tickmarks-${category}`}>
                        {[...Array(11).keys()].map(i => (
                            <option key={i} value={i} label={i.toString()} />
                        ))}
                        </datalist>
                        <span style={{ marginLeft: '10px' }}>{idealWeeks[category]}</span>
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>

            <button  
                onClick={() => {saveIdealWeeks(locationName, idealWeeks)}} 
                style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px', marginTop: '15px', marginBottom: '30px' }}
            >
                Save Weeks
            </button>

            <ToastContainer />
        </div>
    );
};

export default InventorySettings;