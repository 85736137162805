export const updateCredentials = async (supplier_id, location_name, username, password) => {
    try {
        const requestBody = JSON.stringify({supplier_id, location_name, password, username});
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL}/update-credentials`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });
        if (!response.ok) {
            throw new Error('Failed to update credentials');
        }
        
        return true;
    } catch (error) {
        console.error('Update failed:', error);
        return false;
    }
}