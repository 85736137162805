import { toast } from "react-toastify";

export const checkFirstSpotcheck = async (locationName, setIsFirst, signal = undefined) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/is-first-spotcheck`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({location_name: locationName}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error(`Error fetching first spotcheck: ${ response.statusText }`);
        }

        const data = await response.json();
        const productIds = data.map(item => item.product_id); // Extract product_id from response
        setIsFirst(productIds);
        return productIds;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error checking first spotcheck:', error);
        }
        return false;
    }
};
export const fetchRewards = async (setRewards, locationName, signal = undefined) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-rewards`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({locationName: locationName}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setRewards(data); // Store the rewards data in the state variable
        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching rewards:', error);
        }
        return false;
    }
};

export const fetchTokens = async (setTokens, signal = undefined) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-tokens`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({user_email: localStorage.getItem('userEmail')}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if provided
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setTokens(data[0].tokens);
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching tokens:', error);
        }
    }
};


export const fetchSpotcheckInventory = async (locationName, setInventoryData, signal = undefined) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/spotcheck-inventory`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({locationName}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if provided
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setInventoryData(data);
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching inventory data:', error);
        }
    }
};

export const loadMoreSpotcheck = async (locationName) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/load-more-spotcheck`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({locationName}),
        });

        if (response.ok) {
            const message = await response.text();
            console.log('message');
            if (message.includes('Spotcheck status updated successfully') || message.includes('Fallback spotcheck status updated successfully')) {
                toast.success('More Spotchecks loaded!');
                return true
            } else {
                toast.info('No more spotchecks for the day, please check again tomorrow.');
                return false
            }
        } else {
            toast.error('Failed to load more spotchecks.');
            return false
        }
    } catch (error) {
        console.error('Error loading more spotchecks:', error);
        toast.error('An error occurred while loading more spotchecks.');
        return false
    }
};