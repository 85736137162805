import {sortArrayByAttribute, handleSortChange, getArrow} from '../utils/tables/sortUtils';
import React, {useEffect, useState, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
const Table = ({data, columns, mapping, placements = {}, columnWidths = {}, isHoverable = true, isClickable = false, onClick = (row) => { }, subData = {}, idColumn, isExpandable, rowHeight, height, width, prefix={}, callBack={}}) => {
    const [sortAsc, setSortAsc] = useState(true);
    const [sortAttribute, setSortAttribute] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const [expandedRow, setExpandedRow] = useState([]);
    useEffect(() => {
        setSortedData(data);
    }, [data]);

    useEffect(() => {
        setSortedData(sortArrayByAttribute([...data], sortAttribute, sortAsc));
    }, [sortAsc, sortAttribute]);

    // Sets a bundle to be displayed.
    const handleRowClick = (rowId) => {
        if (expandedRow.includes(rowId)) {
            setExpandedRow(expandedRow.filter(id => id !== rowId));
        } else {
            // If the bundle is not expanded, add it to the list of expanded bundles
            setExpandedRow([...expandedRow, rowId]);
        }
    };

    return (
        <div className="table-container" style={{height:height, width:width}}>
            <table className={isHoverable ? "table hover-effect" : "table"}>
                <thead>
                    <tr>
                        {isExpandable && <th style={{borderBottom: '2px solid black', padding: '10px', width: '5%'}}></th>}
                        {columns.map((column, index) => (
                            <th key={index}
                                style={{width: columnWidths[column] ? columnWidths[column] : '10%', textAlign: placements[column] ? placements[column] : 'left'}}
                                onClick={() => handleSortChange(mapping[column], sortAttribute, setSortAttribute, sortAsc, setSortAsc)}>
                                {column} {getArrow(mapping[column], sortAttribute, sortAsc)}
                            </th>
                        )
                        )}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((row, rowIndex) => {
                        return (
                            <>
                                <tr key={rowIndex} onClick={isClickable ? () => onClick(row) : () => { }} style={{cursor: isClickable ? 'pointer' : '', height: rowHeight ? rowHeight : ''}}>
                                    {isExpandable && (
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRowClick(row[idColumn]);
                                            }}
                                            style={{textAlign: 'center', cursor: 'pointer'}}
                                        >
                                            <span>
                                                {expandedRow.includes(row[idColumn]) ?
                                                    <FontAwesomeIcon icon={faAngleUp} /> :
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                }
                                            </span>
                                        </td>
                                    )}
                                    {columns.map((column, colIndex) => (
                                        <td key={colIndex} style={{textAlign: placements[column] ? placements[column] : 'left'}}>
                                            {prefix[column] ? prefix[column] : ''}{callBack[column] ? callBack[column](row[mapping[column]]) : row[mapping[column]]}
                                        </td>
                                    )
                                    )}
                                </tr>

                                {(expandedRow.includes(row[idColumn]) && subData[row[idColumn]]) && (
                                    <tr style={{backgroundColor: rowIndex % 2 === 1 ? '#ebf4f5' : 'white'}}>
                                        {/* Render order details */}
                                        <td colSpan="1"></td>
                                        <td colSpan={columns.length}>
                                            <div style={{zIndex: 0, border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden', position: 'relative'}}>
                                                {prefix[idColumn] ? prefix[idColumn] : ''}{subData[row[idColumn]]}
                                            </div>
                                        </td>
                                    </tr>
                                )
                                }
                            </>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};


export default Table;