import React, {useState, useRef, useImperativeHandle, forwardRef} from 'react';

const Card = forwardRef(({title, paragraphs, currentHovered, buttonTitle, onClick, image, banner}, ref) => {
    const cardRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getWidth: () => {
            if (cardRef.current) {
                return cardRef.current.getBoundingClientRect().width;
            }
            return 0;
        }
    }));
    return (
        <div
            className={currentHovered ? "supplier-card hovered" : "supplier-card"}
            ref={cardRef}
            style={{position: 'relative', cursor: onClick ? 'pointer' : '', }}  // Make the card container relative for positioning the banner
            onClick={onClick ? onClick : ()=>{}}
        >
            {/* Promotion Banner */}
            {banner && (
                <div style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: '#E4846E', // Tomato color
                    color: 'white',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    zIndex: 1  // Ensures it stays on top
                }}>
                    {banner}
                </div>
            )}
            

            {image && (
                <img style={{
                    width: '90%',
                    height: 'auto',
                    aspectRatio: '2 / 1',
                }}
                    src={image}
                    className="promo-image"
                />
            )}

            <h2 style={{textAlign: 'left', marginBottom: '8px'}}>{title}</h2>

            {paragraphs && (
                paragraphs.map((item, index) => (
                    <div key={index} style={{textAlign: 'left', margin: '8px 0px'}}>{item}</div>
                ))
            )}

            {buttonTitle && (
                <div style={{textAlign: 'left', marginTop: '32px'}}>
                    <button className="action-button" onClick={onClick}>{buttonTitle}</button>
                </div>
            )}
        </div>
    );
});

export default Card;
